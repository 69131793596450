import { createRouter, createWebHashHistory } from 'vue-router'

import login from "@/pages/page-login"
import home from "@/pages/page-home"

import pageStatistique from "@/pages/page-projetcs/page-statistique"
import pageEmailVisualisation from "@/pages/page-projetcs/page-email-visualisation"

import planningDisponibilite from "@/pages/page-projetcs/plannings/page-planning-disponibilite"
import planningLivraison from "@/pages/page-projetcs/plannings/page-planning-livraison"
import planningTechnicien from "@/pages/page-projetcs/plannings/page-planning-technicien"

import pageEtatDesLieuxMateriel from "@/pages/page-projetcs/page-etat-des-lieux-materiel"
import pageEtatDesLieuxVelo from "@/pages/page-projetcs/page-etat-des-lieux-velo"

const routes = [
    // { path: '*', component: home },
    { path: '/', component: home },
    { path: '/login', component: login },
    { path: '/planning-disponibilite', component: planningDisponibilite },
    { path: '/planning-livraison', component: planningLivraison },
    { path: '/planning-technicien', component: planningTechnicien },
    { path: '/statistiques', component: pageStatistique },
    { path: '/email-visualisation', component: pageEmailVisualisation },
    { path: '/etat-des-lieux-materiel', component: pageEtatDesLieuxMateriel },
    { path: '/etat-des-lieux-velo', component: pageEtatDesLieuxVelo },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router