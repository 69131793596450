<template>
  <div
    class="etat-des-lieux-materiel"
    :style="{
      'background-color': changeBackground ? '#455A64' : '#fafafa',
      'border-radius': '5px',
    }"
  >
    <div v-if="isIos" style="height: 35px" align="right" id="hideInPrint">
      <v-btn text rounded @click="closeDialog()" class="mt-2"
        ><i
          class="fas fa-times-circle"
          style="font-size: 25px; color: red; cursor: pointer"
        ></i
      ></v-btn>
    </div>
    <v-col cols="12" align="right" id="hideInPrint" class="pa-0" v-else>
      <v-btn rounded variant="plain" @click="closeDialog()">
        <v-icon color="red" size="30">mdi-close</v-icon>
      </v-btn>
    </v-col>
    <div v-if="changeBackground" align="center" style="padding-top: 20px">
      <v-chip>
        <h3>
          {{ $t("etat-des-lieux-materiel.form.edition_mode") }}
        </h3>
      </v-chip>
    </div>
    <v-col v-if="isPrinting == false">
      <v-dialog v-model="isSignatureOpen" width="500px">
        <v-card tile>
          <signature-pad
            v-if="selectedSignature"
            id="signature"
            :ref="selectedSignature"
            height="200px"
            :width="'auto'"
            :options="{
              onBegin: () => {
                $refs[selectedSignature].resizeCanvas();
              },
            }"
          />
          <v-card color="#eeeeee" tile>
            <v-col align="right">
              <v-btn
                text
                elevation="0"
                color="green"
                variant="tonal"
                class="mx-2"
                id="hideInPrint"
                @click="(isSignatureOpen = false), save(selectedSignature)"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn
                color="blue"
                class="mx-2"
                id="hideInPrint"
                variant="tonal"
                @click="undo(selectedSignature)"
                align="right"
              >
                <v-icon>mdi-undo</v-icon>
              </v-btn>
              <v-btn
                color="red"
                variant="tonal"
                class="mx-2"
                id="hideInPrint"
                @click="clear(selectedSignature)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-card>
        </v-card>
      </v-dialog>
      <v-dialog v-model="sendEmail">
        <v-card width="300">
          <v-card-title> {{ $t("etat-des-lieux-materiel.user.send_by_email") }} </v-card-title>
          <v-col align="right">
            <v-select
              class="mb-2"
              v-model="email_pdf"
              :items="emailList"
              variant="outlined"
              :label="'Email'"
              :no-data-text="$t('home.no_data_available')"
              hide-details
            >
            </v-select>
            <v-text-field
              dense
              hide-details
              variant="outlined"
              :label="'Email TEST'"
              v-model="email_pdf"
            >
            </v-text-field>
            <v-btn
              color="primary"
              @click="send_pdf"
              class="mt-2"
              style="width: 100%"
            >
              {{ $t("etat-des-lieux-materiel.user.send") }}
            </v-btn>
          </v-col>
        </v-card></v-dialog
      >
      <v-dialog v-model="modalSetPhoto2">
        <v-card>
          <v-col>
            <h3>
              {{ $t("etat-des-lieux-materiel.form.copy_previous_photo_to_actual_photo") }}
            </h3>
            <v-col>
              <v-row>
                <v-col align="center" cols="6">
                  <v-btn @click="setPhoto1InPhoto2">
                    {{ $t("etat-des-lieux-materiel.form.yes") }}
                  </v-btn>
                </v-col>
                <v-col align="center" cols="6">
                  <v-btn @click="modalSetPhoto2 = false">
                    {{ $t("etat-des-lieux-materiel.form.no") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-card>
      </v-dialog>
      <div
        id="capture"
        class="pa-1"
        style="background-color: transparent; border-radius: 5px"
      >
        <v-dialog v-model="photoViewer" max-width="2000px" width="90%">
          <v-card color="transparent">
            <div
              v-if="
                formData[photoViewerDataKey] &&
                formData[photoViewerDataKey].length !== 0 &&
                nbrPhoto[photoViewerDataKey] != 0
              "
            >
              <v-carousel height="100%" hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in formData[photoViewerDataKey]"
                  :key="i"
                  :src="item"
                  width="900px"
                >
                  <v-sheet color="transparent" height="100%" align="right">
                    <v-row>
                      <v-col align="right" cols="" class="px-0">
                        <v-btn
                          v-if="
                            (selectedPhotoTypeByClass === 'sortie' &&
                              sortieDisabled === false) ||
                            (selectedPhotoTypeByClass === 'entrer' &&
                              entrerDisabled === false &&
                              (changeBackground === true ||
                                showEditableBtn === false))
                          "
                          class="ma-2"
                          color="red"
                          variant="text"
                          @click="deletePic(i, photoViewerDataKey)"
                        >
                          <v-icon :size="35" color="red"> mdi-delete </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto" align="right" class="pl-0">
                        <v-btn
                          fab
                          variant="text"
                          small
                          class="ma-2"
                          style="float: right"
                        >
                          <v-icon
                            :size="40"
                            color="red"
                            @click="photoViewer = false"
                          >
                            mdi-close-circle-outline
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </div>
            <div v-else>
              <v-card>
                <v-col> {{ $t("etat-des-lieux-materiel.form.none_photos_saved") }} </v-col>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col cols="" align="left" class="py-0">
            <v-row>
              <v-col cols="">
                <div id="info" style="display: none">
                  <v-col>
                    <img :src="logoSrc" alt="" height="90px" /> <br />
                    {{ agency["ag_adresse"] }} <br />
                    {{ agency["ag_cp"] }}
                    {{ agency["ag_ville"] }} <br />
                    {{ agency["ag_telephone"] }} <br />
                    {{ agency["ag_courriel"] }} <br />
                    {{ agency["ag_siteweb"] }} <br />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="auto" align="right" id="qcode" style="display: none">
                <qrcode-vue
                  style="margin-top: 20px; margin-right: 10px"
                  :value="getqCodeUrl"
                  :size="qCodeSize"
                  level="H"
                ></qrcode-vue>
              </v-col>
            </v-row>
            <v-col class="pt-0">
              <div align="center">
                <h1 class="" style="text-transform: uppercase">
                  {{ data.title }}
                </h1>
              </div>
            </v-col>
            <v-dialog v-model="rootModal" width="500px">
              <v-card tile>
                <v-col align="center">
                  <div style="font-size: 20px" class="pa-2">
                    {{ $t("etat-des-lieux-materiel.form.edition_mode") }}
                    ({{ $t("etat-des-lieux-materiel.form.verification") }})
                  </div>
                  <v-text-field
                    v-model="rootPassword"
                    :label="$t('form.user_password')"
                    outlined
                    hide-details
                    type="password"
                  >
                  </v-text-field>
                  <div v-if="rootErrorMessage" style="color: red" class="ma-1">
                    {{ rootErrorMessage }}
                  </div>
                  <v-btn @click="formIsEditable(rootPassword)" class="mt-2"
                    >Valider</v-btn
                  >
                </v-col>
              </v-card>
            </v-dialog>
            <v-card
              v-if="mainClasse != 'termine' && mainClasse != 'entrer'"
              id="hideInPrint"
              flat
              :class="[mainClasse, 'outlined']"
            >
              <v-col> {{ $t("etat-des-lieux-materiel.form.element_editable_by_color") }} </v-col>
            </v-card>
            <v-card
              v-else
              id="hideInPrint"
              flat
              :class="[mainClasse, 'outlined']"
            >
              <v-col class="py-2" v-if="changeBackground === false">
                <v-row justify="center" align="center">
                  <v-col cols="10" class="pa-1">
                    <div v-if="mainClasse === 'entrer'">
                      {{ $t("etat-des-lieux-materiel.form.element_editable_by_color") }} <br />
                    </div>
                    <div style="color: grey; font-size: 12px">
                      {{ $t("etat-des-lieux-materiel.form.all_element_editable") }}
                    </div>
                  </v-col>
                  <v-col cols="" v-if="showEditableBtn" class="pa-1">
                    <div align="right">
                      <v-btn
                        variant="text"
                        elevation="0"
                        @click="formIsEditable()"
                        ><v-icon>mdi-file-document-edit </v-icon></v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-col
          id="formContainer"
          :style="
            isMobile
              ? 'height: ' +
                getFormHeight +
                'px; overflow-y: scroll; width: 100%'
              : ''
          "
        >
          <div>
            <v-col class="pa-0">
              <v-card-title class="pl-0" style="text-transform: uppercase">{{
                $t("etat-des-lieux-materiel.form.informations")
              }}</v-card-title>
              <v-row>
                <v-col md="3" cols="12" sm="4" class="pa-1">
                  <div style="background-color: #fafafa" class="rounded px-1">
                    <v-col class="px-0 py-0">
                      <div
                        style="background-color: #fafafa"
                        class="py-1 rounded"
                      >
                        {{ $t("etat-des-lieux-materiel.form.client") }}
                        <v-card
                          style="
                            background-color: white;
                            border: solid 1px #bdbdbd;
                          "
                          outlined
                          class="pa-2"
                          flat
                        >
                          {{ selectedClient.CL_CLIENT_RAISON_SOCIALE }}
                        </v-card>
                      </div>
                    </v-col>
                    <div></div>
                    <!-- <v-select
                      :items="formaredClientList"
                      v-model="selectedClient"
                      item-title="CL_CLIENT_RAISON_SOCIALE"
                    >
                    </v-select> -->
                  </div>
                </v-col>
                <v-col
                  v-for="information in getInformation.data"
                  :key="information.key"
                  md="3"
                  cols="12"
                  sm="4"
                  class="pa-1"
                >
                  <div
                    style="background-color: #fafafa"
                    class="pa-1 rounded"
                    v-if="information.key !== 'CLD_DESCRIPTION'"
                  >
                    {{ information.label }}
                    <v-card
                      style="background-color: white; border: solid 1px #bdbdbd"
                      outlined
                      class="pa-2"
                      flat
                    >
                      {{ formData[information.key] }}
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="isMobile" class="px-0 pb-0 mt-2">
              <v-divider></v-divider>
            </v-col>
            <v-col class="pa-0">
              <v-card-title class="pl-0 mb-1" style="text-transform: uppercase">
                {{ $t("etat-des-lieux-materiel.form.park_Information") }}</v-card-title
              >
              <v-row>
                <v-col md="3" cols="12" sm="4" class="pa-1">
                  <div style="background-color: #fafafa" class="pa-1 rounded">
                    {{ getInformation.description.label }}
                    <v-card
                      style="background-color: white; border: solid 1px #bdbdbd"
                      outlined
                      class="pa-2"
                      flat
                    >
                      {{ formData[getInformation.description.key] }}
                    </v-card>
                  </div>
                </v-col>
                <v-col
                  v-for="(info, key) in parc_information"
                  :key="key"
                  md="3"
                  cols="12"
                  sm="4"
                  class="pa-1"
                >
                  <div style="background-color: #fafafa" class="pa-1 rounded">
                    {{ $t("etat-des-lieux-materiel.form.park." + key) }}
                    <v-card
                      style="background-color: white; border: solid 1px #bdbdbd"
                      outlined
                      class="pa-2"
                      flat
                    >
                      {{ info }}
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="isMobile && parc_information" class="px-0 pb-0 mt-2">
              <v-divider></v-divider>
            </v-col>
            <v-col class="px-0">
              <v-card-title class="pl-0" style="text-transform: uppercase">{{
                $t("etat-des-lieux-materiel.form.exit_return")
              }}</v-card-title>
              <v-row>
                <v-col
                  v-for="elem in getDates"
                  :key="elem.key"
                  cols="6"
                  sm="6"
                  class="pa-1"
                >
                  <div :class="elem.class" class="outlined" align="center">
                    {{ elem.label }}
                    <v-divider
                      style="width: 100%"
                      class="mb-2 mt-0"
                    ></v-divider>
                    <div class="pa-2 pt-1">
                      <v-btn
                        variant="text"
                        class="outlined"
                        @click="datemodal[elem.key] = true"
                        style="width: 100%; background-color: white"
                        :disabled="elem.disabled"
                      >
                        <!-- {{ elem.label }} : -->
                        {{
                          formatDate(formData[elem.key], "DD/MM/YYYY - HH:mm")
                        }}
                      </v-btn>
                    </div>
                    <v-dialog v-model="datemodal[elem.key]">
                      <Datepicker
                        class="etat-des-lieux-materiel"
                        keepActionRow
                        v-model="formData[elem.key]"
                        locale="fr"
                        :clearable="false"
                        :cancelText="'Fermer'"
                        :selectText="'Valider'"
                        inline
                        @update:modelValue="datemodal[elem.key] = false"
                      ></Datepicker>
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="px-0">
              <v-divider v-if="isMobile"></v-divider>
              <v-card-title class="pl-0" style="text-transform: uppercase">
                {{ $t("etat-des-lieux-materiel.form.options") }}
              </v-card-title>
              <v-row>
                <v-col cols="12" class="pa-1">
                  <v-table
                    fixed-header
                    style="border: solid 1.9px rgba(0, 0, 0, 0.2)"
                    height="350"
                  >
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("etat-des-lieux-materiel.form.starting_quantity") }}
                        </th>
                        <th class="text-left">
                          <!-- {{ $t("etat-des-lieux-materiel.form.nom")}} -->
                        </th>
                        <th class="text-left">
                          {{ $t("etat-des-lieux-materiel.form.end_quantity") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in varificationTab.fieldData"
                        :key="item['CH_DESIGNATION']"
                      >
                        <td class="sortie" align="center" justify="center">
                          <v-select
                            style="background-color: white"
                            :items="[
                              '0',
                              '1',
                              '2',
                              '3',
                              '4',
                              '5',
                              '6',
                              '7',
                              '8',
                              '9',
                              '10',
                            ]"
                            variant="outlined"
                            density="compact"
                            hide-details
                            v-model="item['CLD_QTE_DEPART']"
                            :disabled="sortieDisabled"
                          ></v-select>
                        </td>
                        <td align="center">
                          <div style="font-weight: bold">
                            {{ item["CH_DESIGNATION"] }}
                          </div>
                        </td>

                        <td class="entrer" align="center">
                          <v-select
                            style="background-color: white"
                            :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                            outlined
                            dense
                            hide-details
                            v-model="item['CLD_QTE_RETOUR']"
                            :disabled="entrerDisabled"
                          ></v-select>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </v-col>
                <v-col cols="12">
                  <v-row class="mb-3">
                    <v-col
                      v-for="observation in getObservations"
                      :key="observation.key"
                      sm="6"
                      cols="6"
                      class="pa-1"
                    >
                      <div :class="[observation.class, 'outlined']">
                        <div class="pa-2">
                          {{ observation.label }}
                        </div>
                        <v-divider></v-divider>
                        <div class="pa-2">
                          <v-textarea
                            style="background-color: white"
                            :placeholder="observation.label + '...'"
                            variant="outlined"
                            v-model="formData[observation.key]"
                            no-resize
                            height="135"
                            hide-details
                            :disabled="observation.disabled"
                          ></v-textarea>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="elem in getCarbu"
                      :key="elem.key"
                      sm="6"
                      cols="6"
                      class="pa-1"
                    >
                      <v-card
                        flat
                        class="pt-2 px-2 form_card outlined"
                        :class="elem.class"
                        height="100%"
                      >
                        <v-col>
                          <v-row>
                            {{ elem.label }}
                            <!-- :label="formData[elem.key] + '%'" -->
                            <v-spacer></v-spacer>
                            <div style="font-weight: bold">
                              {{ formData[elem.key] }} %
                            </div>
                          </v-row>
                        </v-col>
                        <v-slider
                          v-model="formData[elem.key]"
                          thumb-label
                          hide-details
                          max="100"
                          :thumb-size="10"
                          color="#757575"
                          min="0"
                          persistent-hint
                          step="10"
                          :disabled="elem.disabled"
                        >
                        </v-slider>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="photo in getPhoto"
                      :key="photo.key"
                      sm="6"
                      cols="6"
                      class="px-1 my-1"
                    >
                      <v-col :class="[photo.class, 'outlined']">
                        <v-row justify="center" style="position: relative">
                          <v-col cols="4" sm="2" md="1" class="photo">
                            <v-file-input
                              class="photo"
                              v-if="photo.disabled"
                              v-model="formData[photo.tmpKey]"
                              variant="outlined"
                              :disabled="photo.disabled"
                              :prepend-icon="'mdi-camera-off'"
                              hide-input
                              accept="image/png, image/jpeg"
                              :label="photo.label"
                              hide-details
                              @change="
                                updatePhotos(
                                  formData[photo.tmpKey],
                                  photo.Key,
                                  photo.tmpKey
                                )
                              "
                            >
                            </v-file-input>
                            <v-file-input
                              v-else
                              class="photo"
                              v-model="formData[photo.tmpKey]"
                              variant="outlined"
                              :disabled="photo.disabled"
                              :prepend-icon="'mdi-camera'"
                              hide-input
                              accept="image/png, image/jpeg"
                              :label="photo.label"
                              hide-details
                              @change="
                                updatePhotos(
                                  formData[photo.tmpKey],
                                  photo.key,
                                  photo.tmpKey
                                )
                              "
                            >
                            </v-file-input>
                          </v-col>
                          <v-col cols="8" sm="10" md="11">
                            <v-btn
                              height="50px"
                              width="100%"
                              style="background-color: white"
                              variant="outlined"
                              class="form_card outlined"
                              @click="openPhotoViewer(photo, photo.key)"
                            >
                              <div v-if="nbrPhoto[photo.key] === undefined">
                                <v-icon> mdi-image </v-icon>
                                0
                              </div>
                              <div v-else>
                                <v-icon> mdi-image </v-icon>
                                {{ nbrPhoto[photo.key] }}
                              </div>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-row>
              <v-col
                v-for="(elem, idx) in getNumberCounter"
                :key="idx"
                cols="6"
                class="pa-1"
              >
                <div
                  class="py-2 px-3"
                  :class="[elem.class, 'outlined']"
                  align="center"
                  style="height: 100%"
                >
                  {{ elem.label }}
                  <!-- :disabled="elem.disabled" -->
                  <v-text-field
                    variant="text"
                    :readonly="elem.disabled"
                    class="centered-input text--darken-3"
                    style="background-color: white"
                    :class="['outlined']"
                    v-model="formData[elem.key]"
                    hide-details
                    single-line
                    type="number"
                  />
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-1" v-if="isMobile"></v-divider>
          </div>
          <v-row class="pt-3">
            <v-col cols="12" sm="6" class="pa-1">
              <div class="sortie outlined">
                <v-card-title style="text-transform: uppercase">{{
                  $t("etat-des-lieux-materiel.form.exit_signature")
                }}</v-card-title>
                <v-col>
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-card variant="flat" class="outlined">
                        <v-col>
                          <v-text-field
                            v-if="!sortieDisabled"
                            variant="outlined"
                            :disabled="sortieDisabled"
                            v-model="formData[keyList.sortieControleur]"
                            :value="get_user_name"
                            class="py-1"
                            hide-details
                            :label="$t('form.controller')"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            readonly
                            variant="outlined"
                            v-model="formData[keyList.sortieControleur]"
                            :value="get_user_name"
                            class="py-1"
                            hide-details
                            :label="$t('form.controller')"
                          ></v-text-field>
                        </v-col>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-card variant="flat" class="outlined">
                        <v-col>
                          <v-text-field
                            v-if="!sortieDisabled"
                            dense
                            variant="outlined"
                            :disabled="sortieDisabled"
                            v-model="formData[keyList.sortieClient]"
                            class="py-1"
                            hide-details
                            :label="$t('form.client_name')"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            dense
                            variant="outlined"
                            readonly
                            v-model="formData[keyList.sortieClient]"
                            class="py-1"
                            hide-details
                            :label="$t('form.client_name')"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          align="right"
                          @click="
                            writeSignature(
                              'CLD_SIGNATURE_DEPART',
                              sortieDisabled
                            )
                          "
                        >
                          <v-img
                            contain
                            :src="formData['CLD_SIGNATURE_DEPART']"
                            width="100%"
                            height="110px"
                          ></v-img>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pa-1">
              <div class="entrer outlined">
                <v-card-title style="text-transform: uppercase">
                  {{ $t("etat-des-lieux-materiel.form.return_signature") }}
                </v-card-title>
                <v-col>
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-card variant="flat" class="outlined">
                        <v-col>
                          <v-text-field
                            v-if="!entrerDisabled"
                            variant="outlined"
                            dense
                            :disabled="entrerDisabled"
                            v-model="formData[keyList.returnControleur]"
                            :value="get_user_name"
                            class="py-1"
                            hide-details
                            :label="$t('form.controller')"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            variant="outlined"
                            dense
                            readonly
                            v-model="formData[keyList.returnControleur]"
                            :value="get_user_name"
                            class="py-1"
                            hide-details
                            :label="$t('form.controller')"
                          ></v-text-field>
                        </v-col>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-card variant="flat" class="outlined">
                        <v-col>
                          <v-text-field
                            v-if="!entrerDisabled"
                            variant="outlined"
                            dense
                            :disabled="entrerDisabled"
                            v-model="formData[keyList.returnClient]"
                            class="py-1"
                            hide-details
                            :label="$t('form.client_name')"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            variant="outlined"
                            readonly
                            v-model="formData[keyList.returnClient]"
                            class="py-1"
                            hide-details
                            :label="$t('form.client_name')"
                            dense
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          align="right"
                          @click="
                            writeSignature(
                              'CLD_SIGNATURE_RETOUR',
                              entrerDisabled
                            )
                          "
                        >
                          <v-img
                            contain
                            :src="formData['CLD_SIGNATURE_RETOUR']"
                            width="100%"
                            height="110px"
                          ></v-img>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-col>
            <v-col class="mr-4" id="hideInPrint" align="center">
              <v-row v-if="!changeBackground" justify="center" class="mb-2">
                <v-col align="center" cols="6" md="4" class="pa-1">
                  <v-btn
                    width="100%"
                    :class="['mb-1', 'button']"
                    color="primary"
                    variant="flat"
                    @click="downloadPdf()"
                    ><v-icon class="mr-2"> mdi-download </v-icon>
                    {{ $t("etat-des-lieux-materiel.form.download") }}
                  </v-btn>
                </v-col>
                <v-col align="center" cols="6" md="4" class="pa-1">
                  <v-btn
                    width="100%"
                    :class="['mb-1', 'button']"
                    color="primary"
                    variant="flat"
                    @click="sharePdf()"
                  >
                    {{ $t("etat-des-lieux-materiel.user.send_by_email") }}
                    <v-icon class="ml-2"> mdi-email </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="6"
                  md="4"
                  class="pa-1"
                  v-if="changeBackground || mainClasse !== 'termine'"
                >
                  <v-btn
                    class="button"
                    style="background-color: #4caf50; color: white; width: 100%"
                    variant="flat"
                    @click="logData()"
                  >
                    {{ $t("etat-des-lieux-materiel.form.save") }}
                  </v-btn>
                </v-col>
                <v-col cols="6" md="4" class="pa-1">
                  <v-btn
                    v-if="changeBackground === false"
                    class="button"
                    style="background-color: #e53935; color: white; width: 100%"
                    variant="flat"
                    @click="closeDialog()"
                  >
                    {{ $t("etat-des-lieux-materiel.form.cancel") }}
                  </v-btn>
                  <v-btn
                    v-else
                    class="button"
                    style="background-color: #e53935; color: white; width: 100%"
                    text
                    outlined
                    @click="reLoadForm()"
                  >
                    {{ $t("etat-des-lieux-materiel.form.cancel") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-snackbar id="hideInPrint" v-model="showAlert" color="red">
          {{ errorMsg }}
        </v-snackbar>
        <v-snackbar id="hideInPrint" v-model="showMessage" color="green">
          <div style="font-size: 15px" align="center">
            {{ infoMsg }}
          </div>
        </v-snackbar>
        <div align="center" id="info" style="display: none">
          <v-divider class=""></v-divider>
          <v-col style="padding-bottom: 20px">
            {{ societe["RS"] }} - {{ societe["TypeEntreprise"] }} au capital de
            {{ societe["Capital"] }} € - SIRET {{ societe["NumeroSIRET"] }} -
            APE : {{ societe["CodeAPE"] }} - TVA :
            {{ societe["TVAIntracommunautaire"] }} <br />
            {{ agency["ag_nom"] }} - {{ agency["ag_adresse"] }}
            {{ agency["ag_cp"] }} {{ agency["ag_ville"] }} - Tél :
            {{ agency["ag_telephone"] }}
          </v-col>
        </div>
      </div>
    </v-col>
    <div v-else id="loading" class="text-center" style="padding-top: 40vh">
      <v-progress-circular indeterminate size="150" :width="7" color="primary">
        {{ $t("etat-des-lieux-materiel.form.is_loading") }}
      </v-progress-circular>
    </div>
  </div>
  <!-- <script src="http://html2canvas.hertzen.com/dist/html2canvas.js"></script> -->
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import engine from "../core/index";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Browser } from "@capacitor/browser";
import { v4 as uuidv4 } from "uuid";
import QrcodeVue from "qrcode.vue";
import SignaturePad from "vue3-signature-pad";
// import SignaturePad from "@/signature-pad.vue";

export default {
  data() {
    return {
      emailList: [],
      logoSrc: "data:image/gif;base64,",
      agency: {},
      societe: {},
      personalizedData: {},
      qCodeSize: 150,
      windowHeight: window.innerHeight,
      rootErrorMessage: undefined,
      rootModal: false,
      rootPassword: "",
      selectedPhotoTypeByClass: undefined,
      showEditableBtn: false,
      nbrPhoto: {},
      nbrLoadedPhoto: 3,
      mainClasse: undefined,
      infoMsg: undefined,
      showMessage: false,
      isSignatureOpen: false,
      selectedSignature: undefined,
      email_pdf: undefined,
      sendEmail: false,
      pdfLink: "",
      isPhoto2Verif: false,
      modalSetPhoto2: false,
      isPrinting: false,
      options: {
        penColor: "black",
      },
      printTest: 0,
      showAlert: false,
      formData: {},
      date: undefined,
      datemodal: {},
      photoViewer: false,
      photoViewerDataKey: undefined,
      selectedClient: {},
      sortieDisabled: true,
      entrerDisabled: true,
      errorMsg: undefined,
      formaredClientList: [],
      parc_information: undefined,
      keyList: {
        parc: "CLD_PARC",
        jsonList: "CLD_CHECK_LIST_JSON_GESTION",
        sortieControleur: "CLD_CONTROLEUR_DEPART",
        returnControleur: "CLD_CONTROLEUR_RETOUR",

        sortieClient: "CLD_SIGNATAIRE_DEPART",
        sortieClientSignature: "CL_SIGNATURE_DEPART",
        returnClient: "CLD_SIGNATAIRE_RETOUR",
        returnClientSignature: "CL_SIGNATURE_RETOUR",
      },
      varificationTab: {},
    };
  },
  watch: {
    isSignatureOpen(val) {
      if (val === false) this.selectedSignature = undefined;
    },
    selectedClient(val) {
      // this.formData["CLD_NOM_CLIENT"] = val["CLD_NOM_CLIENT"];
      this.formData["CL_CLIENT_TELEPHONE"] = val["CL_CLIENT_TELEPHONE"];
      this.formData["CL_CLIENT_RAISON_SOCIALE"] =
        val["CL_CLIENT_RAISON_SOCIALE"];
      this.formData["CL_CLIENT_EMAIL"] = "PAS_D_EMAIL";
      this.formData["CLD_LIEN"] = val["CL_LIEN"];
      this.formData["CL_ID_CLIENT"] = val["CL_ID_CLIENT"];
      this.getEmailList(val["CL_ID_CLIENT"]);
      // this.formData["CLD_SIGNATURE_DEPART"] = val["CLD_SIGNATURE_DEPART"];
      // this.formData["CLD_SIGNATURE_RETOUR"] = val["CLD_SIGNATURE_RETOUR"];
      // this.formData["CLD_NOM_CLIENT"] = val["CLD_NOM_CLIENT"];
      // this.formData["CL_SIGNATURE_DEPART"] = val["CL_SIGNATURE_DEPART"];
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    let that = this;

    this.getPhotoFromDropBox();

    engine.get_societe_by_id(this.data.data["CLD_ID_SOCIETE"], function (data) {
      if (data.code == 0) {
        that.societe = data.data.data[0].fieldData;
      }
    });
    this.agency = this.data.data["CLD_AGENCY"];
    let logo = JSON.parse(this.agency["Logo_JSON"]);
    this.logoSrc += logo.Logo_Base64;
    engine.get_form_special_data(function (data) {
      if (data.code == 0) {
        let personalizedDataArray = data.data.data.map((val) => val.fieldData);
        for (let i = 0; i < personalizedDataArray.length; i++) {
          that.personalizedData[personalizedDataArray[i].name] =
            personalizedDataArray[i].show == 1;
        }
      }
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.mainClasse = "termine";
    let index = Object.values(this.data.disabled).findIndex(
      (data) => data === false
    );
    if (index !== -1) {
      this.mainClasse = Object.keys(this.data.disabled)[index];
    }
    this.formaredClientList = JSON.parse(JSON.stringify(this.clients_list));
    this.formaredClientList = this.formaredClientList.map((val) => val.data);
    this.setParcList();
    this.sortieDisabled = this.data.disabled.sortie;
    this.entrerDisabled = this.data.disabled.entrer;
    if ((this.sortieDisabled && this.entrerDisabled) || this.sortieDisabled) {
      this.showEditableBtn = true;
    }
    this.formData = JSON.parse(JSON.stringify(this.data.data));

    if (this.sortieDisabled) {
      this.formData["CLD_CONTROLEUR_RETOUR"] = this.get_user_name;
    } else {
      this.formData["CLD_CONTROLEUR_DEPART"] = this.get_user_name;
    }

    if (
      this.formData[this.keyList.sortieControleur] === undefined ||
      this.formData[this.keyList.sortieControleur].length === 0
    ) {
      this.formData[this.keyList.sortieControleur] = this.get_user_name;
    } else {
      if (
        this.formData[this.keyList.returnControleur] === undefined ||
        this.formData[this.keyList.returnControleur].length === 0
      )
        this.formData[this.keyList.returnControleur] = this.get_user_name;
    }

    if (this.formData["CLD_CHECK_LIST_JSON_GESTION"] === "") {
      this.formData["CLD_CHECK_LIST_JSON_GESTION"] =
        this.formData["CLD_CHECK_LIST_JSON"];
    }
    // if (this.formData["CLD_PHOTO_1"] !== "")
    //   this.formData["CLD_PHOTO_1"] = Object.values(
    //     JSON.parse(this.formData["CLD_PHOTO_1"])
    //   );
    // if (this.formData["CLD_PHOTO_2"] !== "")
    //   this.formData["CLD_PHOTO_2"] = Object.values(
    //     JSON.parse(this.formData["CLD_PHOTO_2"])
    //   );

    if (
      this.formData["CLD_LIEN"] !== undefined &&
      this.formData["CLD_LIEN"] !== ""
    ) {
      let client = this.formaredClientList.find(
        (val) => val["CL_LIEN"] === this.formData["CLD_LIEN"]
      );
      if (client) this.selectedClient = client;
    }
    if (
      this.formData["CLD_DATE_DEPART_VALIDATION"] &&
      this.formData["CLD_DATE_RETOUR_VALIDATION"] === ""
    )
      this.formData["CLD_DATE_RETOUR_VALIDATION"] =
        moment().format("YYYY-MM-DD");
    if (this.formData["CLD_DATE_DEPART_VALIDATION"] === "")
      this.formData["CLD_DATE_DEPART_VALIDATION"] =
        moment().format("YYYY-MM-DD");
    if (this.formData["CLD_DATE_DEPART_VALIDATION"])
      this.formData["CLD_DATE_DEPART_VALIDATION"] = moment(
        this.formData["CLD_DATE_DEPART_VALIDATION"]
      ).format("YYYY-MM-DD");
    if (this.formData["CLD_DATE_RETOUR_VALIDATION"])
      this.formData["CLD_DATE_RETOUR_VALIDATION"] = moment(
        this.formData["CLD_DATE_RETOUR_VALIDATION"]
      ).format("YYYY-MM-DD");
    this.formData["CLD_CHECK_LIST_JSON_GESTION"] = JSON.parse(
      this.formData["CLD_CHECK_LIST_JSON_GESTION"]
    );

    if (
      this.formData["CLD_NIVEAU_GAS_RETOUR"] === "" ||
      this.formData["CLD_NIVEAU_GAS_RETOUR"] === undefined
    )
      this.formData["CLD_NIVEAU_GAS_RETOUR"] = 100;
    if (this.formData["CLD_NIVEAU_GAS_DEPART"] == "")
      this.formData["CLD_NIVEAU_GAS_DEPART"] = 100;
    if (
      this.formData["CLD_NIVEAU_ADBLUE_RETOUR"] === "" ||
      this.formData["CLD_NIVEAU_ADBLUE_RETOUR"] === undefined
    )
      this.formData["CLD_NIVEAU_ADBLUE_RETOUR"] = 100;
    if (this.formData["CLD_NIVEAU_ADBLUE_DEPART"] == "")
      this.formData["CLD_NIVEAU_ADBLUE_DEPART"] = 100;
    this.varificationTab = this.formData[this.keyList.jsonList];
    // engine.get_photos(
    //   this.formData["CLD_NUM_CONTRAT"],
    //   this.formData["CLD_MATRICULE"],
    //   "SORTIE",
    //   function (data) {
    //     console.log("get_photos 1", data);
    //     if (data.code === 0) {
    //       if (data.data !== "undefined")
    //     that.$set(that.formData, "CLD_PHOTO_1", JSON.parse(data.data));
    //   that.$set(
    //     that.formData,
    //     "CLD_PHOTO_1_TMP",
    //     new Array(Object.keys(that.formData["CLD_PHOTO_1"]).length).fill({})
    //   );
    //   that.$set(
    //     that.nbrPhoto,
    //     "CLD_PHOTO_1",
    //     that.formData["CLD_PHOTO_1_TMP"].length
    //   );
    // }
    //   }
    // );
    // engine.get_photos(
    //   this.formData["CLD_NUM_CONTRAT"],
    //   this.formData["CLD_MATRICULE"],
    //   "RETOUR",
    //   function (data) {
    //     console.log("get_photos 1", data);
    //     if (data.code === 0) {
    //       that.$set(that.formData, "CLD_PHOTO_2", JSON.parse(data.data));
    //       that.$set(
    //         that.formData,
    //         "CLD_PHOTO_2_TMP",
    //         new Array(Object.keys(that.formData["CLD_PHOTO_2"]).length).fill({})
    //       );
    //       that.$set(
    //         that.nbrPhoto,
    //         "CLD_PHOTO_2",
    //         that.formData["CLD_PHOTO_2_TMP"].length
    //       );
    //     }
    //   }
    // );
    if (this.formData["CLD_HEURE_DEPART"].includes(":")) {
      let time = this.formData["CLD_HEURE_DEPART"].split(":");
      this.formData["CLD_HEURE_DEPART"] = time[0] + ":" + time[1];
    } else {
      if (this.mainClasse === "sortie") {
        this.formData["CLD_HEURE_DEPART"] =
          moment().format("HH:mm").slice(0, -1) + "0";
      }
    }
    if (this.formData["CLD_HEURE_RETOUR"].includes(":")) {
      let time = this.formData["CLD_HEURE_RETOUR"].split(":");
      this.formData["CLD_HEURE_RETOUR"] = time[0] + ":" + time[1];
    } else {
      if (this.mainClasse === "entrer") {
        this.formData["CLD_HEURE_RETOUR"] =
          moment().format("HH:mm").slice(0, -1) + "0";
      }
    }
  },
  props: {
    agencyList: {},
    data: {},
    clients_list: {},
    user_information: {},
  },
  computed: {
    getqCodeUrl() {
      return this.data.data.CLD_NUM_CONTRAT;
    },
    getFormHeight() {
      return this.windowHeight - 170;
    },
    changeBackground() {
      if (
        this.showEditableBtn &&
        this.sortieDisabled === false &&
        this.entrerDisabled === false
      )
        return true;
      return false;
    },
    isIos() {
      if (
        // navigator.userAgent.toLowerCase().includes("ipad") ||
        // navigator.userAgent.toLowerCase().includes("mac") ||
        // navigator.userAgent.toLowerCase().includes("ipode") ||
        navigator.userAgent.toLowerCase().includes("iphone")
      )
        return true;
      return false;
    },
    isMobile() {
      return false;
      // return this.$vuetify.breakpoint.mobile;
    },
    get_user_name() {
      return this.user_information.user_name;
    },
    getPhoto() {
      let photos = [
        {
          key: "CLD_PHOTO_1",
          tmpKey: "CLD_PHOTO_1_TMP",
          label: this.$t("etat-des-lieux-materiel.form.departure_photos"),
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_PHOTO_2",
          tmpKey: "CLD_PHOTO_2_TMP",
          label: this.$t("etat-des-lieux-materiel.form.return_photos"),
          class: "entrer",
          disabled: this.entrerDisabled,
        },
      ];
      return photos;
    },
    getCarbu() {
      let carbu = [];
      if (this.personalizedData["CARBURANT"])
        carbu.push(
          {
            key: "CLD_NIVEAU_GAS_DEPART",
            label: this.$t("etat-des-lieux-materiel.form.exit_gas_level"),
            class: "sortie",
            disabled: this.sortieDisabled,
          },
          {
            key: "CLD_NIVEAU_GAS_RETOUR",
            label: this.$t("etat-des-lieux-materiel.form.return_gas_level"),
            class: "entrer",
            disabled: this.entrerDisabled,
          }
        );
      if (this.personalizedData["ADBLUE"])
        carbu.push(
          {
            key: "CLD_NIVEAU_ADBLUE_DEPART",
            label: this.$t("etat-des-lieux-materiel.form.adblue_level_exit"),
            class: "sortie",
            disabled: this.sortieDisabled,
          },
          {
            key: "CLD_NIVEAU_ADBLUE_RETOUR",
            label: this.$t("etat-des-lieux-materiel.form.adblue_level_return"),
            class: "entrer",
            disabled: this.entrerDisabled,
          }
        );
      return carbu;
    },
    getNumberCounter() {
      let numberCounter = [];
      if (this.personalizedData["KM"])
        numberCounter.push(
          {
            key: "CLD_KILOMETRE_DEPART",
            label: this.$t("etat-des-lieux-materiel.form.kilometers_number_exit"),
            class: "sortie",
            disabled: this.sortieDisabled,
          },
          {
            key: "CLD_KILOMETRE_RETOUR",
            label: this.$t("etat-des-lieux-materiel.form.kilometers_number_return"),
            class: "entrer",
            disabled: this.entrerDisabled,
          }
        );

      if (this.personalizedData["HORAMETRE"])
        numberCounter.push(
          {
            key: "CLD_HORAMETRE_DEPART",
            label: this.$t("etat-des-lieux-materiel.form.horametre_number_exit"),
            class: "sortie",
            disabled: this.sortieDisabled,
          },
          {
            key: "CLD_HORAMETRE_RETOUR",
            label: this.$t("etat-des-lieux-materiel.form.horametre_number_return"),
            class: "entrer",
            disabled: this.entrerDisabled,
          }
        );

      numberCounter.push(
        {
          key: "CLD_QTE_DEPART",
          label: this.$t("etat-des-lieux-materiel.form.material_qte_exit"),
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_QTE_RETOUR",
          label: this.$t("etat-des-lieux-materiel.form.material_qte_return"),
          class: "entrer",
          disabled: this.entrerDisabled,
        }
      );
      return numberCounter;
    },
    getInformation() {
      let informations = {};
      informations["data"] = [
        {
          key: "CL_CLIENT_RAISON_SOCIALE",
          label: this.$t("etat-des-lieux-materiel.form.agency"),
          disabled: true,
        },
        {
          key: "CLD_NUM_CONTRAT",
          label: this.$t("etat-des-lieux-materiel.form.contract_number"),
          disabled: true,
        },
        {
          key: "CL_CLIENT_TELEPHONE",
          label: this.$t("etat-des-lieux-materiel.form.phone_number"),
          disabled: true,
        },
      ];
      informations["description"] = {
        key: "CLD_DESCRIPTION",
        label: this.$t("etat-des-lieux-materiel.form.materials"),
        disabled: true,
      };

      return informations;
    },
    getObservations() {
      let observations = [
        {
          key: "CLD_COMMENTAIRE_DEPART",
          label: this.$t("etat-des-lieux-materiel.form.exit_observation"),
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_COMMENTAIRE_RETOUR",
          label: this.$t("etat-des-lieux-materiel.form.return_observation"),
          class: "entrer",
          disabled: this.entrerDisabled,
        },
      ];
      return observations;
    },
    getTimes() {
      let times = [
        {
          key: "CLD_HEURE_DEPART",
          label: this.$t("etat-des-lieux-materiel.form.exit_hour"),
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_HEURE_RETOUR",
          label: this.$t("etat-des-lieux-materiel.form.return_hour"),
          class: "entrer",
          disabled: this.entrerDisabled,
        },
      ];
      return times;
    },
    getDates() {
      let dates = [
        {
          key: "CLD_DATE_DEPART_VALIDATION",
          label: this.$t("etat-des-lieux-materiel.form.exit_date"),
          open: false,
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_DATE_RETOUR_VALIDATION",
          label: this.$t("etat-des-lieux-materiel.form.return_date"),
          open: false,
          class: "entrer",
          disabled: this.entrerDisabled,
        },
      ];
      return dates;
    },
  },
  components: {
    Datepicker,
    QrcodeVue,
    SignaturePad,
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format);
    },
    getPhotoFromDropBox() {
      let that = this;
      this.formData["CLD_PHOTO_1"] = {};
      this.formData["CLD_PHOTO_2"] = {};
      let keyTrade = {
        CLD_PHOTO_1: { key: "DEPART", label: "CLD_PHOTO_1" },
        CLD_PHOTO_2: { key: "RETOUR", label: "CLD_PHOTO_2" },
      };
      let path =
        this.data.data["CLD_MATRICULE"] +
        "/" +
        this.data.data["CLD_NUM_CONTRAT"] +
        "/PHOTOS/";
      for (let a = 0; a < Object.values(keyTrade).length; a++) {
        for (let i = 0; i <= 2; i++) {
          let keys = Object.values(keyTrade)[a];
          let filePath = path + keys.key + "_" + i + ".png";
          engine.photo_download(filePath, function (data) {
            if (data.code == 0) {
              let blob = new Blob(
                [new Uint8Array(data.data.result.fileBinary.data)],
                { type: "image/png" }
              );
              const reader = new FileReader();
              reader.onloadend = () => {
                let url = reader.result;
                if (that.formData[keys.label] == "")
                  that.formData[keys.label] = {};
                that.formData[keys.label][i] = url;
                // that.$set(that.formData[keys.label], i, url);
                that.formData[keys.label + "_TMP"] = new Array(
                  Object.keys(that.formData[keys.label]).length
                ).fill({});
                // that.$set(
                //   that.formData,
                //   keys.label + "_TMP",
                //   new Array(Object.keys(that.formData[keys.label]).length).fill(
                //     {}
                //   )
                // );
                that.nbrPhoto[keys.label] =
                  that.formData[keys.label + "_TMP"].length;
                // that.$set(
                //   that.nbrPhoto,
                //   keys.label,
                //   that.formData[keys.label + "_TMP"].length
                // );
              };
              reader.readAsDataURL(blob);
            }
          });
        }
      }
    },
    File_Upload() {
      this.save_html_into_pdf(false, true);
    },
    getEmailList(clientId) {
      let that = this;
      engine.get_email_liste_by_id_client(clientId, function (data) {
        if (data.code == 0) {
          that.emailList = data.data.data[0].fieldData["CONTACT_MAIL_TOUS"]
            .split("|")
            .filter((val) => val !== "");
        }
      });
    },
    reLoadForm() {
      window.location =
        window.location.href + "?contrat=" + this.formData["CLD_NUM_CONTRAT"];
      location.reload();
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    formIsEditable() {
      let that = this;
      // engine.verif_root_mode_with_password(password, function (data) {
      //   if (data.code == 0) {
      if (that.mainClasse === "termine") {
        that.entrerDisabled = !that.entrerDisabled;
        that.sortieDisabled = !that.sortieDisabled;
      } else if (that.mainClasse === "entrer") {
        if (that.sortieDisabled) {
          that.entrerDisabled = false;
          that.sortieDisabled = false;
        } else {
          that.entrerDisabled = false;
          that.sortieDisabled = true;
        }
      }
      that.rootModal = false;
      that.rootErrorMessage = undefined;
      //   } else {
      //     that.rootErrorMessage = that.$t("etat-des-lieux-materiel.form.bad_password");
      //   }
      // });
    },
    deletePic(index, key) {
      delete this.formData[this.photoViewerDataKey][index];
      delete this.formData[this.photoViewerDataKey + "_TMP"][index];

      this.$set(this.nbrPhoto, key, this.nbrPhoto[key] - 1);
      if (this.nbrPhoto[key] < 0) this.$set(this.nbrPhoto, key, 0);
      this.$set(
        this.formData,
        this.photoViewerDataKey + "_TMP",
        this.formData[this.photoViewerDataKey]
      );

      this.photoViewer = false;
      this.photoViewer = true;
    },
    openHandler(pdfApp) {
      window._pdfApp = pdfApp;
    },
    writeSignature(ref, disabled) {
      if (disabled === false) {
        this.selectedSignature = ref;
        this.isSignatureOpen = true;
      }
    },
    downloadPdf() {
      this.save_html_into_pdf(true);
    },
    sharePdf() {
      this.save_html_into_pdf(false);
    },
    send_pdf() {
      engine.send_pdf_by_email(
        this.email_pdf,
        this.pdfLink,
        this.formData["CLD_NUM_CONTRAT"],
        function () {}
      );
      this.sendEmail = false;
    },
    setPhoto1InPhoto2() {
      this.formData["CLD_PHOTO_2"] = this.formData["CLD_PHOTO_1"];
      this.formData["CLD_PHOTO_2_TMP"] = this.formData["CLD_PHOTO_1_TMP"];
      this.nbrPhoto["CLD_PHOTO_2"] = this.nbrPhoto["CLD_PHOTO_1"];
      this.modalSetPhoto2 = false;
    },
    undo(key) {
      this.$refs[key].undoSignature();
    },
    save(key) {
      const { isEmpty, data } = this.$refs[key].saveSignature();
      if (isEmpty === false) this.formData[key] = data;
    },
    clear(ref) {
      this.$refs[ref].clearSignature();
    },
    save_html_into_pdf(download, getUlr) {
      window.scrollTo(0, 0);
      let that = this;

      if (getUlr == undefined || getUlr == false) {
        that.isPrinting = true;
      }
      let qcode = document.querySelector("#qcode");
      qcode.style.display = "block";
      let info = document.querySelectorAll("#info");
      for (let i = 0; i < info.length; i++) info[i].style.display = "block";

      var elms = document.querySelectorAll("#hideInPrint");
      let data = document.querySelector("#capture");
      let form = document.querySelector("#formContainer");

      form.style.height = "auto";
      data.style.width = "1600px";
      data.style.backgroundColor = "#fafafa";
      data.style.border = "solid 1px lightgrey";
      for (let i = 0; i < elms.length; i++) elms[i].style.display = "none";
      let html2canvasOptions = {
        allowTaint: true,
        removeContainer: true,
        backgroundColor: null,
        imageTimeout: 15000,
        logging: true,
        scale: 2,
        useCORS: true,
        processData: false,
        contentType: false,
        windowWidth: 1600,
        windowHeight: 1600,
      };
      html2canvas(data, html2canvasOptions).then(async (canvas) => {
        var imgData = await canvas.toDataURL("image/png");
        var imgWidth = 204;
        var imgHeight = 291;
        var position = 3;
        var doc = new jsPDF("p", "mm");

        doc.addImage(
          imgData,
          "PNG",
          3,
          position,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );

        data.style.width = "auto";
        form.style.height = "70vh";
        for (let i = 0; i < info.length; i++) info[i].style.display = "none";
        that.isPrinting = false;
        that.pdfLink = doc.output("datauristring");
        for (var i = 0; i < elms.length; i++) {
          elms[i].style.display = "block";
        }
        if (getUlr == undefined || getUlr == false) {
          if (download) {
            let id = uuidv4();
            engine.creat_tmp_pdf(id, doc.output(), function (data) {
              if (data.code === 0) {
                let url =
                  data.data.url + "api/v1/" + id + "/Etat%20des%20lieux";
                Browser.open({ url });
              }
            });
          } else {
            that.sendEmail = true;
          }
        } else {
          let dropBoxPath =
            that.formData["CLD_MATRICULE"] +
            "/" +
            that.formData["CLD_NUM_CONTRAT"] +
            "/" +
            that.formData["CLD_NUM_CONTRAT"] +
            "_" +
            that.formData["CLD_MATRICULE"] +
            "_" +
            that.data.title.toUpperCase() +
            ".pdf";
          engine.file_upload(that.pdfLink, dropBoxPath, function () {});
        }
      });
    },
    setParcList() {
      let that = this;
      engine.get_parcs(this.formData["CLD_MATRICULE"], function (data) {
        if (data.code === 0) {
          let parcList = data.data.data.map((val) => val.fieldData);
          that.parc_information = parcList.find(
            (val) => val.MATRICULE === that.formData["CLD_MATRICULE"]
          );
          if (that.parc_information) {
            delete that.parc_information.id;
            delete that.parc_information.MODIFICATION;
            delete that.parc_information.CREATION;
            delete that.parc_information.FAMILLE;
          }
        }
      });
    },

    updatePhotos(data, key, tmpkey) {
      this.getPhotoSrc([data], key, tmpkey);
    },

    resizeBase64Img(base64) {
      return new Promise((resolve) => {
        var canvas = document.createElement("canvas");
        let img = document.createElement("img");
        let quality = 3;
        img.src = base64;

        img.onload = function () {
          let imgWidth = this.width / quality;
          let imgHeight = this.height / quality;
          canvas.width = imgWidth;
          canvas.height = imgHeight;
          var context = canvas.getContext("2d");
          context.drawImage(img, 0, 0, imgWidth, imgHeight);
          resolve(canvas.toDataURL());
        };
      });
    },

    getPhotoSrc(photosList, key, tmpkey) {
      let oldPic = this.formData[key];
      let index = Object.keys(this.formData[key]).length;
      if (
        (photosList && this.formData[tmpkey].length > this.nbrLoadedPhoto) ||
        index >= this.nbrLoadedPhoto
      ) {
        this.formData[tmpkey] = this.formData[tmpkey].slice(
          0,
          this.nbrLoadedPhoto
        );
        this.errorMsg =
          this.$t("etat-des-lieux-materiel.form.max_photo_selected") + " " + this.nbrLoadedPhoto;
        this.showAlert = true;
      } else {
        if (index == 0) this.formData[key] = {};
        else {
          let a = 0;
          for (const value of Object.values(oldPic)) {
            this.formData[key][a] = value;
            a += 1;
          }
        }
        let that = this;
        for (let i = 0; i < photosList.length && i < this.nbrLoadedPhoto; i++) {
          let file = photosList[i][0];
          // let blob = new Blob([file], { type: "image/png" });
          // console.log("blob", blob);
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            that.resizeBase64Img(e.target.result).then((result) => {
              that.formData[key][i + Object.keys(that.formData[key]).length] =
                result;
              that.nbrPhoto[key] = Object.keys(that.formData[key]).length;
            });
          };
        }
      }
    },
    openPhotoViewer(data, key) {
      this.selectedPhotoTypeByClass = data.class;
      this.photoViewerDataKey = key;
      this.photoViewer = true;
    },
    boolToDisplay(bool) {
      if (bool === true) {
        return "block";
      }
      return "none";
    },
    convertBoolean(val) {
      if (val) return "Oui";
      return "Non";
    },

    enterFieldsAreFilled() {
      let requireFields = [
        "CLD_DATE_RETOUR_VALIDATION",
        "CLD_HEURE_RETOUR",
        "CLD_CONTROLEUR_RETOUR",
        "CLD_SIGNATURE_RETOUR",
        "CLD_SIGNATAIRE_RETOUR",
        "CLD_QTE_RETOUR",
      ];
      if (this.changeBackground) return true;
      if (this.personalizedData["ADBLUE"])
        requireFields.push("CLD_NIVEAU_ADBLUE_RETOUR");
      if (this.personalizedData["CARBURANT"])
        requireFields.push("CLD_NIVEAU_GAS_RETOUR");
      if (this.personalizedData["KM"])
        requireFields.push("CLD_KILOMETRE_RETOUR");
      if (this.personalizedData["HORAMETRE"])
        requireFields.push("CLD_HORAMETRE_RETOUR");

      for (let i = 0; i < requireFields.length; i++) {
        if (
          this.formData[requireFields[i]] === undefined ||
          this.formData[requireFields[i]] === ""
        ) {
          this.errorMsg =
            this.$t("etat-des-lieux-materiel.home." + requireFields[i]) +
            " " +
            this.$t("etat-des-lieux-materiel.form.is_missing");
          this.showAlert = true;
          return false;
        }
      }
      if (
        this.formData["CLD_PHOTO_2"].length === 0 &&
        this.isPhoto2Verif === false
      ) {
        this.isPhoto2Verif = true;
        this.modalSetPhoto2 = true;
        return false;
      }
      return true;
    },

    sortieFieldsAreFilled() {
      let requireFields = [
        "CLD_NUM_CONTRAT",
        "CLD_DATE_DEPART_VALIDATION",
        "CLD_HEURE_DEPART",
        "CLD_CONTROLEUR_DEPART",
        "CLD_SIGNATURE_DEPART",
        "CLD_SIGNATAIRE_DEPART",
        "CLD_QTE_DEPART",
      ];
      if (this.personalizedData["ADBLUE"])
        requireFields.push("CLD_NIVEAU_ADBLUE_DEPART");
      if (this.personalizedData["CARBURANT"])
        requireFields.push("CLD_NIVEAU_GAS_DEPART");
      if (this.personalizedData["KM"])
        requireFields.push("CLD_KILOMETRE_DEPART");
      if (this.personalizedData["HORAMETRE"])
        requireFields.push("CLD_HORAMETRE_DEPART");
      for (let i = 0; i < requireFields.length; i++) {
        if (
          this.formData[requireFields[i]] === undefined ||
          this.formData[requireFields[i]] === ""
        ) {
          this.errorMsg =
            this.$t("etat-des-lieux-materiel.home." + requireFields[i]) +
            " " +
            this.$t("etat-des-lieux-materiel.form.is_missing");
          this.showAlert = true;
          return false;
        }
      }
      return true;
    },
    logData() {
      if (this.sortieDisabled && this.enterFieldsAreFilled()) {
        this.updateBdd();
      } else if (this.entrerDisabled && this.sortieFieldsAreFilled()) {
        this.updateBdd();
      } else if (
        !this.entrerDisabled &&
        !this.sortieDisabled &&
        this.sortieFieldsAreFilled() &&
        this.enterFieldsAreFilled()
      ) {
        this.updateBdd();
      }
    },
    updateBdd() {
      delete this.formData["CLD_PHOTO_1_TMP"];
      this.formData["CLD_PHOTO_1"] = JSON.stringify(
        this.formData["CLD_PHOTO_1"]
      );

      delete this.formData["CLD_PHOTO_2_TMP"];
      this.formData["CLD_PHOTO_2"] = JSON.stringify(
        this.formData["CLD_PHOTO_2"]
      );

      if (this.formData["CLD_DATE_DEPART_VALIDATION"] !== "")
        this.formData["CLD_DATE_DEPART_VALIDATION"] = moment(
          this.formData["CLD_DATE_DEPART_VALIDATION"]
        ).format("MM-DD-YYYY");
      if (this.formData["CLD_DATE_RETOUR_VALIDATION"] !== "")
        this.formData["CLD_DATE_RETOUR_VALIDATION"] = moment(
          this.formData["CLD_DATE_RETOUR_VALIDATION"]
        ).format("MM-DD-YYYY");
      this.$emit("newMaterielForm", {
        data: this.formData,
        edit: this.changeBackground,
      });
      this.File_Upload();
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style>
.etat-des-lieux-materiel .dp__calendar {
  width: 500px !important;
  padding: 0px 10px;
}

.etat-des-lieux-materiel .photo {
  position: absolute;
  left: 5px;
  top: 6px;
}
.etat-des-lieux-materiel .photo .v-input__control {
  display: none;
}

#signature {
  border: solid 3px #eeeeee;
}

.centered-input input {
  text-align: center;
}
</style>