<template>
  <v-dialog
    v-model="localDialog"
    persistent
    fullscreen
    transition="slide-y-transition"
    class="etat-des-lieux-materiel"
  >
    <v-card outlined color="#fafafa">
      <materielForm
        @closeDialog="closeDialog()"
        @newMaterielForm="newMaterielForm($event)"
        :data="data"
        :clients_list="clients_list"
        :parcs_list="parcs_list"
        :user_information="user_information"
        :agencyList="agencyList"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import materielForm from "./materielForm";

export default {
  data() {
    return {
      localDialog: true,
    };
  },
  components: {
    materielForm,
  },
  props: {
    dialog: {},
    data: {},
    clients_list: {},
    parcs_list: {},
    user_information: {},
    agencyList: {},
  },
  mounted() {
    this.localDialog = this.dialog;
  },
  watch: {
    localDialog(val) {
      if (val === false) {
        this.closeDialog();
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    newMaterielForm(data) {
      this.$emit("newMaterielForm", data);
    },
  },
};
</script>

<style>
</style>