import axios from "axios";
var CryptoJS = require("crypto-js");

class DataApi {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/statistique";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    getStatData(data, callback) {
        let datas = { data }
        axios.post(this.URL + '/v1/getStatData', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    getClientListe(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/getClientListe', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    getAgencyListe(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/getAgencyListe', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    getContratTypeListe(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/getContratTypeListe', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

}

export default new DataApi()