<template>
  <div class="email-visualisation">
    <v-dialog
      v-if="emailDetailData"
      v-model="isEmailDetailDataOpen"
      width="1200"
    >
      <v-card>
        <v-col>
          <v-col style="font-weight: bold; font-size: 20px">
            Sujet : {{ emailDetailData.subject }}
          </v-col>
          <v-divider></v-divider>
          <v-col>
            <v-card
              class=""
              variant="flat"
              style="overflow-x: auto; overflow-y: auto; max-height: 600px"
            >
              <v-col>
                <div v-html="emailDetailData.body"></div>
              </v-col>
            </v-card>
          </v-col>
          <v-row class="mx-1">
            <v-col
              class="px-0"
              v-for="(value, key) in emailDetailData.events"
              :key="key"
              align="center"
            >
              <v-card
                class="ma-2"
                variant="plain"
                style="border: solid 1px lightgrey"
              >
                <v-col class="pa-1">
                  {{ stateTranslation[key] }}
                  <div v-for="elem in value" :key="elem">
                    {{ getFormatedDate(elem) }}
                    <!-- {{ moment(elem).format("DD/MM/YYYY HH:mm:ss") }} -->
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-col align="center" class="pa-0">
            <v-btn
              color="red"
              style="color: white"
              @click="isEmailDetailDataOpen = false"
              >Fermer</v-btn
            >
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="mx-2 mt-2" align="center">
        <v-card class="mb-2 pa-2" outlined>
          <h1 class="mb-0">EMAIL HISTORIQUE</h1>
          <h5 class="ml-1">Séléctionner une adresse mail</h5>
          <!-- {{ authorizedEmailList }} -->
          <v-autocomplete
            class="px-2"
            variant="outlined"
            dense
            v-model="selectedEmail"
            :items="authorizedEmailList"
          ></v-autocomplete>
        </v-card>
        <v-card v-if="emailsListToShow" outlined class="pa-2 pb-0">
          <!-- <div v-if="emailsListToShowFiltered.length != 0"> -->
          <v-row class="" justify="center" align="center">
            <v-col>
              <v-text-field
                style="border: solid 1px grey"
                dense
                v-model="tmpSearch"
                label="Recherche"
                clearable
                v-on:keyup.enter="search = tmpSearch"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="search = tmpSearch"
                >Rechercher</v-btn
              >
            </v-col>
          </v-row>
          <v-col>
            <v-row>
              <v-col class="px-0">
                <v-btn
                  :color="type == 'send' ? 'primary' : ''"
                  elevation="0"
                  style="width: 100%"
                  @click="type = 'send'"
                  :text="type != 'send'"
                  >Envoyé</v-btn
                >
              </v-col>
              <v-col class="px0-0">
                <v-btn
                  :color="type == 'receive' ? 'primary' : ''"
                  elevation="0"
                  style="width: 100%"
                  @click="type = 'receive'"
                  :text="type != 'receive'"
                  >Recus</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-table height="59vh" fixed-header :hide-default-footer="true">
            <thead>
              <tr>
                <th v-for="elem in headers" :key="elem.value" class="text-left">
                  {{ elem.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in emailsListToShowFiltered[type]"
                :key="idx"
              >
                <td v-for="elem in headers" :key="elem.value">
                  {{ item[elem.value] }}
                </td>
                <td>
                  <v-btn
                    elevation="0"
                    small
                    @click="getEmailDetail(item)"
                    color="primary"
                  >
                    Ouvrir
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <div
              v-if="emailsListToShowFiltered[type].length == 0"
              align="center"
              style="position: absolute; width: 100%"
            >
              <div align="center">
                <h3 class="mt-3">Aucun mail en mémoire.</h3>
              </div>
            </div>
          </v-table>

          <v-col class="pt-3 pb-2">
            <div class="text-center">
              <v-pagination
                circle
                :total-visible="20"
                v-model="page"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-col>
        </v-card>
        <v-progress-circular
          style="margin-top: 30vh"
          v-else
          size="90"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <!-- <v-btn v-if="getItems" class="mt-1" style="width: 100%">
          <download-excel :name="'export_liste_mails'" :data="json_data">
            Exporter les données
          </download-excel>
        </v-btn> -->
      </v-col>
    </v-row>
    <div
      v-if="isLoading"
      style="
        position: absolute;
        height:100%;
        width: 100%;
        top:0px;
        background-color:rgb(0,0,0,0.3)
        z-index: 3;
        padding-top:40vh;"
      align="center"
    >
      <v-progress-circular size="120" indeterminate color="black"
        >Chargement</v-progress-circular
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import JsonExcel from "vue-json-excel";
import engine from "../core";
import mainEngine from "@/core/index";
import axios from "axios";

// Vue.component("downloadExcel", JsonExcel);

export default {
  data() {
    return {
      itemsPerPage: 40,
      page: 1,
      pageCount: 0,
      type: "send",
      isLoading: false,
      tmpSearch: "",
      search: "",
      stateTranslation: {
        sent: "Expédié",
        delivered: "Délivré",
        click: "Cliqué",
        opened: "Ouvert",
        open: "Ouvert",
        proxy_open: "Proxy ouvert",
        error: "Erreur",
        invalid_email: "Invalid email",
        blocked: "Bloqué",
      },
      headers: [
        { text: "Date", value: "date" },
        { text: "Expéditeur", value: "from" },
        { text: "Destinataire", value: "email" },
        { text: "Subject", value: "subject" },
        { text: "Details", value: "details" },
      ],
      isEmailDetailDataOpen: false,
      emailDetailData: undefined,
      emailContentSortByEmail: {},
      selectedEmail: undefined,
      emailsListToShow: undefined,
      authorizedEmailList: undefined,
    };
  },
  mounted() {
    let that = this;
    let token = localStorage.getItem("userToken");
    mainEngine.get_user_by_token(token, function (data) {
      if (data.code == 0) {
        let user = data.data.data.data[0].fieldData;
        engine.sendinBlue_user_get_info(user.user_id, function callback(data) {
          if (data.code === 0) {
            let obj = data.data;
            that.authorizedEmailList = obj.MAILS.split("|");
            if (that.authorizedEmailList.length !== 0) {
              that.selectedEmail = that.authorizedEmailList[0];
            }
          } else {
            that.creatMsg = {
              value: "Identifiant ou mot de passe incorecte",
              color: that.errorColor,
            };
            setTimeout(function () {
              that.creatMsg = undefined;
            }, 5000);
          }
        });
      }
      that.isLoading = false;
    });
  },
  watch: {
    tmpSearch(val) {
      if (val == null || val.length == 0) {
        this.search = val;
      }
    },
    selectedEmail(val) {
      let that = this;
      that.emailsListToShow = undefined;
      engine.sendinBlue_get_emails_list(val, function (data) {
        if (data.code == 0) {
          let mails = data.data.data.filter((val) =>
            moment(val.fieldData.date).isAfter(moment().subtract(30, "d"))
          );
          that.emailsListToShow = mails.map((val) => ({
            date: val.fieldData.date,
            email: val.fieldData.email,
            event: val.fieldData.event,
            from: val.fieldData.from,
            json: val.fieldData.json,
            subject: val.fieldData.subject,
            messageId: val.fieldData.messageId,
            api_key: val.fieldData.api_key,
          }));
        } else {
          that.emailsListToShow = [];
        }
      });
    },
  },
  methods: {
    getFormatedDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    filterOnlyCapsText(value, search) {
      if (value === undefined || value.length == 0) return false;
      if (
        value
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
          )
      )
        return true;
      return false;
    },
    getEmailDetail(email) {
      let that = this;
      that.isLoading = true;
      let url =
        "https://api.sendinblue.com/v3/smtp/emails?messageId=%3C" +
        email.messageId.replaceAll("<", "").replaceAll(">", "") +
        "%3E&sort=desc&limit=500&offset=0";
      axios
        .get(url, {
          responseType: "json",
          credentials: false,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "api-key": email.api_key,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let data = res.data.transactionalEmails.find(
              (val) =>
                val.email == email.email &&
                val.from == email.from &&
                val.subject == email.subject
            );
            let url2 = "https://api.sendinblue.com/v3/smtp/emails/" + data.uuid;
            axios
              .get(url2, {
                responseType: "json",
                credentials: false,
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "api-key": email.api_key,
                },
              })
              .then((res) => {
                if (res.status == 200) {
                  that.emailDetailData = res.data;
                  let objectEvent = {};
                  for (let i = 0; i < that.emailDetailData.events.length; i++) {
                    if (
                      objectEvent[that.emailDetailData.events[i].name] ==
                      undefined
                    ) {
                      objectEvent[that.emailDetailData.events[i].name] = [];
                    }
                    objectEvent[that.emailDetailData.events[i].name].push(
                      that.emailDetailData.events[i].time
                    );
                  }
                  that.emailDetailData.events = objectEvent;
                  that.isEmailDetailDataOpen = true;
                }
                that.isLoading = false;
              });
          }
        });
    },
  },
  computed: {
    emailsListToShowFiltered() {
      if (!this.emailsListToShow) return [];
      let emails = {};
      // if (this.type === "send") {
      emails["send"] = this.emailsListToShow
        .filter((val) => val.from === this.selectedEmail)
        .map((val) => ({
          date: moment(JSON.parse(val.json).date).format("DD/MM/YYYY HH:mm:ss"),
          email: val.email,
          from: val.from,
          subject: val.subject,
          messageId: val.messageId,
          api_key: val.api_key,
        }))
        .filter((val) =>
          (val.email + val.from + val.subject).includes(this.search)
        );
      // } else {
      emails["receive"] = this.emailsListToShow
        .filter((val) => val.email === this.selectedEmail)
        .map((val) => ({
          date: moment(JSON.parse(val.json).date).format("DD/MM/YYYY HH:mm:ss"),
          email: val.email,
          from: val.from,
          subject: val.subject,
          messageId: val.messageId,
          api_key: val.api_key,
        }))
        .filter((val) =>
          (val.email + val.from + val.subject).includes(this.search)
        );
      // }
      return emails;
    },
    json_data() {
      let json = [];
      if (this.getItems && this.getItems.length != 0)
        for (let i = 0; i < this.getItems.length; i++) {
          json.push({
            Date: this.getItems[i].date,
            Expéditeur: this.getItems[i].from,
            Destinataire: this.getItems[i].email,
            Subject: this.getItems[i].subject,
          });
        }
      return json;
    },
    getItems() {
      return this.emailContentSortByEmail[this.selectedEmail];
    },
  },
};
</script>

<style>
.email-visualisation .v-pagination__list {
  display: none;
}
</style>