<template>
  <div align="center" style="position: relative" class="etat-des-lieux-velo">
    <div v-if="isLoading" style="position: absolute; top: 30%; width: 100%">
      <h2 class="my-3">
        Chargement
      </h2>
      <v-progress-linear
        style="width: 70%"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      >
      </v-progress-linear>
    </div>
    <p v-if="errorMessage" class="error">
      {{ errorMessage }}
    </p>
    <qrcode-stream @decode="onDecode" @init="onInit" align="right">
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  data() {
    return {
      decodedContent: "",
      errorMessage: undefined,
      isLoading: true,
    };
  },
  components: {
    QrcodeStream,
  },
  methods: {
    onDecode(content) {
      this.decodedContent = content;
      this.$router.push({ path: "/", query: { contrat: this.decodedContent } });
      this.$router.go();
    },

    onInit(promise) {
      let that = this;
      promise
        .then(() => {
          that.isLoading = false;
        })
        .catch((error) => {
          if (error.name === "NotAllowedError") {
            this.errorMessage = "Hey! I need access to your camera";
          } else if (error.name === "NotFoundError") {
            this.errorMessage = "Do you even have a camera on your device?";
          } else if (error.name === "NotSupportedError") {
            this.errorMessage =
              "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
          } else if (error.name === "NotReadableError") {
            this.errorMessage =
              "Couldn't access your camera. Is it already in use?";
          } else if (error.name === "OverconstrainedError") {
            this.errorMessage =
              "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
          } else {
            this.errorMessage = "UNKNOWN ERROR: " + error.message;
          }
          that.isLoading = false;
        });
    },
  },
};
</script>
