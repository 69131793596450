<template>
  <div v-if="showApp">
    <etat-des-lieux-velo></etat-des-lieux-velo>
  </div>
</template>

<script>
import EtatDesLieuxVelo from "@/projects/etat-des-lieux-velo/component/page-home.vue";

export default {
  data() {
    return {
      showApp: false,
    };
  },
  mounted() {
    let appsToShow = this.$store.state.webApplicationToShow;
    let currentApp = this.$route.fullPath.replace("/", "").split('?')[0];
    if (appsToShow.includes(currentApp)) this.showApp = true;
  },
  components: {
    EtatDesLieuxVelo,
  },
};
</script>

<style>
</style>