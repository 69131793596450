<template>
  <div class="statistique">
    <v-dialog v-model="dateDialogIsOpen">
      <v-card align="center">
        <v-col class="pa-1">
          <datepicker
            style="border: solid lightgrey 2px; border-radius: 5px"
            :inline="true"
            placeholder="Select Month"
            :minimum-view="'month'"
            :maximum-view="'month'"
            @selected="dateChange"
            :language="'fr'"
          ></datepicker>
        </v-col>
        <v-btn
          class="mx-1"
          color="#7986CB"
          variant="flat"
          style="color: white; font-weight: bold"
          @click="dateValidation"
          >Valider</v-btn
        >
        <v-btn
          class="ma-1"
          color="#E57373"
          variant="flat"
          style="color: white; font-weight: bold"
          @click="dateDialogIsOpen = false"
        >
          Fermer
        </v-btn>
      </v-card>
    </v-dialog>
    <v-col>
      <v-row
        justify="center"
        align="center"
        :style="[!showChart ? 'margin-top: 6vh' : '']"
      >
        <v-col v-if="showChart == false" col="12" md="6" sm="10">
          <v-card
            v-if="isLoading == false"
            variant="flat"
            style="border: solid 1px #bdbdbd"
            color="#E0E0E0"
          >
            <div
              align="center"
              class="pa-4"
              style="font-weight: bold; font-size: 21px"
            >
              <div
                style="
                  border: solid 1px grey;
                  border-radius: 10px;
                  background-color: #757575;
                  color: white;
                "
                class="pa-2"
              >
                STATISTIQUES
              </div>
            </div>
            <v-divider></v-divider>
            <v-col>
              <div v-for="elem in formElem" :key="elem.key">
                <div v-if="elem.type != 'date'">
                  <v-autocomplete
                    v-if="elem.selected"
                    style="background-color: white"
                    variant="outlined"
                    hide-details
                    :label="elem.label"
                    v-model="elem.value"
                    :disabled="selectAllData"
                    :items="formList[elem.key]"
                    clearable
                  >
                  </v-autocomplete>
                  <v-text-field
                    v-else
                    style="background-color: white"
                    variant="outlined"
                    hide-details
                    :label="elem.label"
                    v-model="elem.value"
                    :disabled="selectAllData"
                    clearable
                  ></v-text-field>
                </div>
                <div v-if="elem.type == 'date'">
                  <v-text-field
                    style="background-color: white"
                    clearable
                    variant="outlined"
                    readonly
                    hide-details
                    :disabled="selectAllData"
                  >
                    <div style="width: 100%" @click="dateDialogIsOpen = true">
                      <div v-if="dateToShow && dateToShow.length != 0">
                        {{ dateToShow }} 
                      </div>
                      <div v-else style="color: grey">{{ elem.label }}</div>
                    </div>
                    <div
                      v-if="dateToShow && dateToShow.length != 0"
                      style="
                        position: absolute;
                        right: 10px;
                        z-index: 2;
                        cursor: pointer;
                        color: red;
                      "
                      @click="clearDate"
                    >
                      X
                    </div>
                  </v-text-field>
                </div>
                <br />
              </div>
              <v-checkbox
                hide-details
                v-model="selectAllData"
                label="Rechercher sur l'ensemble des données disponible"
              ></v-checkbox>
              <v-btn
                style="
                  width: 100%;
                  border: solid 1px grey;
                  font-weight: bold;
                  color: white;
                "
                variant="flat"
                color="#3F51B5"
                @click="searchStats()"
                >Rechercher</v-btn
              >
              <div v-if="errorMsg" class="mt-3">
                <v-card color="red">
                  <v-col style="color: white" align="center">
                    {{ errorMsg }}
                  </v-col>
                </v-card>
              </div>
            </v-col>
          </v-card>
          <div v-else align="center" style="margin-top: 25vh">
            <v-progress-circular size="120" indeterminate color="black"
              >Chargement</v-progress-circular
            >
          </div>
        </v-col>
        <v-col v-else class="px-2 py-1">
          <stats-vue
            :tabHeaders="tabHeaders"
            :tabItems="tabItems"
            :filteredData="filteredData"
            @close="closeChartVue($event)"
          ></stats-vue>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import engine from "../core/DataApi";
import statsVue from "./statsVue.vue";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";

export default {
  data() {
    return {
      errorMsg: undefined,
      tmpDate: undefined,
      dateToShow: undefined,
      selectedDate: undefined,
      dateDialogIsOpen: false,
      selectAllData: false,
      tabHeaders: [
        { key: "Mot_Agence", label: "Agence" },
        { key: "Mot_Client", label: "Client" },
        { key: "Mot_Compta", label: "Code comptabilitée" },
        { key: "Mot_Date", label: "Date" },
        { key: "Mot_Origine", label: "Type" },
        { key: "Mot_Societe", label: "Societe" },
        { key: "Mot_Technicien", label: "Technicien" },
        { key: "Mot_Marge", label: "Marge" },
        { key: "Mot_Marge_Com", label: "Marge commune" },
        { key: "Mot_Ca", label: "Chiffre d'affaire" },
        { key: "Mot_Commercial", label: "Commercial" },
      ],
      tabItems: [],
      filteredData: [],
      showChart: false,
      isLoading: false,
      formList: {
        Mot_Client: [],
        Mot_Agence: [],
        Mot_Origine: [],
      },
      formElem: [
        {
          label: "Client",
          key: "Mot_Client",
          mandatory: false,
          value: undefined,
          selected: true,
        },
        {
          label: "Agence",
          key: "Mot_Agence",
          mandatory: false,
          value: undefined,
          selected: true,
        },
        {
          label: "Type",
          key: "Mot_Origine",
          mandatory: false,
          value: undefined,
          selected: true,
        },
        {
          label: "Code comptabilitée",
          key: "Mot_Compta",
          mandatory: false,
          value: undefined,
          selected: false,
        },
        {
          label: "Date",
          key: "Mot_Date",
          mandatory: false,
          value: undefined,
          type: "date",
          selected: false,
        },
      ],
    };
  },
  mounted() {
    let that = this;
    engine.getAgencyListe(function (data) {
      if (data.code == 0) {
        that.formList["Mot_Agence"] = data.data.data.map(
          (val) => val.fieldData.ag_nom
        );
      }
    });
    engine.getContratTypeListe(function (data) {
      if (data.code == 0) {
        that.formList["Mot_Origine"] = data.data.data.map(
          (val) => val.fieldData.Key
        );
      }
    });
    engine.getClientListe(function (data) {
      if (data.code == 0) {
        that.formList["Mot_Client"] = data.data.data.map(
          (val) => val.fieldData.SOCIETE
        );
      }
    });
    this.formElem.find((val) => val.key == "Mot_Date").value =
      moment().format("MM/YYYY");
    this.dateToShow = moment().locale("fr").format("MMMM YYYY").toUpperCase();
    this.searchStats();
  },
  methods: {
    dateChange(date) {
      this.tmpDate = date;
    },
    dateValidation() {
      this.dateToShow = moment(this.tmpDate)
        .locale("fr")
        .format("MMMM YYYY")
        .toUpperCase();
      this.selectedDate = moment(this.tmpDate).format("MM-YYYY");
      this.setDate(this.selectedDate);
      this.dateDialogIsOpen = false;
    },
    clearDate() {
      this.dateToShow = undefined;
      this.selectedDate = undefined;
      this.dateDialogIsOpen = false;
      this.setDate(undefined);
    },
    closeChartVue() {
      this.showChart = false;
      this.filteredData = [];
      this.tabItems = [];
    },
    setDate(date) {
      let index = this.formElem.findIndex((val) => val.type == "date");
      this.formElem[index].value = date;
    },
    searchStats() {
      let that = this;
      if (this.getFilledData.length != 0 || this.selectAllData == true) {
        let formData = {};
        for (let i = 0; i < this.getFilledData.length; i++) {
          formData[this.getFilledData[i].key] =
            "==" + this.getFilledData[i].value;
        }
        that.isLoading = true;
        if (this.selectAllData == true) formData = undefined;
        else formData = [formData];
        engine.getStatData(formData, function (data) {
          if (data.code == 0) {
            that.filteredData = data.data.data.map((val) => val.fieldData);
            for (let i = 0; i < that.filteredData.length; i++) {
              let item = {};
              for (let a = 0; a < that.tabHeaders.length; a++) {
                item[that.tabHeaders[a].key] =
                  that.filteredData[i][that.tabHeaders[a].key];
              }
              that.tabItems.push(item);
            }
            that.showChart = true;
          } else {
            that.errorMsg =
              "Aucune donnée de statistique trouvé pour cette recherche.";
            for (let i = 0; i < that.getFilledData.length; i++) {
              let value = that.getFilledData[i].value;
              if (that.getFilledData[i].key == "Mot_Date"){
                value = that.dateToShow
              }
              that.errorMsg +=
                " - " + that.getFilledData[i].label + " : " + value;
            }
            setInterval(function () {
              that.errorMsg = undefined;
            }, 10000);
          }
          that.isLoading = false;
          that.selectAllData = false;
        });
      } else {
        that.errorMsg =
          "Le formulaire doit comprendre au minimum un champ de remplis.";
        setInterval(function () {
          that.errorMsg = undefined;
        }, 10000);
      }
    },
  },
  computed: {
    getFilledData() {
      return this.formElem.filter(
        (val) =>
          val.value != undefined &&
          val.value != null &&
          val.value.replace(/ +(?= )/g, "").length > 1
      );
    },
  },
  components: {
    statsVue,
    Datepicker,
  },
};
</script>

<style>
@import "../style.css";
</style>