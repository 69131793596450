// import { ref } from "@vue/reactivity";
// import { watch } from "vue";
import { computed } from "@vue/runtime-core";
// import { onMounted } from "vue";
// import moment from "moment";

export default function pieChart(getTabItemsSorted, constOrigineColor) {

    const getPieData = computed(function() {
        let chartData = {
            labels: Object.keys(getTabItemsSorted.value.origine),
            datasets: [{
                backgroundColor: Object.keys(
                    getTabItemsSorted.value.origine
                ).map((val) => constOrigineColor.value[val]),
                data: Object.values(getTabItemsSorted.value.origine).map(
                    (val) => (100 * val.CA / getTabItemsSorted.value.calcule.Mot_Ca.total)
                ),
            }, ],
        }
        let options = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: 10,
            size: 10,
            // scales: {
            //     yAxes: [{
            //         ticks: {
            //             beginAtZero: true,
            //         },
            //     }, ],
            // },
            plugins: {
                title: {
                    display: true,
                    text: 'CHIFFRE D\'AFFAIRE (%)	'
                },
                datalabels: {
                    backgroundColor: 'white',
                    borderRadius: 100,
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                    formatter: function(value) {
                        return value.toFixed(2) + '%'
                    }
                },

            },
        }
        return { chartData, options }
    })

    return { getPieData };

}