<template>
  <v-col v-if="!isLoading">
    <v-row>
      <v-col
        v-for="elem in getFilteredItem"
        :key="elem.href"
        cols="6"
        sm="4"
        md="3"
        lg="2"
      >
        <v-card
          :color="elem.color"
          min-height="150"
          height="100%"
          style="cursor: pointer"
          class="home-card"
          @click="goTo(elem.href)"
        >
          <v-col align="center">
            <h2>{{ elem.text }}</h2>
            <v-icon size="80"> {{ elem.icon }} </v-icon>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
  <div v-else>
    <v-col align="center" style="margin-top: 35vh">
      <v-progress-circular size="120" indeterminate color="black"
        >Chargement</v-progress-circular
      >
    </v-col>
  </div>
</template>

<script>
import engine from "@/core/index";

export default {
  data() {
    return {
      isLoading: true,
      webApplicationToShow: [],
      items: [
        {
          text: "Planning Disponibilité",
          href: "planning-disponibilite",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning Livraison",
          href: "planning-livraison",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning Technicien",
          href: "planning-technicien",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Statistiques",
          href: "statistiques",
          color: "#FFF176",
          icon: "mdi-chart-bar",
          show: false,
        },
        {
          text: "Email visualisation",
          href: "email-visualisation",
          color: "#5C6BC0",
          icon: "mdi-email",
          show: false,
        },
        {
          text: "Etat des lieux materiel",
          href: "etat-des-lieux-materiel",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
        },
        {
          text: "Etat des lieux vélo",
          href: "etat-des-lieux-velo",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
        },
      ],
    };
  },
  mounted() {
    let that = this;
    let token = localStorage.getItem("userToken");
    engine.get_user_by_token(token, function (data) {
      if (data.code == 0) {
        that.webApplicationToShow =
          data.data.data.data[0].fieldData.web_application_to_show.split(",");
        for (let i = 0; i < that.webApplicationToShow.length; i++) {
          that.items.find(
            (val) => val.href == that.webApplicationToShow[i]
          ).show = true;
        }
        that.$store.state.webApplicationToShow = that.webApplicationToShow;
        that.isLoading = false;
      } else {
        that.$router.push("/login");
      }
    });
    this.selectedPage = window.location.pathname.replace("/", "");
  },
  computed: {
    getFilteredItem() {
      return this.items.filter((val) => val.show == true);
    },
  },
  methods: {
    goTo(href) {
      this.$router.push(href);
    },
  },
};
</script>

<style>
.home-card {
  padding-top: 10px;
  border: solid 1px black;
}
.home-card:hover {
  padding: 0px;
  border: solid black;
}
</style>