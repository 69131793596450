<template>
  <v-card
    v-if="isLoading == false"
    class="planning-disponibilite"
    color="#C8E6C9"
  >
    <v-dialog v-model="isModalsOpen['eventSavModal'].value" persistent>
      <modal-sav
        :data="isModalsOpen['eventSavModal'].data"
        :eventColorByType="eventColorByType"
        @closeModal="closeModal('eventSavModal')"
      ></modal-sav>
    </v-dialog>
    <v-dialog v-model="isModalsOpen['dateModal'].value" persistent>
      <modal-date
        :data="isModalsOpen['dateModal'].data"
        :eventColorByType="eventColorByType"
        @closeModal="closeModal('dateModal')"
      ></modal-date>
    </v-dialog>
    <v-dialog v-model="isModalsOpen['eventModal'].value">
      <modal-materiel-state
        :data="isModalsOpen['eventModal'].data"
        @close="closeModal('eventModal')"
      ></modal-materiel-state>
      <!-- <modal-event
        :data="isModalsOpen['eventModal'].data"
        @closeModal="closeModal('eventModal')"
      ></modal-event> -->
    </v-dialog>
    <v-dialog v-model="isModalsOpen['settingsModal'].value" persistent>
      <modal-settings
        :eventColorByType="eventColorByType"
        :daysToHide="daysToHide"
        @closeSettingsModal="closeSettingsModal"
      ></modal-settings>
    </v-dialog>
    <div style="position: absolute; top: 60px; width: 100%">
      <v-row class="ma-1" align="center">
        <v-col class="px-1">
          <v-autocomplete
            v-model="ressourceSelected"
            :items="getRessourceList"
            label="Sélectionner une famille"
            hide-details
            hide-selected
            style="border: solid 1px lightgrey; background-color: white"
          >
          </v-autocomplete>
        </v-col>
        <v-col v-if="ressourceSelected != undefined" cols="auto" class="px-1">
          <button
            @click="ressourceSelected = undefined"
            style="
              height: 40px;
              width: 40px;
              border-radius: 50%;
              color: red;
              font-size: 20px;
              background-color: white;
            "
          >
            ✖
          </button>
        </v-col>
        <!-- <div justify="center"> <v-btn>sd</v-btn></div> -->
      </v-row>
    </div>
    <full-calendar
      id="calendar"
      style="margin-top: 10px; margin-bottom: 10px"
      class="mx-2"
      :options="calendarOptions"
    >
    </full-calendar>
    <div style="position: absolute; top: 83vh; right: 20px; z-index: 1">
      <div
        style="
          background-color: #e0e0e0;
          border-style: none;
          padding: 5px;
          border-radius: 10px;
          box-shadow: 1px 1px 10px 2px grey;
        "
      >
        <button
          v-for="(value, key) in eventColorByType"
          :key="key"
          :style="{ 'background-color': value.color }"
          style="
            font-size: 15px;
            margin: 5px;
            border: solid 1px grey;
            padding: 10px;
            border-style: none;
            border-radius: 5px;
            color: black;
          "
        >
          <v-chip
            style="
              background-color: rgb(0, 0, 0, 0.5);
              color: white;
              cursor: pointer;
            "
          >
            {{ value.label }}
          </v-chip>
        </button>
      </div>
    </div>
  </v-card>
  <div v-else>
    <v-col align="center" style="margin-top: 40vh">
      <v-progress-circular size="120" indeterminate color="black"
        >Chargement</v-progress-circular
      >
    </v-col>
  </div>
</template>

<script>
import mainEngine from "@/core/index";

import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import calendarData from "../hooks/index";
import engine from "../core/index";
import modalDate from "@/projects/planning-disponibilite/components/modal-date.vue";
// import modalEvent from "@/projects/planning-disponibilite/components/modal-event.vue";
import ModalMaterielState from "@/components/modal-materiel-state.vue";
import modalSettings from "@/components/modal-planning-settings.vue";
import modalSav from "@/projects/planning-disponibilite/components/modal-sav.vue";

export default {
  data() {
    return {
      engine: engine,
      ressourceSelected: undefined,
      isLoading: true,
      user: {},
    };
  },
  setup() {
    const {
      calendarOptions,
      resourceIds,
      setSubFamilyToShow,
      isModalsOpen,
      setModal,
      eventColorByType,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
    } = calendarData();
    return {
      calendarOptions,
      resourceIds,
      setSubFamilyToShow,
      isModalsOpen,
      setModal,
      eventColorByType,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
    };
  },
  mounted() {
    this.isUserConnected();
  },
  methods: {
    closeSettingsModal(data) {
      let that = this;
      let days = data.daysListe.filter(
        (val) => !data.daysToShow.includes(val.idx)
      );
      if (days) {
        days = days.map((val) => val.idx);
        engine.save_day_to_hide(days.toString(), function () {
          that.updateCalendar();
        });
      }
      engine.save_calendar_colors(JSON.stringify(data.localColortab), function () {
        that.updateCalendar();
      });
      this.closeModal("settingsModal");
    },
    isUserConnected() {
      let that = this;
      let token = localStorage.getItem("userToken");
      mainEngine.get_user_by_token(token, function (data) {
        if (data.code != 0) {
          localStorage.clear();
        }
        that.isLoading = false;
      });
    },
    updateCalendar() {
      this.updateCalendarColors();
      this.updateCalendarDaysToShow();
    },
    closeModal(modalKey) {
      this.isModalsOpen[modalKey].value = false;
    },
  },
  watch: {
    ressourceSelected(val) {
      this.setSubFamilyToShow(val);
    },
  },
  computed: {
    getRessourceList() {
      return [...new Set(this.resourceIds.map((val) => val.subFamily))];
    },
  },
  components: {
    FullCalendar,
    modalDate,
    // modalEvent,
    ModalMaterielState,
    modalSettings,
    modalSav,
  },
};
</script>

<style>
@import "../style.css";
</style>