import axios from "axios";
var CryptoJS = require("crypto-js");

class DataAPI {
    constructor() {
        this.URL = "https://test.etatdl-velo.accen.me/api";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    creat_tmp_pdf(id, file, callback) {
        let datas = { id, file }
        axios.post(this.URL + '/v1/creat_tmp_pdf', datas, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(res.data);
        });
    }

    set_users_settings(data, callback) {
        let datas = { data }
        axios.post(this.URL + '/v1/set_users_settings', datas, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(res.data);
        });
    }

    get_users_settings(isRoot, callback) {
        let datas = { isRoot }
        axios.post(this.URL + '/v1/get_users_settings', datas, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(res.data);
        });
    }


    // get_image_filter(contrat, matricule, callback) {
    //     let datas = { contrat, matricule }
    //     axios.post(this.URL + '/v1/get_image_filter', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json'
    //         }
    //     }).then(res => {
    //         callback(res.data);
    //     });
    // }

    // get_signatures(contrat, matricule, callback) {
    //     let datas = { contrat, matricule }
    //     axios.post(this.URL + '/v1/get_signatures', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json'
    //         }
    //     }).then(res => {
    //         callback(res.data);
    //     });
    // }

    // set_image_filter(datas, callback) {
    //     axios.post(this.URL + '/v1/set_image_filter', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json'
    //         }
    //     }).then(res => {
    //         callback(res.data);
    //     });
    // }

    // set_signatures(datas, callback) {
    //     axios.post(this.URL + '/v1/set_signatures', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json'
    //         }
    //     }).then(res => {
    //         callback(res.data);
    //     });
    // }

    user_get_info(callback) {
        let token = localStorage.getItem('token')
        let datas = { token }
        axios.post(this.URL + '/v1/user_get_info', datas, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(res.data);
        });
    }

    is_token_valid(callback) {
        let token = localStorage.getItem('token')
        let datas = { token }
        axios.post(this.URL + '/v1/is_token_valid', datas, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(res.data);
        });
    }

    user_login(user, password, callback) {
        let datas = { user_email: user, user_password: password }
        axios.post(this.URL + '/v1/user_login', datas, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(res.data);
        }).catch(() => {
            callback({ code: 502 });
        })
    }

    // get_parmanent_comment_by_id_pan(id_pan, callback) {
    //     var datas = { id_pan };
    //     axios.post(this.URL + '/v1/get_parmanent_comment_by_id_pan', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {}
    //     }).then(res => {
    //         callback(res.data);
    //     }, reserror => { callback(reserror); });
    // }

    // set_parmanent_comment_by_id_pan(id_pan, observation, callback) {
    //     var datas = { id_pan, observation };
    //     axios.post(this.URL + '/v1/set_parmanent_comment_by_id_pan', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {}
    //     }).then(res => {
    //         callback(res.data);
    //     }, reserror => { callback(reserror); });
    // }

    get_agency(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_agency', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.data);
        }, reserror => { callback(reserror); });
    }

    edit_material(matricule, data, callback) {
        var datas = { matricule, data };
        axios.post(this.URL + '/v1/edit_material', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.data);
        }, reserror => { callback(reserror); });

    }

    // get_contrat_liste_by_contrat_num(contrat_id, callback) {
    //     var datas = { contrat_id };
    //     axios.post(this.URL + '/v1/get_contrat_liste_by_contrat_num', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {}
    //     }).then(res => {
    //         callback(res.data);
    //     }, reserror => { callback(reserror); });
    // }

    // get_contrat_id_list(callback) {
    //     var datas = {};
    //     axios.post(this.URL + '/v1/get_contrat_id_list', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {}
    //     }).then(res => {
    //         callback(res.data);
    //     }, reserror => { callback(reserror); });
    // }


    get_clients(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_clients', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.data);
        });
    }

    get_materials(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_materials', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.data);
        }, reserror => { callback(reserror); });
    }

    // get_fiche_velo_by_id(id_materiel, callback) {
    //     var datas = { id_materiel };
    //     axios.post(this.URL + '/v1/get_fiche_velo_by_id', datas, {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {}
    //     }).then(res => {
    //         callback(res.data);
    //     }, reserror => { callback(reserror); });
    // }

    send_pdf_by_email(email, pdf, contrat, callback) {
        let datas = { email, pdf, contrat }
        axios.post(this.URL + '/v1/send_pdf_by_email', datas, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(res.data);
        });

    }

    get_email_liste_by_id_client(idClient, callback) {
        var datas = { idClient };
        axios.post(this.URL + '/v1/get_email_liste_by_id_client', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.data);
        });
    }

    get_societe_by_id(id, callback) {
        var datas = { id };
        axios.post(this.URL + '/v1/get_societe_by_id', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.data);
        });
    }

}
export default new DataAPI()