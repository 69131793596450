import { ref } from "@vue/reactivity";
import { watch } from "vue";
import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
import moment from "moment";

export default function chartLine(
    chartData
) {
    const series = ref({ datasets: [] });
    const chartOptions = ref([]);
    const constOrigineColor = ref({ 'LOCATION': '#7986CB', 'VENTE': '#66BB6A', 'SAV': '#A1887F' })

    onMounted(() => {
        updateData()
    })

    watch(chartData, () => {
        updateData()
    })

    const updateData = function() {
        let colorstab = []
        series.value = {}
        series.value.labels = {}
        series.value.datasets = []
        let labels = chartData.value.barLabels
        for (const [key, value] of Object.entries(chartData.value.origine)) {
            let tmpdata = value.data
            let finalData = JSON.parse(JSON.stringify(chartData.value.barLabelsObj))
            for (const [key2, value2] of Object.entries(tmpdata)) {
                finalData[key2].y += value2
            }
            series.value.datasets.push({
                label: value.label,
                data: Object.values(finalData),
                parsing: {
                    xAxisKey: "x",
                    yAxisKey: "y"
                },
                fill: false,
                backgroundColor: constOrigineColor.value[key]
            })
            colorstab.push(constOrigineColor.value[key])
        }
        series.value["labels"] = labels
        chartOptions.value = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: 'CHIFFRE D\'AFFAIRE (€)	'
                },
                datalabels: {
                    display: false,
                    // anchor: 'end',
                    color: "black",
                    align: 'top',
                    backgroundColor: 'rgb(255,255,255)',
                    borderRadius: 50,
                    borderColor: 'lightgrey',
                    borderWidth: 1,
                    font: {
                        // weight: 'bold',
                        // size: 12
                    },
                    rotation: -90,
                    formatter: function(value) {
                        if (typeof value.y == 'number') {
                            return Number(value.y).toLocaleString() + ' €'
                        }
                        return value.y
                    }
                },
                legend: {
                    display: true,
                    labels: {
                        fontColor: 'grey',
                        usePointStyle: true
                    }
                },
                tooltip: {
                    callbacks: {
                        title: function(tooltipItems) {
                            return moment(parseInt(tooltipItems[0].label)).locale("fr").format('MMMM YYYY').toUpperCase()
                        }
                    },
                    mode: 'index',
                    intersect: false
                },
            },
            height: 200,
            animation: {
                duration: 0
            },
            scales: {
                // yAxes: [{
                //     ticks: {
                //         beginAtZero: true,
                //     },
                // }, ],
                x: {
                    ticks: {
                        count: 20,
                        callback: function(value, index) {
                            return moment(parseInt(series.value["labels"][index])).locale("fr").format('MMMM YYYY').toUpperCase()
                        }
                    }
                }
            },
            backgroundColor: colorstab,
            barPercentage: 1,

        }
    }

    const getLineData = computed(function() {
        return { series: series.value, options: chartOptions.value }
    })

    return { getLineData, constOrigineColor };
}