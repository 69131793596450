<template>
  <v-row class="fill-height statistique" justify="center" align="center">
    <v-col cols="12">
      <v-row justify="center" align="center" class="pt-1 px-1">
        <v-col cols="12" md="9" class="pa-1">
          <v-card
            color="#EEEEEE"
            class="pa-2"
            :height="!getVuetifyBreakPoint?windowHeight * (1.82 / 5):windowHeight * 0.70"
          >
            <v-row class="pa-1">
              <v-col cols="12" md="9" class="pa-1">
                <v-table>
                  <tbody
                    style="border: solid lightgrey 2px; font-weight: 600"
                    :style="{ height: (windowHeight * (2 / 5)) / 3 + 'px' }"
                  >
                    <tr v-for="item in getTabItemsSorted.calcule" :key="item">
                      <td style="text-transform: uppercase">
                        {{ item.label }}
                      </td>
                      <td>
                        Total :
                        {{ Number(item.total.toFixed(2)).toLocaleString() }}
                      </td>
                      <td>
                        Moyenne :
                        {{
                          Number(
                            (item.total / getTabItemsSorted.qty).toFixed(2)
                          ).toLocaleString()
                        }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <v-table
                  class="mt-1"
                  style="overflow-y: auto; height: 100px"
                  :style="{ height: (windowHeight * (2 / 5)) / 1.856 + 'px' }"
                >
                  <tbody
                    style="font-weight: 600; border: solid lightgrey 2px"
                    :style="{
                      height: (windowHeight * (2 / 5)) / 1.856 - 2 + 'px',
                    }"
                  >
                    <tr align="center" style="text-transform: uppercase">
                      <td>Type</td>
                      <td>Chiffre d'affaire (€)</td>
                      <td>Chiffre d'affaire (%)</td>
                      <td>Marge (€)</td>
                      <td>Marge (%)</td>
                    </tr>
                    <tr v-for="item in getTabItemsSorted.origine" :key="item">
                      <td
                        :style="{
                          'background-color': constOrigineColor[item.label],
                        }"
                      >
                        <div
                          style="
                            color: white;
                            background-color: #616161;
                            border-radius: 50px;
                            border: solid 1px #616161;
                          "
                          align="center"
                        >
                          {{ item.label }}
                        </div>
                      </td>
                      <td
                        :style="{
                          'background-color': constOrigineColor[item.label],
                        }"
                      >
                        <div
                          style="
                            border: solid grey 1px;
                            background-color: white;
                            border-radius: 50px;
                          "
                          align="center"
                        >
                          {{ Number(item.CA.toFixed(2)).toLocaleString() }}
                        </div>
                      </td>

                      <td
                        :style="{
                          'background-color': constOrigineColor[item.label],
                        }"
                      >
                        <div
                          style="
                            border: solid grey 1px;
                            background-color: white;
                            border-radius: 50px;
                          "
                          align="center"
                        >
                          {{
                            (
                              (item.CA * 100) /
                              getTabItemsSorted.calcule.Mot_Ca.total
                            ).toFixed(2)
                          }}
                        </div>
                      </td>
                      <td
                        :style="{
                          'background-color': constOrigineColor[item.label],
                        }"
                      >
                        <div
                          style="
                            border: solid grey 1px;
                            background-color: white;
                            border-radius: 50px;
                          "
                          align="center"
                        >
                          {{ Number(item.MARGE.toFixed(2)).toLocaleString() }}
                        </div>
                      </td>
                      <td
                        :style="{
                          'background-color': constOrigineColor[item.label],
                        }"
                      >
                        <div
                          style="
                            border: solid grey 1px;
                            background-color: white;
                            border-radius: 50px;
                          "
                          align="center"
                        >
                          {{
                            (
                              (item.MARGE * 100) /
                              getTabItemsSorted.calcule.Mot_Marge.total
                            ).toFixed(2)
                          }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>
              <v-col cols="12" md="3" class="pa-1" :class="!getVuetifyBreakPoint?'pl-0':''">
                <div
                  style="
                    background-color: white;
                    border: solid lightgrey 2px;
                    border-bottom: none;
                    text-transform: uppercase;
                    font-weight: 600;
                  "
                >
                  <v-col align="center">
                    Classement clients
                    <h6>CHIFFRE D'AFFAIRE (€)</h6>
                  </v-col>
                </div>
                <v-table
                  class=""
                  style="overflow-y: auto"
                  :style="!getVuetifyBreakPoint?{ height: windowHeight * 0.289 + 'px' }:{ height: windowHeight * 0.262 + 'px' }"
                >
                  <tbody
                    style="font-weight: 600; border: solid lightgrey 2px"
                    :style="{ height: windowHeight * (1.51 / 5) + 'px' }"
                  >
                    <tr
                      v-for="(item, idx) in getTabItemsSorted.clientTab"
                      :key="item"
                    >
                      <td>
                        {{ idx + 1 }} : {{ item.Mot_Client }} :
                        {{
                          Number(
                            parseFloat(item.Mot_Ca.total).toFixed(2)
                          ).toLocaleString()
                        }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="pa-1">
          <v-card
            variant="flat"
            :height="windowHeight * (1.83 / 5)"
            class="card-shape"
            style="overflow-y: auto"
            color="#EEEEEE"
          >
            <v-row justify="center" align="center">
              <v-col class="">
                <DoughnutChart
                  :style="{ height: windowHeight * (1.7 / 5) + 'px' }"
                  :chartData="getPieData.chartData"
                  :options="getPieData.options"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" class="px-1 py-1">
          <v-card
            class="d-flex pa-2"
            width="100%"
            color="#EEEEEE"
            :height="windowHeight * (2.59 / 5)"
          >
            <BarChart
              :style="{ width: windowWidth + 'px' }"
              :chartData="getLineData.series"
              :options="getLineData.options"
            />
            <LineChart
              v-if="false"
              :style="{ width: windowWidth + 'px' }"
              :chartData="getLineData.series"
              :options="getLineData.options"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row class="" v-if="false">
        <v-col class="px-1 mt-1">
          <v-card class="pa-1 card-shape" variant="outlined">
            <v-row>
              <v-col>
                <v-text-field
                  hide-details
                  label="Rechercher"
                  class="my-2"
                  variant="outlined"
                  v-model="tmpSearch"
                >
                  <v-btn
                    style="position: absolute; right: 0px"
                    color="red"
                    @click="(tmpSearch = undefined), (search = undefined)"
                    variant="plain"
                    fab
                    v-if="tmpSearch && tmpSearch.length !== 0"
                  >
                    X
                  </v-btn>
                </v-text-field>
              </v-col>
              <v-col cols="auto" class="my-4 mr-3">
                <v-btn variant="outlined" @click="search = tmpSearch">
                  Rechercher</v-btn
                >
              </v-col>
            </v-row>
            <div class="tableFixHead">
              <table style="height: 100%">
                <thead>
                  <tr>
                    <th v-for="elem in tabHeaders" :key="elem">
                      <!-- @click="sortBy(elem)" -->
                      {{ elem.key }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="elem in getTabItemsSorted.tab" :key="elem">
                    <td v-for="key in elem" :key="key">
                      {{ key }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0 px-2">
      <v-btn
        width="100%"
        @click="close()"
        color="#EF5350"
        style="color: white; font-weight: bold"
        >Effectuer une nouvelle recherche</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { watch } from "vue";
import chartBar from "../hooks/chartBar";
import chartPie from "../hooks/chartPie";
import { BarChart, DoughnutChart, LineChart } from "vue-chart-3";
import moment from "moment";

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  props: {
    tabHeaders: {},
    tabItems: {},
    filteredData: {},
  },
  components: { BarChart, DoughnutChart, LineChart },

  setup(props) {
    const tabItems = ref(props.tabItems);
    const sortingWay = ref(true);
    const sortingKey = ref("");
    const search = ref(undefined);
    const tmpSearch = ref(undefined);
    const height = ref(400);

    watch(tmpSearch, (val) => {
      if (val == undefined || val.length == 0) {
        search.value = undefined;
      }
    });

    const sortBy = function (sortingKey) {
      sortingKey.value = sortingKey.key;
      sortingWay.value = !sortingWay.value;
    };

    const getTabItemsSorted = computed(function () {
      if (tabItems.value == undefined) return [];
      let barLabels = [];
      let tab = [...tabItems.value];
      let newTab = [];
      if (search.value) {
        tab = tab.filter((val) =>
          JSON.stringify(Object.values(val))
            .toLowerCase()
            .includes(search.value.toLowerCase())
        );
      }
      newTab = tab.sort((a, b) => {
        return a[sortingKey.value] - b[sortingKey.value];
      });
      if (!sortingWay.value) newTab = newTab.reverse();

      let origineObj = {};
      let clientTab = {};
      let calcule = {
        Mot_Ca: { label: "Chiffre d'affaire (€)", total: 0 },
        Mot_Marge: { label: "Marge (€)", total: 0 },
      };
      for (let i = 0; i < newTab.length; i++) {
        // Client
        if (clientTab[newTab[i].Mot_Client] == undefined)
          clientTab[newTab[i].Mot_Client] = {
            Mot_Client: newTab[i].Mot_Client,
            Mot_Ca: { label: "Chiffre d'affaire total", total: 0 },
            Mot_Marge: { label: "Marge total", total: 0 },
          };
        //
        // ORIGINE
        if (origineObj[newTab[i].Mot_Origine] == undefined) {
          origineObj[newTab[i].Mot_Origine] = {
            label: newTab[i].Mot_Origine,
            nbr: 0,
            data: {},
            CA: 0,
            MARGE: 0,
          };
        }
        let dateFormated = moment(newTab[i].Mot_Date).format("YYYY-MM");
        dateFormated = moment(dateFormated).format("x");
        barLabels.push(dateFormated);
        origineObj[newTab[i].Mot_Origine].nbr += 1;

        if (origineObj[newTab[i].Mot_Origine].data[dateFormated])
          origineObj[newTab[i].Mot_Origine].data[dateFormated] += parseFloat(
            newTab[i].Mot_Ca
          );
        else
          origineObj[newTab[i].Mot_Origine].data[dateFormated] = parseFloat(
            newTab[i].Mot_Ca
          );

        if (typeof newTab[i].Mot_Marge === "number") {
          clientTab[newTab[i].Mot_Client].Mot_Marge.total += parseFloat(
            newTab[i].Mot_Marge
          );
          calcule["Mot_Marge"].total += parseFloat(newTab[i].Mot_Marge);
          origineObj[newTab[i].Mot_Origine].MARGE += parseFloat(
            newTab[i].Mot_Marge
          );
        }
        if (typeof newTab[i].Mot_Ca === "number") {
          clientTab[newTab[i].Mot_Client].Mot_Ca.total += parseFloat(
            newTab[i].Mot_Ca
          );
          calcule["Mot_Ca"].total += parseFloat(newTab[i].Mot_Ca);
          origineObj[newTab[i].Mot_Origine].CA += parseFloat(newTab[i].Mot_Ca);
        }
      }
      clientTab = Object.values(clientTab).sort(
        (a, b) => b.Mot_Ca.total - a.Mot_Ca.total
      );
      barLabels = [...new Set(barLabels)].sort((a, b) => a - b);
      let barLabelsObj = {};
      for (let i = 0; i < barLabels.length; i++) {
        barLabelsObj[barLabels[i]] = { x: parseInt(barLabels[i]), y: null };
      }

      return {
        tab: newTab,
        origine: origineObj,
        calcule,
        qty: newTab.length,
        barLabels,
        barLabelsObj,
        clientTab,
      };
    });
    const { getLineData, constOrigineColor } = chartBar(getTabItemsSorted);
    const { getPieData } = chartPie(getTabItemsSorted, constOrigineColor);

    return {
      getTabItemsSorted,
      sortBy,
      tmpSearch,
      search,
      getLineData,
      height,
      getPieData,
      constOrigineColor,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    getVuetifyBreakPoint() {
      return this.$vuetify.display.sm || this.$vuetify.display.xs;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>