<template>
  <v-app>
    <v-app-bar
      :title="getAppBarTitile"
      height="50"
      :absolute="true"
      :fixed="true"
      :app="true"
      outlined
    >
      <v-btn v-if="showHeader" @click="goTo('/')">
        <v-icon color="#EF5350" size="30"> mdi-arrow-left-circle </v-icon>
      </v-btn>
      <div
        v-if="showHeader"
        style="width: 100%; font-size: 20px; font-weight: 100"
        align="center"
      >
        <div
          style="border: solid 1px lightgrey; border-radius: 5px"
          class="py-1"
        >
          {{ getApp }}
        </div>
      </div>
      <v-btn v-if="isHomePage" @click="logOut()">
        <v-icon color="#EF5350" size="30"> mdi-logout </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main :style="{ 'background-color': getBackgroundColor }">
      <v-col class="px-1 pb-0 pt-2">
        <v-card
          :variant="'text'"
          :style="showHeader ? 'height: 94vh; overflow-y: auto' : ''"
        >
          <v-col class="pa-0">
            <router-view v-if="!isLoading" />
            <div v-else>
              <v-col align="center" style="margin-top: 35vh">
                <v-progress-circular
                  size="110"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
            </div>
          </v-col>
        </v-card>
      </v-col>
    </v-main>
  </v-app>
</template>

<script>
import engine from "@/core/index";

export default {
  data() {
    return {
      selectedPage: undefined,
      isLoading: true,
      webApplicationToShow: [],
      items: [
        {
          text: "Planning Disponibilité",
          href: "planning-disponibilite",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning Livraison",
          href: "planning-livraison",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Planning Technicien",
          href: "planning-technicien",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
        },
        {
          text: "Statistiques",
          href: "statistiques",
          color: "#FFF176",
          icon: "mdi-chart-bar",
          show: false,
        },
        {
          text: "Email visualisation",
          href: "email-visualisation",
          color: "#5C6BC0",
          icon: "mdi-email",
          show: false,
        },
        {
          text: "Etat des lieux materiel",
          href: "etat-des-lieux-materiel",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
        },
        {
          text: "Etat des lieux vélo",
          href: "etat-des-lieux-velo",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
        },
      ],
    };
  },
  mounted() {
    let that = this;
    let token = localStorage.getItem("userToken");
    engine.get_user_by_token(token, function (data) {
      if (data.code == 0) {
        that.webApplicationToShow =
          data.data.data.data[0].fieldData.web_application_to_show.split(",");
        for (let i = 0; i < that.webApplicationToShow.length; i++) {
          that.items.find(
            (val) => val.href == that.webApplicationToShow[i]
          ).show = true;
        }
        that.$store.state.webApplicationToShow = that.webApplicationToShow;
        let user = data.data.data.data[0].fieldData;
        delete user.user_id;
        delete user.user_password;
        that.$store.state.user = user;
      } else {
        that.$router.push("/login");
      }
      that.isLoading = false;
    });
    this.selectedPage = window.location.pathname.replace("/", "");
  },
  methods: {
    logOut() {
      localStorage.clear();
      this.goTo("/login");
    },
    goTo(href) {
      this.$router.push(href);
    },
  },
  computed: {
    getBackgroundColor() {
      let url = this.$route.fullPath.replace("/", "");
      let color = "#90A4AE";
      let colorTab = {
        "": "#E0E0E0",
        login: "#E0E0E0",
        "planning-disponibilite": "#90A4AE",
        "planning-livraison": "#90A4AE",
        "planning-technicien": "#90A4AE",
        "email-visualisation": "#90A4AE",
        statistiques: "#90A4AE",
      };
      if (colorTab[url] != undefined) color = colorTab[url];
      return color;
    },
    getAppBarTitile() {
      return "ACCEN - Web apllications";
    },
    getApp() {
      return this.$route.fullPath
        .replace("/", "")
        .replace("-", " ").split("?")[0]
        .toUpperCase();
    },
    showHeader() {
      let show = ["/", "/login"];
      return !show.includes(this.$route.fullPath);
    },
    isHomePage() {
      let show = ["/"];
      return show.includes(this.$route.fullPath);
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
