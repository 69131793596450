<template>
  <v-col v-if="isLoading === false && localData" class="etat-des-lieux-velo">
    <v-card
      flat
      :style="{ 'padding-top': isIos ? '16px' : '0px' }"
      :color="!isEditionMode ? 'transparent' : '#607D8B'"
    >
      <div v-if="!isPrinting">
        <v-dialog v-model="isSignatureOpen">
          <v-card color="#EEEEEE">
            <v-col>
              <signature-pad
                v-if="selectedSignature"
                id="signature"
                :ref="selectedSignature"
                :height="'350'"
                :width="'500'"
                :options="{
                  onBegin: () => {
                    $refs[selectedSignature].resizeCanvas();
                  },
                }"
              />
            </v-col>
            <div style="position: absolute; bottom: 0px; right: 0px">
              <v-card color="#424242" class="ma-2">
                <v-col align="right" cols="12" class="pa-1">
                  <v-btn
                    color="green"
                    text
                    fab
                    class="mx-2"
                    id="hideInPrint"
                    @click="save(selectedSignature)"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn
                    color="blue"
                    text
                    fab
                    class="mx-2"
                    id="hideInPrint"
                    @click="undo(selectedSignature)"
                  >
                    <v-icon>mdi-undo </v-icon>
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    fab
                    class="mx-2"
                    id="hideInPrint"
                    @click="clear(selectedSignature)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    fab
                    class="mx-2"
                    id="hideInPrint"
                    @click="close()"
                  >
                    <v-icon>mdi-close-box</v-icon>
                  </v-btn>
                </v-col>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="isPhotoEditorOpen" width="600px">
          <v-card
            height="360px"
            style="position: relative"
            color="#EEEEEE"
            width="600"
          >
            <v-col align="center" cols="12" class="pa-0">
              <div class="etat-des-lieux-velo wrapper">
                <v-img
                  src="../public/velo_simple.jpg"
                  contain
                  class="wrapper-img"
                  width="600px"
                  height="360px"
                  style="position: relative"
                />
                <signature-pad
                  v-if="selectedPhoto"
                  :ref="selectedPhoto"
                  class="pad"
                  :height="'300'"
                  :width="'100%'"
                  :options="{
                    penColor: 'white',
                    minWidth: 1,
                    maxWidth: 1,
                    onBegin: () => {
                      $refs[selectedPhoto].resizeCanvas();
                    },
                  }"
                />
              </div>
            </v-col>
            <div style="position: absolute; bottom: 0px; right: 0px">
              <v-card color="#424242" class="ma-2">
                <v-col align="right" cols="12" class="pa-1">
                  <v-btn
                    color="green"
                    text
                    fab
                    class="mx-2"
                    id="hideInPrint"
                    @click="save(selectedPhoto)"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn
                    color="blue"
                    text
                    fab
                    class="mx-2"
                    id="hideInPrint"
                    @click="undo(selectedPhoto)"
                  >
                    <v-icon>mdi-undo </v-icon>
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    fab
                    class="mx-2"
                    id="hideInPrint"
                    @click="clear(selectedPhoto)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    fab
                    class="mx-2"
                    id="hideInPrint"
                    @click="close()"
                  >
                    <v-icon>mdi-close-box</v-icon>
                  </v-btn>
                </v-col>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="sendEmail" width="500">
          <v-card>
            <v-card-title> {{ $t("etat-des-lieux-velo.form.send_by_email") }} </v-card-title>
            <v-col align="right">
              <v-text-field
                v-model="getClient.CL_CLIENT_EMAIL"
                readonly
                :label="$t('etat-des-lieux-velo.form.mail')"
                :no-data-text="$t('etat-des-lieux-velo.form.no_data_available')"
                outlined
              >
              </v-text-field>
              <v-btn color="primary" @click="send_pdf">
                {{ $t("etat-des-lieux-velo.form.send_by_email") }}
              </v-btn>
            </v-col>
          </v-card></v-dialog
        >

        <div id="capture">
          <v-col cols="">
            <div id="info" style="display: none">
              <v-col v-if="getAgency">
                <img
                  :src="
                    logoSrc + JSON.parse(getAgency['Logo_JSON']).Logo_Base64
                  "
                  alt=""
                  height="90px"
                />
                <br />
                {{ getAgency["ag_adresse"] }} <br />
                {{ getAgency["ag_cp"] }}
                {{ getAgency["ag_ville"] }} <br />
                {{ getAgency["ag_telephone"] }} <br />
                {{ getAgency["ag_courriel"] }} <br />
                {{ getAgency["ag_siteweb"] }} <br />
              </v-col>
              <div v-else style="height: 110px"></div>
            </div>
          </v-col>
          <v-col
            cols="auto"
            id="qcode"
            class="pa-0"
            style="position: absolute; right: 10px; top: 20px; display: none"
          >
            <qrcode-vue
              style="margin-top: 20px; margin-right: 10px"
              :value="getqCodeUrl"
              :size="qCodeSize"
              level="H"
            ></qrcode-vue>
          </v-col>

          <v-col class="py-1">
            <h1 class="py-2" align="center">{{ $t("etat-des-lieux-velo.menu.title") }}</h1>
            <v-btn
              id="hideInPrint"
              v-if="
                !isEditionMode && user_information.admin == 1 ? true : false
              "
              fab
              small
              style="position: absolute; right: 75px"
              :style="{ top: isIos ? '20px' : '-5px' }"
              @click="startEdition()"
            >
              <v-icon> mdi-clipboard-edit-outline </v-icon>
            </v-btn>
            <v-btn
              id="hideInPrint"
              fab
              small
              style="position: absolute; right: 0px"
              :style="{ top: isIos ? '20px' : '-5px' }"
              @click="closeDialog"
            >
              <v-icon color="red"> mdi-close </v-icon>
            </v-btn>
            <v-divider class="my-2"></v-divider>
            <h3 class="mb-2">Informations générale</h3>
            <v-col class="px-0">
              <v-row>
                <v-col
                  v-for="(value, key) in getContratInformationsToShow"
                  :key="key"
                  md="3"
                  sm="12"
                  cols="12"
                  class="py-2"
                >
                  <div style="background-color: #fafafa" class="pa-1 rounded">
                    {{ $t("etat-des-lieux-velo.form." + key) }}
                    <v-card
                      style="background-color: white; border: solid 1px #bdbdbd"
                      outlined
                      class="pa-2"
                      flat
                    >
                      {{ value }}
                    </v-card>
                  </div>
                </v-col>
                <v-col
                  v-for="(value, key) in getClientInformationsToShow"
                  :key="key"
                  md="3"
                  sm="12"
                  cols="12"
                  class="py-2"
                >
                  <!-- <v-card outlined height="100%" class="pa-1">
                    <h6>{{ $t("etat-des-lieux-velo.form." + key) }}</h6>
                    {{ value }}
                  </v-card> -->
                  <div style="background-color: #fafafa" class="pa-1 rounded">
                    {{ $t("etat-des-lieux-velo.form." + key) }}
                    <v-card
                      style="background-color: white; border: solid 1px #bdbdbd"
                      outlined
                      class="pa-2"
                      flat
                    >
                      {{ value }}
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="px-0 mt-3">
              <h3 class="mb-3">Informations Vélo</h3>
              <v-row>
                <v-col
                  v-for="(value, key) in getVeloInformation"
                  :key="key"
                  md="3"
                  sm="12"
                  cols="12"
                  class="py-2"
                >
                  <div style="background-color: #fafafa" class="pa-1 rounded">
                    {{ $t("etat-des-lieux-velo.form." + key) }}
                    <v-card
                      style="background-color: white; border: solid 1px #bdbdbd"
                      outlined
                      class="pa-2"
                      flat
                    >
                      {{ value }}
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="px-0 mt-5">
              <v-row>
                <v-col v-for="elem in getDates" :key="elem.key">
                  <div
                    :class="elem.class"
                    style="border: solid 1px lightgrey; border-radius: 5px"
                    align="center"
                    @click="showError(data.disabled[elem.class], elem.key)"
                  >
                    {{ $t("etat-des-lieux-velo.form." + elem.key) }}
                    <v-divider
                      style="width: 100%"
                      class="mb-2 mt-0"
                    ></v-divider>
                    <div class="pa-2 pt-1">
                      <v-btn
                        variant="text"
                        class="outlined"
                        @click="datemodal[elem.key] = true"
                        style="width: 100%; background-color: white"
                        :disabled="data.disabled[elem.class]"
                      >
                        {{
                          formatDate(localData[elem.key], "DD/MM/YYYY - HH:mm")
                        }}
                      </v-btn>
                    </div>
                    <v-dialog v-model="datemodal[elem.key]">
                      <Datepicker
                        keepActionRow
                        v-model="localData[elem.key]"
                        locale="fr"
                        :clearable="false"
                        :cancelText="'Fermer'"
                        :selectText="'Valider'"
                        inline
                        @update:modelValue="datemodal[elem.key] = false"
                      ></Datepicker>
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              align="center"
              style="
                border: solid 1px;
                border-color: rgb(138, 138, 138) !important;
                border-radius: 5px;
                background-color: #fafafa;
              "
            >
              <div id="hideInPrint" class="py-2">
                <v-row align="center">
                  <v-col>
                    <v-text-field
                      id="hideInPrint"
                      variant="outlined"
                      dense
                      hide-details
                      v-model="tmpSearch"
                      label="Rechercher"
                      v-on:keyup.enter="search = tmpSearch"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto" class="">
                    <v-btn
                      color="primary"
                      x-small
                      fab
                      @click="search = tmpSearch"
                    >
                      <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-table
                fixed-header
                id="table"
                style="height: 600px; overflow: auto"
              >
                <thead>
                  <tr>
                    <th v-for="elem in tab_headers" :key="elem.value">
                      {{ elem.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, idx) in localData.CLD_CHECK_LIST_JSON_GESTION"
                    :key="idx"
                  >
                    <td
                      v-if="
                        item.CH_DESIGNATION.toLowerCase().includes(
                          search.toLowerCase()
                        )
                      "
                      class="sortie"
                      style="width: 33%; border-color: lightgrey !important"
                    >
                      <div align="center">
                        <div style="border: none" id="hideInPrint">
                          <v-switch
                            v-if="!data.disabled.sortie"
                            color="green"
                            inset
                            v-model="item.ETAT_DEPART"
                            :disabled="data.disabled.sortie"
                            :style="{
                              color: item.ETAT_DEPART ? 'green' : 'red',
                            }"
                            hide-details
                          ></v-switch>
                          <div v-else>
                            <div v-if="item.ETAT_DEPART == true">
                              <v-icon color="green">mdi-check-bold</v-icon>
                            </div>
                            <div v-else>
                              <v-icon color="red">mdi-alert</v-icon>
                            </div>
                          </div>
                        </div>
                        <div id="showInPrint" style="display: none">
                          <div v-if="item.ETAT_DEPART == true">
                            <v-icon color="green">mdi-check-bold</v-icon>
                          </div>
                          <div v-else>
                            <v-icon color="red">mdi-alert</v-icon>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      v-if="
                        item.CH_DESIGNATION.toLowerCase().includes(
                          search.toLowerCase()
                        )
                      "
                      style="width: 33%"
                      align="center"
                    >
                      {{ item.CH_DESIGNATION }}
                    </td>
                    <td
                      v-if="
                        item.CH_DESIGNATION.toLowerCase().includes(
                          search.toLowerCase()
                        )
                      "
                      class="entrer pa-0"
                      style="width: 33%; border-color: lightgrey !important"
                    >
                      <div align="center">
                        <div
                          style="border: none; width: 100%; position: relative"
                          id="hideInPrint"
                        >
                          <v-switch
                            v-if="!data.disabled.entrer"
                            inset
                            style="margin-left: 42%"
                            v-model="item.ETAT_RETOUR"
                            :disabled="data.disabled.entrer"
                            :style="{
                              color: item.ETAT_RETOUR ? 'green' : 'red',
                            }"
                            hide-details
                          ></v-switch>
                          <div v-else>
                            <div v-if="item.ETAT_RETOUR == true">
                              <v-icon color="green">mdi-check-bold</v-icon>
                            </div>
                            <div v-else>
                              <v-icon color="red">mdi-alert</v-icon>
                            </div>
                          </div>
                        </div>
                        <div id="showInPrint" style="display: none">
                          <div v-if="item.ETAT_RETOUR == true">
                            <v-icon color="green">mdi-check-bold</v-icon>
                          </div>
                          <div v-else>
                            <v-icon color="red">mdi-alert</v-icon>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-col>
          <v-col id="hideInPrint" v-if="getSortedSetting['PHOTO']">
            <v-row class="px-2" align="center">
              <v-col class="pa-1" cols="6">
                <v-card variant="outlined" flat class="sortie">
                  <v-col class="pa-0" align="center">
                    <v-img
                      contain
                      @click="
                        showError(
                          data.disabled.sortie,
                          'CLD_PHOTO_EDITION_DEPART'
                        ),
                          writeUpToPhoto(
                            'filtre_photo_dep',
                            data.disabled.sortie
                          )
                      "
                      src="../public/velo_simple.jpg"
                      width="600px"
                      max-height="300px"
                    >
                      <v-img
                        @click="
                          showError(
                            data.disabled.sortie,
                            'CLD_PHOTO_EDITION_DEPART'
                          ),
                            writeUpToPhoto(
                              'filtre_photo_dep',
                              data.disabled.sortie
                            )
                        "
                        width="600px"
                        :src="localData.CLD_PHOTO_EDITION_DEPART"
                      ></v-img>
                    </v-img>
                  </v-col>
                </v-card>
              </v-col>
              <v-col class="pa-1" cols="6">
                <v-card variant="outlined" flat class="entrer">
                  <v-col class="pa-0" align="center">
                    <v-img
                      contain
                      @click="
                        showError(
                          data.disabled.entrer,
                          'CLD_PHOTO_EDITION_RETOUR'
                        ),
                          writeUpToPhoto(
                            'filtre_photo_ret',
                            data.disabled.entrer
                          )
                      "
                      width="600px"
                      max-height="300px"
                      src="../public/velo_simple.jpg"
                    >
                      <v-img
                        @click="
                          showError(
                            data.disabled.entrer,
                            'CLD_PHOTO_EDITION_RETOUR'
                          ),
                            writeUpToPhoto(
                              'filtre_photo_ret',
                              data.disabled.entrer
                            )
                        "
                        width="600px"
                        :src="localData.CLD_PHOTO_EDITION_RETOUR"
                      ></v-img>
                    </v-img>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-row class="mx-0 px-2 py-1">
            <v-col
              v-for="value in getKilometreInformation"
              :key="value.key"
              cols="6"
              class="px-1"
            >
              <div
                style="border-radius: 5px; border: solid 1px"
                :class="value.type"
                class=""
              >
                <v-text-field
                  :id="value.key"
                  @click="showError(data.disabled[value.type], value.key)"
                  type="number"
                  class="mx-2 mt-2"
                  variant="plain"
                  :readonly="data.disabled[value.type]"
                  style="border-radius: 5px"
                  :label="$t('etat-des-lieux-velo.form.' + value.key)"
                  hide-details
                  v-model="localData[value.key]"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-col>
            <v-row class="mx-0">
              <v-col class="px-0 py-1" cols="12">
                <div
                  style="border-radius: 5px; background-color: #d7ccc8"
                  class="pa-3"
                >
                  <v-textarea
                    class="area"
                    height="100px"
                    variant="solo"
                    :label="$t('etat-des-lieux-velo.form.general_observation')"
                    hide-details
                    no-resize
                    :readonly="data.disabled.sortie && data.disabled.entrer"
                    v-model="localData.CLD_OBSERVATION_GENERAL"
                  ></v-textarea>
                </div>
              </v-col>
              <v-col class="px-1 pl-0 py-1" cols="6">
                <div
                  class="sortie"
                  style="border-radius: 5px; border: solid 1px"
                  @click="
                    showError(data.disabled.sortie, 'CLD_COMMENTAIRE_DEPART')
                  "
                >
                  <v-textarea
                    id="CLD_COMMENTAIRE_DEPART"
                    @click="
                      showError(data.disabled.sortie, 'CLD_COMMENTAIRE_DEPART')
                    "
                    class="ma-3 area"
                    variant="solo"
                    :readonly="data.disabled.sortie"
                    style="border-radius: 5px"
                    :label="$t('etat-des-lieux-velo.form.exit_observation')"
                    hide-details
                    no-resize
                    v-model="localData.CLD_COMMENTAIRE_DEPART"
                  ></v-textarea>
                </div>
              </v-col>
              <v-col class="px-1 pr-0 py-1" cols="6">
                <div
                  class="entrer"
                  style="border-radius: 5px; border: solid 1px"
                  @click="
                    showError(data.disabled.entrer, 'CLD_COMMENTAIRE_RETOUR')
                  "
                >
                  <v-textarea
                    id="CLD_COMMENTAIRE_RETOUR"
                    @click="
                      showError(data.disabled.entrer, 'CLD_COMMENTAIRE_RETOUR')
                    "
                    class="ma-3 area"
                    variant="solo"
                    :readonly="data.disabled.entrer"
                    style="border-radius: 5px"
                    :label="$t('etat-des-lieux-velo.form.return_observation')"
                    hide-details
                    v-model="localData.CLD_COMMENTAIRE_RETOUR"
                    no-resize
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="">
            <v-row class="px-2">
              <v-col
                class="pa-1"
                cols="6"
                align="center"
                @click="writeSignature('signature_dep', data.disabled.sortie)"
              >
                <v-card flat variant="outlined" class="sortie">
                  <v-col align="left"> {{ $t("etat-des-lieux-velo.form.exit_signature") }} </v-col>
                  <v-img
                    id="CLD_SIGNATURE_DEPART"
                    @click="
                      showError(data.disabled.sortie, 'CLD_SIGNATURE_DEPART')
                    "
                    contain
                    :src="localData.CLD_SIGNATURE_DEPART"
                    width="100%"
                    height="100px"
                  ></v-img>
                </v-card>
              </v-col>
              <v-col
                cols="6"
                class="pa-1"
                align="center"
                @click="writeSignature('signature_ret', data.disabled.entrer)"
              >
                <v-card flat variant="outlined" class="entrer">
                  <v-col align="left">
                    {{ $t("etat-des-lieux-velo.form.return_signature") }}
                  </v-col>
                  <v-img
                    id="CLD_SIGNATURE_RETOUR"
                    @click="
                      showError(data.disabled.entrer, 'CLD_SIGNATURE_RETOUR')
                    "
                    contain
                    :src="localData.CLD_SIGNATURE_RETOUR"
                    height="100px"
                    width="100%"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <div align="center" id="hideInPrint" v-if="data.selectedItem != 0">
            <v-col
              ms="6"
              cols="12"
              align="center"
              class=""
              @click="showError(data.disabled.entrer, '')"
            >
              <v-card
                outlined
                align="center"
                class="pa-2"
                style="border: solid 3px"
                :style="{
                  'border-color': localData.CLD_ETATDL_VALIDE
                    ? '#66BB6A'
                    : 'red',
                }"
              >
                <div style="justify-content: center; display: flex">
                  <v-switch
                    class="mt-0 mb-2"
                    :readonly="data.disabled.entrer"
                    v-model="localData.CLD_ETATDL_VALIDE"
                    hide-details
                    :label="$t('etat-des-lieux-velo.form.validateForme')"
                    inset
                    color="green"
                    :class="!localData.CLD_ETATDL_VALIDE ? 'custom-red' : ''"
                  ></v-switch>
                </div>
                <v-text-field
                  v-model="localData.CLD_ETATDL_VALIDE_COMMENTAIRE"
                  :readonly="data.disabled.entrer"
                  label="Information complémentaire"
                  variant="outlined"
                  hide-details
                ></v-text-field>
              </v-card>
            </v-col>
          </div>
          <div align="center" id="info" style="display: none">
            <v-divider class=""></v-divider>
            <v-col style="padding-bottom: 20px">
              {{ societe["RS"] }} - {{ societe["TypeEntreprise"] }} au capital
              de {{ societe["Capital"] }} € - SIRET
              {{ societe["NumeroSIRET"] }} - APE : {{ societe["CodeAPE"] }} -
              TVA : {{ societe["TVAIntracommunautaire"] }} <br />
              <div v-if="getAgency">
                {{ getAgency["ag_nom"] }} - {{ getAgency["ag_adresse"] }}
                {{ getAgency["ag_cp"] }} {{ getAgency["ag_ville"] }} - Tél :
                {{ getAgency["ag_telephone"] }}
              </div>
            </v-col>
          </div>
          <v-col align="center" id="hideInPrint">
            <v-col>
              <v-row>
                <v-col cols="12" sm="6" class="py-0 px-1" v-if="!isEditionMode">
                  <v-btn
                    class="my-1 elevation-0"
                    color="#81C784"
                    :style="{
                      width: '100%',
                      'margin-right': isMobile ? 'auto' : '5px',
                      color: 'black',
                    }"
                    @click="downloadPdf()"
                  >
                    {{ $t("etat-des-lieux-velo.form.download") }}</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="" class="py-0 px-1" v-if="!isEditionMode">
                  <v-btn
                    width="100%"
                    class="my-1 elevation-0"
                    :style="{
                      width: '100%',
                      'margin-right': isMobile ? 'auto' : '5px',
                      color: 'white',
                    }"
                    color="#5C6BC0"
                    elevation="0"
                    @click="sharePdf()"
                  >
                    {{ $t("etat-des-lieux-velo.form.send_by_email") }}
                    <v-icon class="ml-2"> mdi-email </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="py-0 px-1"
                  v-if="
                    (data.selectedItem == 0 && !isEditionMode) ||
                    (data.selectedItem == 1 && !isEditionMode)
                  "
                >
                  <v-btn
                    v-if="data.selectedItem == 0 && !isEditionMode"
                    class="my-1 elevation-0"
                    :style="{
                      color: 'white',
                    }"
                    style="width: 100%"
                    color="#5C6BC0"
                    @click="setNewEtatdl(true)"
                  >
                    Sortir le vélo</v-btn
                  >
                  <v-btn
                    v-if="data.selectedItem == 1 && !isEditionMode"
                    class="my-1 elevation-0"
                    style="width: 100%"
                    :style="{
                      color: 'white',
                    }"
                    color="#5C6BC0"
                    @click="setNewEtatdl(true)"
                  >
                    Rentrer le vélo</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="py-0 px-1"
                  v-if="data.selectedItem == 2 && isEditionMode"
                >
                  <v-btn
                    class="my-1 elevation-0"
                    style="width: 100%"
                    :style="{
                      color: 'white',
                    }"
                    color="#5C6BC0"
                    @click="setNewEtatdl(true)"
                  >
                    Mettre à jour l'état des lieux</v-btn
                  >
                </v-col>
                <v-col cols="" class="py-0 px-1">
                  <v-btn
                    class="my-1 elevation-0"
                    color="#EF5350"
                    style="width: 100%"
                    :style="{
                      color: 'white',
                    }"
                    @click="closeDialog"
                  >
                    {{ $t("etat-des-lieux-velo.form.close") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </div>
      </div>
      <v-card v-else width="100%">
        <div align="center" style="height: 97vh">
          <v-progress-circular
            style="padding: 10px; top: 40vh"
            size="110"
            indeterminate
            color="primary"
            >Génération du pdf
          </v-progress-circular>
        </div>
      </v-card>
    </v-card>
    <v-snackbar v-model="snackbar" color="red" :timeout="5000">
      <div style="color: white">
        {{ errorMessage.msg }}
        <div
          v-if="errorMessage.class"
          :class="errorMessage.class"
          style="height: 20px; width: 20px; border-radius: 100%; float: right"
          class="ml-3"
        ></div>
      </div>
    </v-snackbar>
  </v-col>
  <div
    v-else
    justify="center"
    align="center"
    style="height: 99vh; padding-top: 25%"
  >
    <v-progress-circular
      :size="80"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import engine from "../core/index";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Browser } from "@capacitor/browser";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
// import "vue2-datepicker/locale/fr";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import QrcodeVue from "qrcode.vue";
import SignaturePad from "vue3-signature-pad";

export default {
  data() {
    return {
      data: {},
      datemodal: {},
      validateEtdl: true,
      qCodeSize: 150,
      emailList: [],
      email_pdf: undefined,
      sendEmail: false,
      snackbar: false,
      errorMessage: {},
      lang: {},
      isPhotoEditorOpen: false,
      isLoading: false,
      isPrinting: false,
      ficheVelo: undefined,
      pdfLink: "",
      selectedSignature: undefined,
      selectedPhoto: undefined,
      isSignatureOpen: false,
      observationIsLoading: false,
      selectedMateriel: undefined,
      selectedMaterielObservation: undefined,
      isPermanentMessageOpen: false,
      filtre_photo_dep: undefined,
      filtre_photo_ret: undefined,
      signature_dep: undefined,
      signature_ret: undefined,
      search: "",
      tmpSearch: "",
      data_table: {},
      etadl_data: [],
      colorType: false,
      personalizedData: undefined,
      savedData: [],
      localData: undefined,
      selectedPhotoType: undefined,
      societe: {},
      logoSrc: "data:image/gif;base64,",
    };
  },
  components: {
    Datepicker,
    QrcodeVue,
    SignaturePad,
  },
  watch: {
    tmpSearch(val) {
      if (val == null || val.length == 0) {
        this.search = "";
      }
    },
    isSignatureOpen(val) {
      if (val === false) this.selectedSignature = undefined;
    },
    isPhotoEditorOpen(val) {
      if (val === false) this.selectedPhoto = undefined;
    },
  },
  props: {
    formData: {},
    user_information: {},
    clients_list: {},
    agency_list: {},
  },
  mounted() {
    let that = this;
    this.data = JSON.parse(JSON.stringify(this.formData));
    this.localData = this.data.data;
    // this.$set(this.$refs, "signature_dep", this.localData.CLD_SIGNATURE_DEPART);
    // this.$refs['signature_dep'] = this.localData.CLD_SIGNATURE_DEPART
    // this.$set(this.$refs, "signature_ret", this.localData.CLD_SIGNATURE_RETOUR);
    // this.$refs['signature_ret'] = this.localData.CLD_SIGNATURE_RETOUR
    // this.$set(
    //   this.$refs,
    //   "filtre_photo_dep",
    //   this.localData.CLD_PHOTO_EDITION_DEPART
    // );
    // this.$refs['filtre_photo_dep'] = this.localData.CLD_PHOTO_EDITION_DEPART
    // this.$set(
    //   this.$refs,
    //   "filtre_photo_ret",
    //   this.localData.CLD_PHOTO_EDITION_RETOUR
    // );
    // this.$refs['filtre_photo_ret'] = this.localData.CLD_PHOTO_EDITION_RETOUR
    engine.get_societe_by_id(this.localData.CLD_ID_SOCIETE, function (data) {
      if (data.code == 0) {
        that.societe = data.data.data[0].fieldData;
      }
    });
    this.getEmailList(this.getClient.CL_ID_CLIENT);
    this.get_users_settings();
    this.localData.CLD_ETATDL_VALIDE == "valide"
      ? (this.localData.CLD_ETATDL_VALIDE = true)
      : (this.localData.CLD_ETATDL_VALIDE = false);
    if (
      this.localData.CLD_CHECK_LIST_JSON_GESTION == "" ||
      this.localData.CLD_CHECK_LIST_JSON_GESTION == undefined
    ) {
      this.localData.CLD_CHECK_LIST_JSON_GESTION = JSON.parse(
        this.localData.CLD_CHECK_LIST_JSON
      ).fieldData.map((val) => ({
        CH_DESIGNATION: val.CH_DESIGNATION,
        CH_REFERENCE: val.CH_REFERENCE,
        ETAT_DEPART: true,
        ETAT_RETOUR: true,
      }));
    } else {
      if (typeof this.localData.CLD_CHECK_LIST_JSON_GESTION === "string") {
        this.localData.CLD_CHECK_LIST_JSON_GESTION = JSON.parse(
          this.localData.CLD_CHECK_LIST_JSON_GESTION
        ).fieldData;
      }
    }
  },
  computed: {
    getDates() {
      let dates = [
        {
          key: "CLD_DATE_DEPART_VALIDATION",
          label: this.$t("etat-des-lieux-velo.form.exit_date"),
          open: false,
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_DATE_RETOUR_VALIDATION",
          label: this.$t("etat-des-lieux-velo.form.return_date"),
          open: false,
          class: "entrer",
          disabled: this.entrerDisabled,
        },
      ];
      return dates;
    },
    getqCodeUrl() {
      return this.localData.CLD_NUM_CONTRAT;
    },
    getClient() {
      if (this.localData && this.localData.CLD_ID_AGENCE && this.clients_list) {
        let client = this.clients_list.find(
          (val) => val.CL_LIEN == this.localData.CLD_LIEN
        );
        return client;
      } else {
        return undefined;
      }
    },
    getVeloInformation() {
      let keyToShow = ["CLD_MATRICULE", "CLD_SOUS_FAMILLE", "CLD_DESCRIPTION"];
      let info = {};
      for (let i = 0; i < keyToShow.length; i++) {
        info[keyToShow[i]] = this.localData[keyToShow[i]];
      }
      return info;
    },
    getKilometreInformation() {
      let keyToShow = [
        { key: "CLD_KILOMETRE_DEPART", type: "sortie" },
        { key: "CLD_KILOMETRE_RETOUR", type: "entrer" },
      ];
      return keyToShow;
    },
    getClientInformationsToShow() {
      let keyToShow = [
        "CL_CLIENT_RAISON_SOCIALE",
        // "CL_NUM_CONTRAT",
        // "CL_ID_AGENCE",
        "CL_NOM_CLIENT",
        // "CL_DATE_DEPART",
        // "CL_DATE_RETOUR",
        "CL_CLIENT_ADRESSE",
        "CL_CLIENT_EMAIL",
      ];
      let clientFiltered = {};
      if (this.getClient)
        for (let i = 0; i < keyToShow.length; i++) {
          if (keyToShow[i] == "CL_ID_AGENCE") {
            clientFiltered["CLD_AGENCY"] = this.agency_list.find(
              (val) => val.ID_Agence == this.getClient[keyToShow[i]]
            ).ag_nom;
          } else clientFiltered[keyToShow[i]] = this.getClient[keyToShow[i]];
        }

      return clientFiltered;
    },
    getAgency() {
      return this.agency_list.find(
        (val) => val.ID_Agence == this.getClient.CL_ID_AGENCE
      );
    },
    getContratInformationsToShow() {
      let keyToShow = [
        // "CL_CLIENT_RAISON_SOCIALE",
        "CL_NUM_CONTRAT",
        // "CL_CLIENT_ADRESSE",
        "CL_ID_AGENCE",
        // "CL_NOM_CLIENT",
        "CL_DATE_DEPART",
        "CL_DATE_RETOUR",
        // "CL_CLIENT_EMAIL",
      ];
      let clientFiltered = {};
      if (this.getClient)
        for (let i = 0; i < keyToShow.length; i++) {
          if (keyToShow[i] == "CL_ID_AGENCE") {
            if (
              this.agency_list.find(
                (val) => val.ID_Agence == this.getClient[keyToShow[i]]
              )
            ) {
              clientFiltered["CLD_AGENCY"] = this.agency_list.find(
                (val) => val.ID_Agence == this.getClient[keyToShow[i]]
              ).ag_nom;
            }
          } else clientFiltered[keyToShow[i]] = this.getClient[keyToShow[i]];
        }

      return clientFiltered;
    },
    isIos() {
      return (
        navigator.userAgent.includes("iPhone") ||
        navigator.userAgent.includes("iPhone Simulator") ||
        navigator.platform.includes("iPhone") ||
        navigator.platform.includes("iPhone Simulator") ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    getSortedSetting() {
      let sortedSettings = {};
      if (this.personalizedData) {
        for (let i = 0; i < this.personalizedData.length; i++) {
          sortedSettings[this.personalizedData[i].name] =
            this.personalizedData[i].show;
        }
      }
      return sortedSettings;
    },
    isEditionMode() {
      if (!this.data.disabled.sortie && !this.data.disabled.entrer) return true;
      return false;
    },
    isMobile() {
      return false;
      // return this.$vuetify.breakpoint.mobile;
    },
    tab_headers() {
      return [
        {
          text: this.$t("etat-des-lieux-velo.form.exit"),
          sortable: false,
          value: "ETAT_DEPART",
        },
        {
          text: this.$t("etat-des-lieux-velo.form.options"),
          value: "CH_DESIGNATION",
          sortable: false,
        },
        { text: this.$t("etat-des-lieux-velo.form.return"), sortable: false, value: "ETAT_RETOUR" },
      ];
    },
    tab_items() {
      let obj = {};
      let data = this.localData.CLD_CHECK_LIST_JSON_GESTION;
      for (let i = 0; i < data.length; i++) {
        obj[data[i].CH_DESIGNATION + "exit"] = data[i].ETAT_DEPART;
        obj[data[i].CH_DESIGNATION + "return"] = data[i].ETAT_RETOUR;
      }

      return { tab: this.localData.CLD_CHECK_LIST_JSON_GESTION, obj };
    },
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format);
    },
    getEmailList(clientId) {
      let that = this;
      engine.get_email_liste_by_id_client(clientId, function (data) {
        if (data.code == 0) {
          that.emailList = data.data.data[0].fieldData["CONTACT_MAIL_TOUS"]
            .split("|")
            .filter((val) => val !== "");
        }
      });
    },
    showError(show) {
      if (this.data.disabled.entrer && this.data.disabled.sortie) {
        this.errorMessage = {
          msg: "Etat des lieux non modifiable.",
          class: undefined,
        };
      } else if (!this.data.disabled.sortie) {
        this.errorMessage = {
          msg: "Uniquement les éléments de SORTIE de materiel sont modifiable",
          class: "sortie",
        };
      } else if (!this.data.disabled.entrer) {
        this.errorMessage = {
          msg: "Uniquement les éléments de RETOUR de materiel sont modifiable",
          class: "entrer",
        };
      }
      this.snackbar = show;
    },
    get_users_settings() {
      let that = this;
      this.dataForm = JSON.parse(JSON.stringify(this.user_information));
      engine.get_users_settings(true, function (data) {
        if (data.code == 0) {
          that.personalizedData = data.data.data.map((val) => val.fieldData);
          for (let i = 0; i < that.personalizedData.length; i++) {
            that.personalizedData[i].show = that.personalizedData[i].show == 1;
          }
        }
      });
    },
    startEdition() {
      // if (this.data.disabled.entrer && this.data.disabled.sortie) {
      this.data.disabled.entrer = false;
      this.data.disabled.sortie = false;
      // } else {
      //   this.data.disabled.entrer = true;
      //   this.data.disabled.sortie = true;
      // }
    },
    downloadPdf() {
      this.save_html_into_pdf(true);
    },
    sharePdf() {
      this.save_html_into_pdf(false);
    },
    send_pdf() {
      engine.send_pdf_by_email(
        this.getClient.CL_CLIENT_EMAIL,
        this.pdfLink,
        this.localData.CLD_NUM_CONTRAT,
        function () {}
      );
      this.sendEmail = false;
    },
    save_html_into_pdf(download) {
      window.scrollTo(0, 0);
      this.isPrinting = true;
      let that = this;

      let data = document.querySelector("#capture");
      data.style.width = "1600px";
      data.style.backgroundColor = "#fafafa";
      data.style.border = "solid 1px lightgrey";

      var info = document.querySelectorAll("#info");
      var elms = document.querySelectorAll("#hideInPrint");
      var elmsToShow = document.querySelectorAll("#showInPrint");
      var table = document.querySelector("#table");
      let qcode = document.querySelector("#qcode");
      qcode.style.display = "block";
      table.style.height =
        31.2 * this.localData.CLD_CHECK_LIST_JSON_GESTION.length + "px";
      for (let i = 0; i < info.length; i++) info[i].style.display = "block";
      for (let i = 0; i < elms.length; i++) elms[i].style.display = "none";
      for (let i = 0; i < elmsToShow.length; i++)
        elmsToShow[i].style.display = "block";

      // let html2canvasOptions = {
      //   scale: 2,
      //   windowWidth: 1600,
      // };
      let html2canvasOptions = {
        allowTaint: true,
        removeContainer: true,
        backgroundColor: null,
        imageTimeout: 15000,
        logging: true,
        scale: 2,
        useCORS: true,
        processData: false,
        contentType: false,
        windowWidth: 1600,
        windowHeight: 1600,
      };
      html2canvas(data, html2canvasOptions).then(async (canvas) => {
        var imgData = await canvas.toDataURL("image/png");
        var imgWidth = 204;
        var imgHeight = 291;
        var position = 3;
        var doc = new jsPDF("p", "mm");
        doc.addImage(
          imgData,
          "PNG",
          3,
          position,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );

        // table.style.height = "700px";
        // data.style.width = "auto";
        that.isPrinting = false;
        that.pdfLink = doc.output("datauristring");
        // for (var i = 0; i < elms.length; i++) elms[i].style.display = "block";
        // for (var i = 0; i < elmsToShow.length; i++)
        //   elmsToShow[i].style.display = "none";
        if (download) {
          let id = uuidv4();
          engine.creat_tmp_pdf(id, doc.output(), function (data) {
            if (data.code === 0) {
              let url = data.data.url + "api/v1/" + id + "/Etat%20des%20lieux";
              Browser.open({ url });
            }
          });
        } else {
          that.sendEmail = true;
        }
      });
    },
    writeSignature(ref, disabled) {
      if (disabled === false) {
        this.selectedSignature = ref;
        this.isSignatureOpen = true;
      }
    },
    writeUpToPhoto(ref, disabled) {
      if (disabled === false) {
        this.selectedPhoto = ref;
        this.isPhotoEditorOpen = true;
      }
    },
    clear(ref) {
      this.$refs[ref].clearSignature();
    },
    undo(ref) {
      this.$refs[ref].undoSignature();
    },
    close() {
      this.isPhotoEditorOpen = false;
      this.isSignatureOpen = false;
    },
    save(ref) {
      const { isEmpty, data } = this.$refs[ref].saveSignature();
      if (isEmpty === false) this[ref] = data;
      if (ref == "signature_dep") this.localData.CLD_SIGNATURE_DEPART = data;
      if (ref == "signature_ret") this.localData.CLD_SIGNATURE_RETOUR = data;
      if (ref == "filtre_photo_dep")
        this.localData.CLD_PHOTO_EDITION_DEPART = data;
      if (ref == "filtre_photo_ret")
        this.localData.CLD_PHOTO_EDITION_RETOUR = data;
      this.isPhotoEditorOpen = false;
      this.isSignatureOpen = false;
    },
    closeDialog() {
      if (this.isEditionMode) {
        window.location =
          window.location.href + "?contrat=" + this.localData["CLD_NUM_CONTRAT"];
        location.reload();
      } else this.$emit("closeDialog");
    },
    setNewEtatdl(closeEtdl) {
      let error = undefined;
      let formVerif = {
        0: [
          "CLD_DATE_DEPART_VALIDATION",
          "CLD_KILOMETRE_DEPART",
          "CLD_SIGNATURE_DEPART",
        ],
        1: [
          "CLD_DATE_RETOUR_VALIDATION",
          "CLD_KILOMETRE_RETOUR",
          "CLD_SIGNATURE_RETOUR",
        ],
        2: [
          "CLD_DATE_DEPART_VALIDATION",
          "CLD_KILOMETRE_DEPART",
          "CLD_SIGNATURE_DEPART",
          "CLD_DATE_RETOUR_VALIDATION",
          "CLD_KILOMETRE_RETOUR",
          "CLD_SIGNATURE_RETOUR",
        ],
      };
      error = formVerif[this.data.selectedItem].find(
        (val) => this.localData[val] == ""
      );
      if (error) {
        this.errorMessage = {
          msg: this.$t("etat-des-lieux-velo.form.neededElem") + " : " + this.$t("etat-des-lieux-velo.form." + error),
          class: this.data.selectedItem == 0 ? "sortie" : "entrer",
        };

        // document.getElementById(error).scrollIntoView({
        //   behavior: "smooth",
        //   block: "center",
        //   inline: "nearest",
        // });

        this.snackbar = true;
      } else {
        if (closeEtdl) this.closeDialog();
        if (this.data.selectedItem == 0) {
          this.localData.CLD_CHECK_LIST_JSON_GESTION =
            this.localData.CLD_CHECK_LIST_JSON_GESTION.map((val) => ({
              CH_DESIGNATION: val.CH_DESIGNATION,
              CH_REFERENCE: val.CH_REFERENCE,
              ETAT_DEPART: val.ETAT_DEPART,
              ETAT_RETOUR: val.ETAT_DEPART,
            }));
          this.localData.CLD_CONTROLE_DEPART = 1;
        }
        if (this.data.selectedItem == 1) {
          this.localData.CLD_CONTROLE_RETOUR = 1;
        }
        this.localData.CLD_ETATDL_VALIDE = this.localData.CLD_ETATDL_VALIDE
          ? "valide"
          : "error";
        this.localData.CLD_CHECK_LIST_JSON_GESTION = JSON.stringify({
          TotalLigne: this.localData.CLD_CHECK_LIST_JSON_GESTION.length,
          fieldData: this.localData.CLD_CHECK_LIST_JSON_GESTION,
        });
        this.$emit("setNewEtatdl", {
          data: this.localData,
        });
      }
    },
  },
};
</script>

<style>
.etat-des-lieux-velo td {
  height: 30px !important;
}

.etat-des-lieux-velo .mx-input {
  background-color: white !important;
}

.etat-des-lieux-velo .style-1 {
  background-color: rgb(245, 245, 245);
}

.etat-des-lieux-velo .custom-red .v-input--selection-controls__input div {
  color: red !important;
}
.etat-des-lieux-velo table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.etat-des-lieux-velo .wrapper {
  position: relative;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.etat-des-lieux-velo .wrapper-img {
  position: absolute;
  left: 0;
  top: 0;
}

.etat-des-lieux-velo .pad {
  position: absolute;
  left: 0px;
  top: 35px;
}

.etat-des-lieux-velo .area .v-field--variant-solo {
  box-shadow: none !important;
}
</style>