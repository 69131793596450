<template>
    <v-dialog
      v-model="show"
      fullscreen
      persistent
    >
      <v-card outlined color="#fafafa">
          <velosForm
            v-if="Object.keys(data).length !== 0"
            @closeDialog="closeDialog()"
            @setNewEtatdl="setNewEtatdl($event)"
            :formData="data"
            :clients_list="clients_list"
            :agency_list="agency_list"
            :user_information="user_information"
          />
      </v-card>
    </v-dialog>
</template>

<script>
import velosForm from "./velo-form";

export default {
  data() {
    return {
      show: true,
      localDialog: true,
    };
  },
  components: {
    velosForm,
  },
  props: {
    dialog: {},
    data: {},
    clients_list: {},
    user_information: {},
    agency_list: {},
  },
  mounted() {
    this.localDialog = this.dialog;
  },
  watch: {
    localDialog(val) {
      if (val === false) {
        this.closeDialog();
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    setNewEtatdl(data) {
      this.$emit("setNewEtatdl", data);
    },
  },
};
</script>

<style>
</style>