import { Chart, Tooltip, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { loadFonts } from './plugins/webfontloader'
import vuetify from './plugins/vuetify'
import { createApp } from 'vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import fr from './locale/fr.json'
import en from './locale/en.json'
import App from './App.vue'

Chart.register(Tooltip, ChartDataLabels, ...registerables);

loadFonts()

const messages = {
    en: en,
    fr: fr
}

const i18n = createI18n({
    locale: 'fr',
    messages,
})


const app = createApp(App)

app.use(i18n)
app.use(router)
app.use(store)
app.use(vuetify)

app.mount('#app')