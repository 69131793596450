import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import momentPlugin from '@fullcalendar/moment'
import interactionPlugin from "@fullcalendar/interaction";

// import { Calendar } from "@fullcalendar/core";

import fr from "@fullcalendar/core/locales/fr";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
import { useStore } from 'vuex'

import moment from 'moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import engine from "@/projects/planning-disponibilite/core/index.js";

export default function calendarData() {
    const store = useStore()
    const calendarDates = ref({ start: undefined, end: undefined });
    const resourceIds = ref([]);
    const filteredRessouceIds = ref([])
    const eventsList = ref([]);
    const eventsListReserved = ref([]);
    const eventsListSav = ref([]);
    const daysToHide = ref([6, 0]);
    const showAllSubFamily = ref(false);
    const selectedSubFamilyToShow = ref(undefined);
    const settingsData = ref({});
    const isModalsOpen = ref({
        'dateModal': { value: false, data: {} },
        'eventModal': { value: false, data: {} },
        'settingsModal': { value: false, data: {} },
        'eventSavModal': { value: false, data: {} },
    });
    // const isModalSettingsOpen = ref(false);
    const eventColorByType = ref({
        LOCATION: { label: 'Location', color: '#A1887F' },
        RESERVATION: { label: 'Reservation', color: '#81C784' },
        SAV: { label: 'Sav', color: '#A1887F' }
    })

    onMounted(() => {
        updateCalendarColors()
        updateCalendarDaysToShow()
        engine.get_parc(function(data) {
            if (data.code == 0) {
                resourceIds.value = data.data.data.map((val) => ({
                    id: (val.fieldData.SOUS_FAMILLE + val.fieldData.MATRICULE).toUpperCase(),
                    title: val.fieldData.MATRICULE,
                    subFamily: val.fieldData.SOUS_FAMILLE,
                    matricule: val.fieldData.MATRICULE,
                }));
            }
        })
    })

    const getCustomButtons = computed(function() {
        let customButtons = {}
        customButtons.showSubFamily = {
            text: showAllSubFamily.value == true ? 'Afficher les Locations / Réservations' : 'Afficher l\'ensemble du parc',
            click: changeRessourceType
        }
        customButtons.openSettings = {
            text: 'Paramètre',
            click: openSettings
        }
        return customButtons
    })

    const getAllEvents = computed(function() {
        return eventsList.value.concat(eventsListReserved.value.concat(eventsListSav.value))
    })

    const calendarOptions = computed(function() {
        let option = {
            datesSet: updateCalendarView,
            dateClick: calendarClicked,
            eventClick: eventClicked,
            schedulerLicenseKey: '0359429366-fcs-1650894843',
            nowIndicator: true,
            initialView: "resourceTimelineWeek",
            locale: fr,
            height: "91.6vh",
            headerToolbar: {
                left: isRoot.value ? 'today showSubFamily openSettings' : 'today showSubFamily',
                center: 'title',
                right: 'resourceTimelineDay,resourceTimelineWeek prev,next'
            },
            views: {
                day: {
                    titleFormat: 'dddd D MMMM YYYY',
                },
            },
            slotDuration: "02:00:00",
            slotMinTime: "06:00:00",
            slotMaxTime: "20:00:00",
            plugins: [momentPlugin, dayGridPlugin, timeGridPlugin, resourceTimelinePlugin, interactionPlugin],
            aspectRatio: 1.6,
            resourceAreaWidth: '255px',
            resourceGroupField: 'subFamily',
            allDaySlot: false,
            resourceAreaHeaderContent: 'Familles - Matricules',
            hiddenDays: daysToHide.value,
            events: getAllEvents.value,
            resources: filteredRessouceIds.value,
            // editable: true,
            // resourcesInitiallyExpanded: false,
            customButtons: getCustomButtons.value
                // customButtons: {
                //     showSubFamily: {
                //         text: showAllSubFamily.value == true ? 'Afficher les Locations / Réservations' : 'Afficher l\'ensemble du parc',
                //         click: changeRessourceType
                //     },
                //     openSettings: {
                //         text: 'Paramètre',
                //         click: openSettings
                //     },
                // }
        }
        return option;
    })

    const updateCalendarDaysToShow = function() {
        engine.get_day_to_hide(function(data) {
            if (data.code == 0 && data.data.data[0].fieldData.Value != "" && data.data.data[0].fieldData.Value != undefined) {
                daysToHide.value = data.data.data[0].fieldData.Value.split(',').map((val) => parseInt(val))
            }
        })
    }

    const updateCalendarColors = function() {
        engine.get_calendar_colors(function(data) {
            if (data.code == 0 && data.data.data[0].fieldData.Value != "" && data.data.data[0].fieldData.Value != undefined) {
                eventColorByType.value = {...eventColorByType.value, ...JSON.parse(data.data.data[0].fieldData.Value) }
                setEvents()
            }
        })
    }

    const openSettings = function() {
        setModal('settingsModal', true, settingsData.value)
    }

    const eventClicked = function(data) {
        let modalData = data.event._def.extendedProps.eventData
        if (data.event._def.extendedProps.type == 'SAV')
            setModal('eventSavModal', true, data)
        else
            setModal('eventModal', true, modalData)
    }

    const calendarClicked = function(data) {
        if (isRoot.value)
            setModal('dateModal', true, data)
    }

    const setModal = function(key, value, data) {
        isModalsOpen.value[key] = { value, data }
    }

    const changeRessourceType = function() {
        showAllSubFamily.value = !showAllSubFamily.value
        verifRessourceListe()
    }

    const verifRessourceListe = function() {
        if (selectedSubFamilyToShow.value != undefined) {
            filteredRessouceIds.value = resourceIds.value.filter((val) => val.subFamily == selectedSubFamilyToShow.value)
        } else {
            if (showAllSubFamily.value) {
                filteredRessouceIds.value = resourceIds.value
            } else {
                let findedSubFamily = getAllEvents.value.map((val) => val.subFamily)
                filteredRessouceIds.value = resourceIds.value.filter((val) => findedSubFamily.includes(val.subFamily))
            }
        }
    }

    const updateCalendarView = function(calendarData) {
        let start = moment(calendarData.start).format("x")
        let end = moment(calendarData.end).format("x")
        if (calendarDates.value.start == start && calendarDates.value.end == end) return
        else {
            showAllSubFamily.value = false
        }
        updateCalendarEvents(start, end)
    }

    const updateCalendarEvents = function(start, end) {
        calendarDates.value = { start, end }
        setEvents()
    }

    const setSubFamilyToShow = function(subFamily) {
        selectedSubFamilyToShow.value = subFamily
        verifRessourceListe()
    }

    const setEvents = function() {
        engine.get_events(
            parseFloat(calendarDates.value.start),
            parseFloat(calendarDates.value.end),
            function(data) {
                eventsList.value = []
                if (data.code == 0) {
                    let tmpEventsList = data.data.data.map((val) => val.fieldData)
                    let localEventList = []
                    for (let i = 0; i < tmpEventsList.length; i++) {
                        let subFamily = tmpEventsList[i].CLD_SOUS_FAMILLE
                        let matricule = tmpEventsList[i].CLD_MATRICULE
                        let title = ""
                        if (tmpEventsList[i].CLD_NOM_CLIENT)
                            title += tmpEventsList[i].CLD_NOM_CLIENT + " - "
                        title += tmpEventsList[i].CLD_CLIENT_ADRESSE + ' ' + tmpEventsList[i].CLD_CLIENT_VILLE + ' ' + tmpEventsList[i].CLD_CLIENT_CODEPOSTAL
                        let id = subFamily + matricule
                        localEventList.push({
                            resourceIds: [id.toUpperCase()],
                            title: title,
                            start: moment(tmpEventsList[i].CLD_DATE_LIVRAISON_DEPART).format(),
                            end: moment(tmpEventsList[i].CLD_DATE_LIVRAISON_RETOUR).format(),
                            color: eventColorByType.value.LOCATION.color,
                            subFamily: subFamily,
                            matricule: matricule,
                            eventData: tmpEventsList[i],
                            type: "LOCATION"
                        })
                    }
                    eventsList.value = localEventList
                }
                setEventsReserved()
                setEventsSav()
            })
    }

    const setEventsSav = function() {
        engine.get_events_sav(
            parseFloat(calendarDates.value.start),
            parseFloat(calendarDates.value.end),
            function(data) {
                eventsListSav.value = []
                if (data.code == 0) {
                    let tmpEventsList = data.data.data.map((val) => val.fieldData)
                    let localEventListSav = []
                    for (let i = 0; i < tmpEventsList.length; i++) {
                        let subFamily = tmpEventsList[i].sous_famille
                        let matricule = tmpEventsList[i]['N° MATR INT']
                            // let title = tmpEventsList[i].societeliv + " : " + tmpEventsList[i].villeliv + ' ' + tmpEventsList[i].adliv
                        let title = "SAV"
                        let id = subFamily + matricule
                        localEventListSav.push({
                            resourceIds: [id.toUpperCase()],
                            title: title,
                            start: moment(tmpEventsList[i].Debut_TIMESTAMP).format(),
                            end: moment(tmpEventsList[i].Fin_TIMESTAMP).format(),
                            color: eventColorByType.value.SAV.color,
                            subFamily: subFamily,
                            matricule: matricule,
                            eventData: tmpEventsList[i],
                            type: "SAV"
                        })
                    }
                    eventsListSav.value = localEventListSav
                }
            })
    }
    const setEventsReserved = function() {
        engine.get_events_reserved(
            parseFloat(calendarDates.value.start),
            parseFloat(calendarDates.value.end),
            function(data) {
                eventsListReserved.value = []
                if (data.code == 0) {
                    let tmpEventsList = data.data.data.map((val) => val.fieldData)
                    let localEventListReserved = []
                    for (let i = 0; i < tmpEventsList.length; i++) {
                        let subFamily = tmpEventsList[i].R_SOUS_FAMILLE
                        let matricule = tmpEventsList[i].R_MATRICULE
                        let title = ""
                        if (tmpEventsList[i].R_NOM_CLIENT)
                            title += tmpEventsList[i].R_NOM_CLIENT + " - "
                        title = tmpEventsList[i].R_CLIENT_ADRESSE + ' ' + tmpEventsList[i].R_CLIENT_VILLE + ' ' + tmpEventsList[i].R_CLIENT_CODEPOSTAL
                        let id = subFamily + matricule
                        localEventListReserved.push({
                            resourceIds: [id.toUpperCase()],
                            title: title,
                            start: moment(tmpEventsList[i].R_DATE_LIVRAISON_DEPART).format(),
                            end: moment(tmpEventsList[i].R_DATE_LIVRAISON_RETOUR).format(),
                            color: eventColorByType.value.RESERVATION.color,
                            subFamily: subFamily,
                            matricule: matricule,
                            eventData: tmpEventsList[i],
                            type: "RESERVATION"
                        })
                    }
                    eventsListReserved.value = localEventListReserved
                    verifRessourceListe()
                } else {
                    verifRessourceListe()
                }
            })
    }

    const isRoot = computed(function() {
        return store.state.user.admin == 1 ? true : false
    })

    return {
        calendarOptions,
        resourceIds,
        setSubFamilyToShow,
        isModalsOpen,
        setModal,
        eventColorByType,
        updateCalendarColors,
        updateCalendarDaysToShow,
        daysToHide
    };
}