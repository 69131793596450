<template>
  <v-col
    v-if="materials_list"
    class="px-1 py-0 etat-des-lieux-materiel"
    style="height: 100%"
    :class="[dataMenu[selectedItem].class]"
  >
    <v-dialog v-if="isqCodeReaderOpen" v-model="isqCodeReaderOpen" width="500">
      <v-card>
        <qcode-reader></qcode-reader>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-if="modalUser" v-model="modalUser" width="500" persistent>
      <v-card>
        <v-col>
          <modalUserDialog
            @close="modalUser = false"
            :agencyList="agency_list"
            :get_user_information="get_user_information"
            :isAdmin="isAdmin"
          />
        </v-col>
      </v-card>
    </v-dialog> -->
    <div
      v-if="!isOpenMiniVariant"
      style="
        background-color: rgb(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
      "
      @click="isOpenMiniVariant = !isOpenMiniVariant"
    ></div>
    <v-app-bar
      v-if="false"
      color="#eeeeee"
      :clipped-left="isIos"
      dense
      app
      class="mx-0"
      :style="{ 'padding-top': isIos ? '16px' : '0px' }"
      :height="isIos ? getTopBarHeight : ''"
    >
      <v-icon
        v-if="isMobile"
        class="pr-2"
        @click="isOpenMiniVariant = !isOpenMiniVariant"
        >mdi-menu</v-icon
      >
      {{ $t("etat-des-lieux-materiel.home.title") }} <v-spacer></v-spacer>
      <v-chip @click="user()">
        <button class="pa-2">
          <v-icon> mdi-account </v-icon>
        </button>
        <h3 v-if="get_user_information && isMobile === false">
          {{ get_user_information.user_name }}
        </h3>
      </v-chip>
      <button @click="logout(false)" class="pa-2">
        <v-icon color="red"> mdi-door-open </v-icon>
      </button>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <button class="px-1 mt-2">
              <img :src="$i18n.locale + '.png'" width="30px" />
            </button>
          </div>
          <!-- <v-btn text v-bind="attrs" v-on="on">
            <img :src="$i18n.locale + '.png'" width="30px" />
          </v-btn> -->
        </template>
        <v-card align="center">
          <v-btn
            width="100%"
            v-if="$i18n.locale !== 'fr'"
            small
            @click="$i18n.locale = 'fr'"
            >FR</v-btn
          >
          <v-btn
            width="100%"
            v-if="$i18n.locale !== 'en'"
            small
            text
            @click="$i18n.locale = 'en'"
            >EN</v-btn
          >
        </v-card>
      </v-menu>
    </v-app-bar>
    <dialogPage
      v-if="dialog"
      :dialog="dialog"
      :data="dialogData"
      :clients_list="clients_list"
      :user_information="get_user_information"
      :agencyList="agency_list"
      @closeDialog="closeDialog($event)"
      @newMaterielForm="newMaterielForm($event)"
    />
    <div v-else>
      <div>
        <v-navigation-drawer permanent temporary rail>
          <v-list-item
            v-for="(item, i) in itemsMenu"
            class="py-4"
            :class="[item.class]"
            style="font-size: 20px"
            :key="i"
            :prepend-icon="item.icon"
            :title="item.text"
            @click="selectedItem = i"
          />
        </v-navigation-drawer>
      </div>
      <div class="">
        <div style="position: absolute; right: 10px">
          <v-btn
            v-if="selectedItem != 0"
            @click="isqCodeReaderOpen = true"
            style=""
            color="black"
            icon="mdi-qrcode-scan"
            size="small"
            variant="text"
          ></v-btn>
          <v-divider vertical class="mx-1"></v-divider>
          <v-btn
            @click="updateTable"
            style=""
            color="black"
            icon="mdi-refresh"
            size="small"
            variant="text"
          ></v-btn>
        </div>
        <v-col align="center" class="pt-0 pb-2 px-0">
          <v-col
            class="pa-0"
            style="text-transform: uppercase"
            align="center"
            cols="12"
          >
            <div
              class="py-1"
              style="
                background-color: white;
                border-radius: 5px;
                color: black;
                font-size: 20px;
                border: solid lightgrey 1px;
                font-weight: 500;
              "
            >
              {{ dataMenu[selectedItem].title }}
            </div>
          </v-col>
        </v-col>
        <v-table
          height="87vh"
          :class="[dataMenu[selectedItem].class]"
          class="pa-2"
          fixed-header
        >
          <thead style="background-color: white">
            <tr>
              <th v-for="elem in get_tab_header" :key="elem.value">
                {{ elem.text }}
              </th>
            </tr>
          </thead>
          <tbody style="background-color: white">
            <tr
              v-for="(item, idx) in get_tab_items"
              :key="idx"
              @click="openDialog(item)"
            >
              <td>{{ item.CLD_NUM_CONTRAT }}</td>
              <td>{{ item.CLD_SOUS_FAMILLE }}</td>
              <td>{{ item.CLD_DESCRIPTION }}</td>
              <td>{{ item.CLD_MATRICULE }}</td>
              <td v-if="selectedItem != '0'">
                {{ item.CLD_DATE_DEPART_VALIDATION }}
              </td>
              <td v-if="selectedItem != '0'">
                {{ item.CLD_DATE_RETOUR_VALIDATION }}
              </td>
              <td v-if="selectedItem != '0'">{{ item.CLD_TECHNICIEN }}</td>
              <td>{{ item.CLD_AGENCY.ag_nom }}</td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>
  </v-col>
</template>

<script>
import dialogPage from "./modal-dialog";
// import modalUserDialog from "./modalUser";
import qcodeReader from "./qcodeReader";
import engine from "../core/index";

export default {
  data() {
    return {
      drawerIsOpen: false,
      isqCodeReaderOpen: false,
      windowHeight: window.innerHeight,
      isAdmin: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      pathFrFlag: "../public/fr.png",
      isOpenMiniVariant: true,
      dialog: false,
      modalUser: false,
      search: "",
      selectedItem: 0,
      selectedMaterial: {},
      dialogData: {},
      materials_list: undefined,
      clients_list: undefined,
      agency_list: undefined,
      returned_materials_list: undefined,
    };
  },
  components: {
    dialogPage,
    // modalUserDialog,
    qcodeReader,
  },
  mounted() {
    let that = this;
    // let token = localStorage.getItem("token");
    // engine.user_token_is_valide(token, function callback(data) {
    //   if (data.code === 0) {
    that.setMaterialList();
    engine.get_clients(function (data) {
      if (data.code === 0)
        that.clients_list = that.formatFromServerToTable(data.data.data);
    });
    engine.get_agency(function (data) {
      if (data.code === 0)
        that.agency_list = that.formatFromServerToTable(data.data.data);
    });
    engine.get_user_info(function (data) {
      that.get_user_information = data.data;
      if (that.get_user_information.admin == 1) that.isAdmin = true;
    });
    // }
    //  else {
    //   that.logout(true);
    // }
    // });
  },
  computed: {
    get_user_information() {
      return this.$store.state.user;
    },
    getFormHeight() {
      return this.windowHeight - 385;
    },
    getTopBarHeight() {
      if (this.isIos) return 70;
      if (this.isMobile) return 45;
      return "";
    },
    isIos() {
      if (
        // navigator.userAgent.toLowerCase().includes("ipad") ||
        // navigator.userAgent.toLowerCase().includes("mac") ||
        // navigator.userAgent.toLowerCase().includes("ipode") ||
        navigator.userAgent.toLowerCase().includes("iphone")
      )
        return true;
      return false;
    },
    isMobile() {
      return false;
      // return this.$vuetify.breakpoint.mobile;
    },
    dataMenu() {
      return [
        {
          title: this.$t("etat-des-lieux-materiel.home.departure_list"),
          option: {
            btn: [this.$t("etat-des-lieux-materiel.form.exit_of_material")],
            key: "depart",
            title: this.$t("etat-des-lieux-materiel.form.exit_of_material"),
            disabled: { entrer: true, sortie: false },
          },

          class: "sortie",
        },
        {
          title: this.$t("etat-des-lieux-materiel.home.return_list"),
          option: {
            btn: [this.$t("etat-des-lieux-materiel.form.return_of_material")],
            key: "return",
            title: this.$t("etat-des-lieux-materiel.form.return_of_material"),
            disabled: { entrer: false, sortie: true },
          },
          class: "entrer",
        },
        {
          title: this.$t("etat-des-lieux-materiel.home.finish_list"),
          option: {
            btn: [this.$t("etat-des-lieux-materiel.form.finished_material")],
            key: "returned",
            title: this.$t("etat-des-lieux-materiel.form.finished_material"),
            disabled: { entrer: true, sortie: true },
          },
          class: "termine",
        },
      ];
    },
    itemsMenu() {
      return [
        {
          text: this.$t("etat-des-lieux-materiel.home.departure"),
          icon: "mdi-arrow-right-bold",
          class: "sortie",
        },
        {
          text: this.$t("etat-des-lieux-materiel.home.return"),
          icon: "mdi-arrow-left-bold",
          class: "entrer",
        },
        { text: this.$t("etat-des-lieux-materiel.home.finish"), icon: "mdi-lock", class: "termine" },
      ];
    },
    get_client_state() {
      let state = undefined;
      let OldState = undefined;
      if (this.selectedMaterial) {
        let lien = this.selectedMaterial.data["CLD_LIEN"];
        let dataList = this.materials_list.filter(
          (val) => val.data["CLD_LIEN"] === lien
        );
        if (dataList)
          for (let i = 0; i < dataList.length; i++) {
            if (
              dataList[i].data["CLD_CONTROLE_DEPART"] !==
              dataList[i].data["CLD_CONTROLE_RETOUR"]
            )
              return "X";
            if (
              dataList[i].data["CLD_CONTROLE_DEPART"] === 1 &&
              dataList[i].data["CLD_CONTROLE_RETOUR"] === 1
            )
              state = "2";
            if (
              dataList[i].data["CLD_CONTROLE_DEPART"] === 0 &&
              dataList[i].data["CLD_CONTROLE_RETOUR"] === 0
            )
              state = "0";
            if (OldState && state && OldState !== state) return "X";

            OldState = state;
            state = undefined;
          }
      }
      return OldState;
    },
    get_tab_items() {
      let tab_items = [];
      let data = JSON.parse(JSON.stringify(this.get_selected_material_data));
      for (let i = 0; i < data.length; i++) {
        if (
          this.selectedItem !== 0 ||
          (this.selectedItem === 0 && data[i]["CLD_LIEN"] !== "")
        ) {
          let item = data[i];
          tab_items.push(item);
        }
      }
      return tab_items;
    },

    get_tab_header() {
      let tab_header = [];
      let list = [];
      if (this.selectedItem === 0)
        list = [
          "CLD_NUM_CONTRAT",
          "CLD_SOUS_FAMILLE",
          "CLD_DESCRIPTION",
          "CLD_MATRICULE",
          "CLD_AGENCY",
        ];

      if (this.selectedItem === 1 || this.selectedItem === 2)
        list = [
          "CLD_NUM_CONTRAT",
          "CLD_SOUS_FAMILLE",
          "CLD_DESCRIPTION",
          "CLD_MATRICULE",
          "CLD_DATE_DEPART_VALIDATION",
          "CLD_DATE_RETOUR_VALIDATION",
          "CLD_TECHNICIEN",
          "CLD_AGENCY",
        ];
      for (let i = 0; i < list.length; i++) {
        tab_header.push({
          text: this.$t("etat-des-lieux-materiel.home." + list[i]),
          value: list[i],
        });
      }

      return tab_header;
    },
    get_selected_material_data() {
      let data = [];
      switch (this.dataMenu[this.selectedItem].option.key) {
        case "depart":
          data = this.get_depart_material;
          break;
        case "return":
          data = this.get_return_material;
          break;
        case "returned":
          data = this.get_returned_material;
          break;
      }
      return data;
    },
    get_depart_material() {
      let depart_material_list = [];
      if (this.materials_list) {
        for (let i = 0; i < this.materials_list.length; i++) {
          if (
            this.get_user_information != undefined &&
            String(this.get_user_information.user_id_agence) ==
              String(this.materials_list[i].data.CLD_ID_AGENCE) &&
            this.materials_list[i].data["CLD_CONTROLE_DEPART"] == 0 &&
            this.materials_list[i].data["CLD_CONTROLE_RETOUR"] == 0
          ) {
            depart_material_list.push(
              this.get_materials_data_to_show(this.materials_list[i])
            );
          }
        }
      }
      return depart_material_list;
    },
    get_return_material() {
      let return_material_list = [];
      if (this.materials_list)
        for (let i = 0; i < this.materials_list.length; i++) {
          if (
            this.get_user_information &&
            String(this.get_user_information.user_id_agence) ===
              String(this.materials_list[i].data.CLD_ID_AGENCE) &&
            this.materials_list[i].data["CLD_CONTROLE_DEPART"] === 1 &&
            this.materials_list[i].data["CLD_CONTROLE_RETOUR"] === 0
          ) {
            return_material_list.push(
              this.get_materials_data_to_show(this.materials_list[i])
            );
          }
        }
      return return_material_list;
    },
    get_returned_material() {
      let returned_material_list = [];
      if (this.materials_list)
        for (let i = 0; i < this.materials_list.length; i++) {
          if (
            this.get_user_information &&
            String(this.get_user_information.user_id_agence) ===
              String(this.materials_list[i].data.CLD_ID_AGENCE) &&
            this.materials_list[i].data["CLD_CONTROLE_DEPART"] === 1 &&
            this.materials_list[i].data["CLD_CONTROLE_RETOUR"] === 1
          ) {
            returned_material_list.push(
              this.get_materials_data_to_show(this.materials_list[i])
            );
          }
        }
      return returned_material_list;
    },
  },
  methods: {
    uploadPhoto(key, data, formData) {
      formData = formData.data;
      data = Object.values(JSON.parse(data));
      let keyTrade = { CLD_PHOTO_1: "DEPART", CLD_PHOTO_2: "RETOUR" };
      let maxPhoto = 3;
      for (let i = 0; i < data.length; i++) {
        let dropBoxPath =
          formData["CLD_MATRICULE"] +
          "/" +
          formData["CLD_NUM_CONTRAT"] +
          "/" +
          "PHOTOS" +
          "/" +
          keyTrade[key] +
          "_" +
          i +
          ".png";

        engine.file_upload(data[i], dropBoxPath, function () {});
      }
      if (data.length < maxPhoto) {
        for (let size = data.length; size <= maxPhoto; size++) {
          let dropBoxPath =
            formData["CLD_MATRICULE"] +
            "/" +
            formData["CLD_NUM_CONTRAT"] +
            "/" +
            "PHOTOS" +
            "/" +
            keyTrade[key] +
            "_" +
            size +
            ".png";

          engine.photo_delete(dropBoxPath, function () {});
        }
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    updateTable() {
      this.setMaterialList();
    },
    tokenIsValide(callbackGeneral) {
      callbackGeneral();
    },
    user() {
      this.modalUser = true;
    },
    logout(isRedirect) {
      if (isRedirect) {
        let query = this.$route.query;
        query["type"] = "redirect";
        this.$router.push({ path: "/etat-des-lieux-materiel", query });
      } else
        this.$router.push({
          path: "/etat-des-lieux-materiel",
          query: { type: "disconnection" },
        });

      localStorage.clear();
    },
    setMaterialList() {
      let that = this;
      this.tokenIsValide(function () {
        engine.get_materials(function (data) {
          if (data.code === 0) {
            that.materials_list = that
              .formatFromServerToTable(data.data.data)
              .reverse();
            if (that.$route.query && that.$route.query.contrat) {
              let findedContrat = undefined;
              try {
                findedContrat = JSON.parse(
                  JSON.stringify(
                    that.materials_list.find(
                      (val) =>
                        val.data.CLD_NUM_CONTRAT === that.$route.query.contrat
                    )
                  )
                );
              } catch (e) {
                console.log(e);
              }
              that.$router.push(that.$route.path);
              if (typeof findedContrat === "object") {
                let state = undefined;
                if (
                  findedContrat.data["CLD_CONTROLE_DEPART"] !==
                  findedContrat.data["CLD_CONTROLE_RETOUR"]
                )
                  state = 1;
                if (
                  findedContrat.data["CLD_CONTROLE_DEPART"] === 1 &&
                  findedContrat.data["CLD_CONTROLE_RETOUR"] === 1
                )
                  state = 2;
                if (
                  findedContrat.data["CLD_CONTROLE_DEPART"] === 0 &&
                  findedContrat.data["CLD_CONTROLE_RETOUR"] === 0
                )
                  state = 0;
                if (state !== undefined) {
                  that.selectedItem = state;
                  findedContrat.data =
                    that.get_materials_data_to_show(findedContrat);
                  that.openDialog(findedContrat.data);
                }
              }
            }
            if (that.selectedMaterial && that.selectedMaterial.data) {
              engine.set_client_state(
                that.selectedMaterial.data["CLD_LIEN"],
                that.get_client_state,
                function () {}
              );
            }
          }
        });
      });
    },
    setMaterialInHistorie(data) {
      this.tokenIsValide(function () {
        engine.create_historie_material(data, function () {});
      });
    },
    newMaterielForm(materiel) {
      let data = materiel.data;
      this.dialog = false;
      delete data.CLD_AGENCY;
      delete data.CL_CLIENT_EMAIL;
      delete data.CL_CLIENT_RAISON_SOCIALE;
      delete data.CL_CLIENT_TELEPHONE;
      delete data.CL_NOM_CLIENT;
      delete data.CL_ID_CLIENT;

      this.selectedMaterial.data["CLD_LIEN"] = data["CLD_LIEN"];
      data["CLD_CHECK_LIST_JSON_GESTION"] = JSON.stringify(
        data["CLD_CHECK_LIST_JSON_GESTION"]
      );
      let that = this;
      if (this.selectedItem === 0) {
        data["CLD_HEURE_DEPART"] = data["CLD_HEURE_DEPART"].replace("H", ":");
        if (materiel.edit === false) {
          data["CLD_CONTROLE_DEPART"] = 1;
          data["CLD_CONTROLE_RETOUR"] = 0;
        }
        this.uploadPhoto("CLD_PHOTO_1", data["CLD_PHOTO_1"], materiel);

        delete data.CLD_PHOTO_1;
        delete data.CLD_PHOTO_2;
        this.tokenIsValide(function () {
          engine.edit_material(
            data["CLD_MATRICULE"],
            data["CLD_NUM_CONTRAT"],
            data["CLD_IDEnreg"],
            data,
            function (edit_material_data) {
              if (edit_material_data.code == 0) that.setMaterialList();
            }
          );
        });
      } else if (this.selectedItem === 1 || this.selectedItem === 2) {
        data["CLD_HEURE_RETOUR"] = data["CLD_HEURE_RETOUR"].replace("H", ":");
        if (materiel.edit === false) {
          data["CLD_CONTROLE_DEPART"] = 1;
          data["CLD_CONTROLE_RETOUR"] = 1;
        }
        {
          this.uploadPhoto("CLD_PHOTO_1", data["CLD_PHOTO_1"], materiel);
          this.uploadPhoto("CLD_PHOTO_2", data["CLD_PHOTO_2"], materiel);
        }

        delete data.CLD_PHOTO_1;
        delete data.CLD_PHOTO_2;
        this.tokenIsValide(function () {
          engine.edit_material(
            data["CLD_MATRICULE"],
            data["CLD_NUM_CONTRAT"],
            data["CLD_IDEnreg"],
            data,
            function (data) {
              if (data.code === 0) {
                that.setMaterialList();
              }
            }
          );
        });
      }
      this.closeDialog();
    },
    get_materials_data_to_show(material) {
      this.selectedMaterial = material;
      let filtered_material = {};

      let agency = {};
      if (this.agency_list !== undefined) {
        let agencyObj = this.agency_list.find(
          (val) => val.data["ID_Agence"] == material.data["CLD_ID_AGENCE"]
        );
        agency = agencyObj.data;
      }
      filtered_material["CLD_AGENCY"] = agency;
      if (this.clients_list && this.materials_list) {
        filtered_material["CLD_DESCRIPTION"] = material.data["CLD_DESCRIPTION"];
        filtered_material["CLD_MATRICULE"] = material.data["CLD_MATRICULE"];
        filtered_material["CLD_SOUS_FAMILLE"] =
          material.data["CLD_SOUS_FAMILLE"];
        filtered_material["CLD_DATE_DEPART_VALIDATION"] =
          material.data["CLD_DATE_DEPART_VALIDATION"];
        filtered_material["CLD_DATE_RETOUR_VALIDATION"] =
          material.data["CLD_DATE_RETOUR_VALIDATION"];
        filtered_material["CLD_NUM_CONTRAT"] = material.data["CLD_NUM_CONTRAT"];
        filtered_material["CLD_TECHNICIEN"] = material.data["CLD_TECHNICIEN"];
        filtered_material["CLD_CHECK_LIST_JSON"] =
          material.data["CLD_CHECK_LIST_JSON"];
        filtered_material["CLD_CHECK_LIST_JSON_GESTION"] =
          material.data["CLD_CHECK_LIST_JSON_GESTION"];
        filtered_material["CLD_COMMENTAIRE_DEPART"] =
          material.data["CLD_COMMENTAIRE_DEPART"];
        filtered_material["CLD_COMMENTAIRE_RETOUR"] =
          material.data["CLD_COMMENTAIRE_RETOUR"];
        filtered_material["CLD_LIEN"] = material.data["CLD_LIEN"];
        filtered_material["CLD_NIVEAU_GAS_DEPART"] =
          material.data["CLD_NIVEAU_GAS_DEPART"];
        filtered_material["CLD_NIVEAU_GAS_RETOUR"] =
          material.data["CLD_NIVEAU_GAS_RETOUR"];
        filtered_material["CLD_PHOTO_1"] = material.data["CLD_PHOTO_1"];
        filtered_material["CLD_PHOTO_2"] = material.data["CLD_PHOTO_2"];
        filtered_material["CLD_HEURE_DEPART"] =
          material.data["CLD_HEURE_DEPART"];
        filtered_material["CLD_HEURE_RETOUR"] =
          material.data["CLD_HEURE_RETOUR"];
        filtered_material["CLD_SIGNATURE_DEPART"] =
          material.data["CLD_SIGNATURE_DEPART"];
        filtered_material["CLD_SIGNATURE_RETOUR"] =
          material.data["CLD_SIGNATURE_RETOUR"];
        filtered_material["CLD_NOM_CLIENT"] = material.data["CLD_NOM_CLIENT"];
        filtered_material["CLD_CONTROLEUR_DEPART"] =
          material.data["CLD_CONTROLEUR_DEPART"];
        filtered_material["CLD_CONTROLEUR_RETOUR"] =
          material.data["CLD_CONTROLEUR_RETOUR"];
        filtered_material["CLD_KILOMETRE_DEPART"] =
          material.data["CLD_KILOMETRE_DEPART"];
        filtered_material["CLD_KILOMETRE_RETOUR"] =
          material.data["CLD_KILOMETRE_RETOUR"];
        filtered_material["CLD_HORAMETRE_RETOUR"] =
          material.data["CLD_HORAMETRE_RETOUR"];
        filtered_material["CLD_HORAMETRE_DEPART"] =
          material.data["CLD_HORAMETRE_DEPART"];
        filtered_material["CLD_QTE_DEPART"] = material.data["CLD_QTE_DEPART"];
        filtered_material["CLD_QTE_RETOUR"] = material.data["CLD_QTE_RETOUR"];
        filtered_material["CLD_NIVEAU_ADBLUE_DEPART"] =
          material.data["CLD_NIVEAU_ADBLUE_DEPART"];
        filtered_material["CLD_NIVEAU_ADBLUE_RETOUR"] =
          material.data["CLD_NIVEAU_ADBLUE_RETOUR"];
        filtered_material["CLD_SIGNATAIRE_RETOUR"] =
          material.data["CLD_SIGNATAIRE_RETOUR"];
        filtered_material["CLD_SIGNATAIRE_DEPART"] =
          material.data["CLD_SIGNATAIRE_DEPART"];
        filtered_material["CLD_ID_SOCIETE"] = material.data["CLD_ID_SOCIETE"];
        filtered_material["CLD_IDEnreg"] = material.data["CLD_IDEnreg"];
      }

      return filtered_material;
    },

    formatFromServerToTable(list) {
      let formatedData = [];
      for (let i = 0; i < list.length; i++) {
        let item = { id: list[i].recordId, data: list[i].fieldData };
        formatedData.push(item);
      }
      return formatedData;
    },
    closeDialog() {
      this.dialog = false;
    },
    openDialog(item) {
      let disabled = this.dataMenu[this.selectedItem].option.disabled;
      this.dialogData = {
        title: this.dataMenu[this.selectedItem].option.title,
        data: item,
        disabled,
      };
      this.dialog = true;
    },
    filterTextTab(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1
      );
    },
  },
};
</script>

<style>
@import "../style.css";
</style>