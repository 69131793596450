<template>
  <div class="modal planing-livraison" align="center">
    <div
      class="modal-content"
      align="left"
      style="font-size: 20px; min-width: 700px; margin: 50px"
      :style="{
        'margin-top': !visu_contrat ? '20vh' : '3vh',
        'min-width': !visu_contrat ? '600px' : '750px',
      }"
    >
      <div v-if="!loading">
        <div
          style="
            margin-top: 10px;
            width: 100%;
            border: solid 1px;
            padding: 10px;
            padding-bottom: 0px;
            background-color: white;
          "
          align="center"
        >
          <div style="font-style: normal; font-size: 14px">
            <v-card variant="flat" style="">
              <div style="margin-bottom: 100px" v-if="agency">
                <v-row justify="center" class="mb-2">
                  <v-col cols="12" class="pb-0" align="left">
                    <img :src="logoSrc" alt="" height="60" /> <br />
                  </v-col>
                  <v-col v-if="agency" align="left" cols="6">
                    <!-- <v-img :src="logoSrc"></v-img> -->
                    {{ agency["ag_adresse"] }} <br />
                    {{ agency["ag_cp"] }}
                    {{ agency["ag_ville"] }} <br />
                    {{ agency["ag_telephone"] }} <br />
                    {{ agency["ag_courriel"] }} <br />
                    {{ agency["ag_siteweb"] }} <br />
                  </v-col>
                  <v-col
                    cols="6"
                    align="center"
                    style="font-weight: 600; font-size: 17px"
                  >
                    <div
                      style="
                        border: solid 1px grey;
                        border-radius: 5px;
                        background-color: #f5f5f5;
                      "
                    >
                      {{ contratData["CL_CLIENT_RAISON_SOCIALE"] }} <br />
                      {{ contratData["CL_CLIENT_ADRESSE"] }}<br />
                      {{ contratData["CL_CLIENT_CODEPOSTAL"] }}
                      {{ contratData["CL_CLIENT_VILLE"] }}
                    </div>
                  </v-col>
                </v-row>
                <v-col cols="12" class="pa-0" style="border: solid 1px black">
                  <h3 class="ma-0 darkBackground">Information intervention</h3>
                </v-col>
                <v-col>
                  <v-row style="border: solid 1px grey">
                    <v-col cols="6" class="pa-1">
                      <div align="left">
                        <tbody v-if="contratData">
                          <div>
                            <tr>
                              <td>Dossier suivie par :</td>
                              <td>{{ contratData["CL_SUIVI_PAR"] }}</td>
                            </tr>
                            <tr>
                              <td>Référence à rappeler :</td>
                              <td>{{ contratData["CL_NUM_CONTRAT"] }}</td>
                            </tr>
                            <!-- <tr>
                            <td>Livré le :</td>
                            <td>{{ contratData["CL_DATE_DEPART"] }}</td>
                          </tr> -->
                          </div>
                          <div v-if="client && client['CL_INFO_CONTRAT']">
                            <tr>
                              <!-- <td>CL_INFO_CONTRAT :</td> -->
                              <td>{{ client["CL_INFO_CONTRAT"] }}</td>
                            </tr>
                          </div>
                        </tbody>
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      style="border-left: solid 1px grey"
                      class="pa-1"
                    >
                      <div align="left">
                        <tbody>
                          <div>
                            <tr>
                              <td>Compte Client :</td>
                              <td>{{ contratData["CL_CLIENT_COMPTE"] }}</td>
                            </tr>
                            <tr>
                              <td>Réf. Cde Client :</td>
                              <td>{{ contratData["CL_REF_COMMANDE"] }}</td>
                            </tr>
                            <tr>
                              <td>Référence Chantier :</td>
                              <td>{{ contratData["CL_REF_CHANTIER"] }}</td>
                            </tr>
                          </div>
                        </tbody>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="mt-1 px-0">
                  <table style="width: 100%">
                    <thead style="width: 100%">
                      <tr class="darkBackground planing-livraison" style="width: 100%">
                        <th class="form-div">REF</th>
                        <th class="form-div">Description</th>
                        <th class="form-div">Sous famille</th>
                        <th class="form-div">Marque</th>
                        <th class="form-div">Numéro de série</th>
                      </tr>
                    </thead>
                    <tbody style="width: 100%">
                      <tr style="width: 100%">
                        <td class="form-div">{{ data["CLD_MATRICULE"] }}</td>
                        <td class="form-div">{{ data["CLD_DESCRIPTION"] }}</td>
                        <td class="form-div">{{ data["CLD_SOUS_FAMILLE"] }}</td>
                        <td class="form-div">{{ data["CLD_MARQUE"] }}</td>
                        <td class="form-div">{{ data["CLD_NUMERO_SERIE"] }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style="width: 100%" v-if="data.type == 'DEPART'">
                    <thead>
                      <tr class="darkBackground">
                        <th class="form-div">Commentaire</th>
                        <th
                          class="form-div"
                          v-if="client"
                        >
                          Commentaire interne
                        </th>
                        <th class="form-div">Date départ</th>
                        <th class="form-div">Heure départ</th>
                      </tr>
                    </thead>
                    <tbody
                      :style="{
                        'background-color':
                          data.type == 'DEPART' ? '#FFF9C4' : '',
                      }"
                    >
                      <tr style="width: 100%">
                        <td class="form-div">
                          {{ data["CLD_COMMENTAIRE_DEPART"] }}
                        </td>
                        <td
                          class="form-div"
                          v-if="client"
                        >
                          {{ client["CL_COMMENTAIRE_INTERNE"] }}
                        </td>
                        <td class="form-div">
                          {{ getFormatedDate(data["CLD_DATE_DEPART"]) }}
                        </td>
                        <td class="form-div">
                          {{ data["CLD_HEURE_DEPART"] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table style="width: 100%" v-if="data.type == 'RETOUR'">
                    <thead>
                      <tr class="darkBackground" >
                        <th class="form-div">Commentaire</th>
                        <th
                          class="form-div"
                          v-if="client"
                        >
                          Commentaire interne
                        </th>
                        <th class="form-div">Date retour</th>
                        <th class="form-div">Heure retour</th>
                      </tr>
                    </thead>
                    <tbody
                      :style="{
                        'background-color':
                          data.type == 'RETOUR' ? '#FFF9C4' : '',
                      }"
                    >
                      <tr style="width: 100%">
                        <td class="form-div">
                          {{ data["CLD_COMMENTAIRE_RETOUR"] }}
                        </td>
                        <td
                          class="form-div"
                          v-if="client"
                        >
                          {{ client["CL_COMMENTAIRE_INTERNE"] }}
                        </td>
                        <td class="form-div">
                          {{ getFormatedDate(data["CLD_DATE_RETOUR"]) }}
                        </td>
                        <td class="form-div">
                          {{ data["CLD_HEURE_RETOUR"] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </div>
              <div style="font-size: 10px">
                <v-divider class=""></v-divider>
                <v-col v-if="societe">
                  {{ societe["RS"] }} - {{ societe["TypeEntreprise"] }} au
                  capital de {{ societe["Capital"] }} € - SIRET
                  {{ societe["NumeroSIRET"] }} - APE :
                  {{ societe["CodeAPE"] }} - TVA :
                  {{ societe["TVAIntracommunautaire"] }} <br />
                  <div v-if="agency">
                    {{ agency["ag_nom"] }} - {{ agency["ag_adresse"] }}
                    {{ agency["ag_cp"] }} {{ agency["ag_ville"] }} - Tél :
                    {{ agency["ag_telephone"] }}
                  </div>
                </v-col>
              </div>
            </v-card>
          </div>
        </div>
        <div align="center" style="margin-top: 10px">
          <v-btn
            @click="closeModal"
            class="modal-btn"
            style="background-color: red; color: white"
          >
            Fermer
          </v-btn>
        </div>
        <div align="center">
          <v-btn variant="text" :href="getAccenUrl"> Ouvrir dans ACCEN </v-btn>
        </div>
        <div align="center"></div>
      </div>
      <div v-else align="center" style="height: 200px; margin-top: 10vh">
        <v-progress-circular
          indeterminate
          size="100"
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import planningLivraison from "../core/index";
import moment from "moment";

export default {
  data() {
    return {
      logoSrc: "data:image/gif;base64,",
      contratData: {},
      visu_contrat: true,
      agency: undefined,
      client: undefined,
      societe: undefined,
      loading: true,
    };
  },
  props: {
    data: { required: true },
  },
  mounted() {
    let that = this;
    planningLivraison.get_contrat_from_num_contrat(
      this.data.CLD_NUM_CONTRAT,
      function (data) {
        if (data.code == 0) {
          that.contratData = data.data.data.map((val) => val.fieldData);
          that.contratData = that.contratData[0];
        }
        that.loading = false;
      }
    );
    planningLivraison.get_clients(function (data) {
      if (data.code == 0) {
        let result = data.data.data.find(
          (val) => val.fieldData.CL_LIEN == that.data.CLD_LIEN
        );
        if (result) {
          that.client = result.fieldData;
          planningLivraison.get_societe_by_id(that.client.CL_ID_SOCIETE, function (data) {
            if (data.code == 0) {
              that.societe = data.data.data[0].fieldData;
            }
          });
        }
      }
    });
    planningLivraison.get_agency(function (data) {
      if (data.code == 0) {
        let result = data.data.data.find(
          (val) => val.fieldData.ID_Agence == that.data.CLD_ID_AGENCE
        );
        if (result) {
          that.agency = result.fieldData;
          that.logoSrc = String(
            that.logoSrc + JSON.parse(that.agency["Logo_JSON"]).Logo_Base64
          );
        }
      }
    });
  },
  computed: {
    getAccenUrl() {
      return (
        "fmp://demo-accen.dyndns.org/Contrat%20Loc.fmp12?script=affiche_contrat&param=" +
        this.data["CLD_NUM_CONTRAT"]
      );
    },
  },
  methods: {
    getFormatedDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.planning-livraison .form-div {
  padding: 0px 5px;
  font-size: 13px;
  border: solid 1px grey;
  font-weight: normal;
}
</style> 