<template>
  <div v-if="pageIsLoading == false" class="etat-des-lieux-velo">
    <div
      :class="[dataMenu[selectedItem].class]"
      style="border-radius: 5px"
      class="mx-1"
    >
      <div v-if="user_info.admin == 1 ? true : false">
        <v-dialog
          v-if="isModalUserOpen"
          v-model="isModalUserOpen"
          width="500"
          persistent
        >
          <v-card>
            <v-col>
              <modal-user
                @close="isModalUserOpen = false"
                :agencyList="agency_list"
                :user_information="user_info"
                :isAdmin="user_info.admin == 1 ? true : false"
              />
            </v-col>
          </v-card>
        </v-dialog>
      </div>
      <v-dialog
        v-if="isqCodeReaderOpen"
        v-model="isqCodeReaderOpen"
        width="500"
      >
        <v-card>
          <qcode-reader></qcode-reader>
        </v-card>
      </v-dialog>
      <dialogPage
        v-if="dialog"
        :dialog="dialog"
        :data="dialogData"
        :user_information="user_info"
        :clients_list="clients_list"
        :agency_list="agency_list"
        @closeDialog="closeDialog($event)"
        @setNewEtatdl="setNewEtatdl($event)"
      />
      <div @click="isOpenMiniVariant = !isOpenMiniVariant">
        <v-navigation-drawer permanent temporary rail>
          <v-list-item
            class="etat-des-lieux-velo"
            v-for="(item, i) in itemsMenu"
            :class="[item.class]"
            style="font-size: 20px; height: 80px"
            :key="i"
            :prepend-icon="item.icon"
            :title="item.text"
            @click="selectedItem = i"
          />
        </v-navigation-drawer>
      </div>
      <v-col>
        <v-card-title class="pl-0" align="right">
          <div style="position: absolute">
            <h3>
              {{ dataMenu[selectedItem].title.toUpperCase() }}
            </h3>
          </div>
          <v-btn variant="text" @click="isqCodeReaderOpen = true">
            <v-icon>mdi-qrcode-scan </v-icon>
          </v-btn>
          <v-btn variant="text" @click="setMateriels">
            <v-icon>mdi-refresh </v-icon>
          </v-btn>
        </v-card-title>
        <v-select
          variant="solo"
          class="elevation-0 mb-2"
          :label="$t('home.filter_by_agency')"
          hide-details
          :items="agency_list.map((val) => val.ag_nom)"
          v-model="selectedFilteredAgency"
          clearable
        >
        </v-select>
        <v-table height="79.5vh" fixed-header>
          <thead>
            <tr>
              <th v-for="elem in get_tab_header" :key="elem.value">
                {{ elem.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in get_tab_items"
              :key="idx"
              @click="openDialog(dataMenu[selectedItem].option.btn[0], item)"
            >
              <td>{{ item.CLD_NUM_CONTRAT }}</td>
              <td>{{ item.CLD_MATRICULE }}</td>
              <td>{{ item.CLD_NOM_CLIENT }}</td>
              <td>{{ item.CLD_DESCRIPTION }}</td>
              <td v-if="selectedItem != '0'">{{ item.CLD_DATE_DEPART }}</td>
              <td v-if="selectedItem != '0'">{{ item.CLD_DATE_RETOUR }}</td>
              <td>
                <div
                  v-if="
                    agency_list &&
                    agency_list.find(
                      (val) => val.ID_Agence == item.CLD_ID_AGENCE
                    )
                  "
                >
                  {{
                    agency_list.find(
                      (val) => val.ID_Agence == item.CLD_ID_AGENCE
                    ).ag_nom
                  }}
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
        <!-- <v-data-table
          disable-sort
          hide-default-footer
          :headers="get_tab_header"
          :items-per-page="itemsPerPage"
          :items="get_tab_items"
          :search="search"
          :custom-filter="filterTextTab"
          style="border: solid 1px lightgrey"
          locale="fr-fr"
          @page-count="pageCount = $event"
          class="px-2"
          :no-data-text="$t('home.no_data_available')"
        >
          <template v-slot:top>
            <v-row justify="center" align="center" class="pt-3">
              <v-col>
                <v-text-field
                  v-model="tmpSearch"
                  outlined
                  dense
                  hide-details
                  :label="$t('home.filter')"
                  class="mx-4 mb-2"
                  v-on:keyup.enter="search = tmpSearch"
                ></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="search = tmpSearch"
                  >Rechercher</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="ma-2"
              color="primary"
              elevation="0"
              @click="openDialog(dataMenu[selectedItem].option.btn[0], item)"
            >
              Ouvrir
            </v-btn>
          </template>
          <template v-slot:item.CLD_ID_AGENCE="{ item }">
            <div
              v-if="
                agency_list &&
                agency_list.find((val) => val.ID_Agence == item.CLD_ID_AGENCE)
              "
            >
              {{
                agency_list.find((val) => val.ID_Agence == item.CLD_ID_AGENCE)
                  .ag_nom
              }}
            </div>
          </template>
        </v-data-table> -->
      </v-col>
    </div>
  </div>
  <v-row v-else justify="center" align="center">
    <v-col align="center" style="padding-top: 40vh">
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
import engine from "../core/index";
import dialogPage from "./velo-dialog";
import qcodeReader from "./qcode-reader";
import modalUser from "./modal-user";

export default {
  data() {
    return {
      tmpSearch: "",
      isModalUserOpen: false,
      isqCodeReaderOpen: false,
      selectedFilteredAgency: undefined,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      tab_items: [],
      contratToId: {},
      etatdl_elems: [],
      agency_list: [],
      dialogData: {},
      materielsList: [],
      isOpenMiniVariant: true,
      selectedItem: 0,
      search: "",
      dialog: false,
      pageIsLoading: true,
      user_info: {},
    };
  },
  components: {
    modalUser,
    dialogPage,
    qcodeReader,
  },
  watch: {
    tmpSearch(val) {
      if (val == null || val.length == 0) {
        this.search = "";
      }
    },
    selectedItem() {
      this.isOpenMiniVariant = false;
    },
  },
  mounted() {
    let that = this;
    this.pageIsLoading = true;
    // let token = localStorage.getItem("token");
    // if (token == null) that.$router.push("/login");
    // else
    // engine.user_get_info(function (data) {
    //   if (data.code !== 0) {
    //     that.$router.push("/login");
    //   } else {
    that.setMateriels();
    that.user_info = this.get_user_information;
    engine.get_agency(function (data) {
      if (data.code === 0) {
        that.agency_list = that.formatAgencyData(data.data.data);
      }
    });
    engine.get_clients(function (data) {
      if (data.code === 0)
        that.clients_list = that.formatClientData(data.data.data);
    });
    // }
    // });
  },
  methods: {
    verif_elem(selectedAgency, idToCompare) {
      if (selectedAgency == undefined) return true;
      else {
        if (selectedAgency.ID_Agence == idToCompare) return true;
        else return false;
      }
    },

    formatClientData(list) {
      let formatedData = list.map((val) => ({
        CL_CLIENT_ADRESSE: val.fieldData.CL_CLIENT_ADRESSE,
        // CL_CLIENT_EMAIL: val.fieldData.CL_CLIENT_EMAIL,
        CL_CLIENT_EMAIL: val.fieldData.CL_SUIVI_MAIL,
        CL_CLIENT_CODEPOSTAL: val.fieldData.CL_CLIENT_CODEPOSTAL,
        CL_CLIENT_RAISON_SOCIALE: val.fieldData.CL_CLIENT_RAISON_SOCIALE,
        CL_DATE_CONTRAT: val.fieldData.CL_DATE_CONTRAT,
        CL_DATE_DEPART: val.fieldData.CL_DATE_DEPART,
        CL_DATE_RETOUR: val.fieldData.CL_DATE_RETOUR,
        CL_ID_AGENCE: val.fieldData.CL_ID_AGENCE,
        CL_ID_CLIENT: val.fieldData.CL_ID_CLIENT,
        CL_LIEN: val.fieldData.CL_LIEN,
        CL_NOM_CLIENT: val.fieldData.CL_NOM_CLIENT,
        CL_NUM_CONTRAT: val.fieldData.CL_NUM_CONTRAT,
      }));
      return formatedData;
    },
    formatAgencyData(list) {
      let formatedData = [];
      for (let i = 0; i < list.length; i++) {
        formatedData.push(list[i].fieldData);
      }
      return formatedData;
    },
    logOut() {
      localStorage.clear();
      this.user_info = {};
      this.$router.push("/login");
    },
    setNewEtatdl(newEtadl) {
      let etatdlData = newEtadl.data;
      let that = this;
      // engine.is_token_valid(function (valid) {
      // if (valid.code == 0) {
      engine.edit_material(
        etatdlData.CLD_MATRICULE,
        etatdlData,
        function () {
          that.setMateriels()
        }
      );
      // } else {
      //   that.$router.push("/login");
      // }
      // });
    },
    setSignature(data) {
      data["contrat"] = this.dialogData.data["N° Contrat Comp"];
      data["matricule"] = this.dialogData.data["LocdataS_::N° Mat interne"];
      engine.set_signatures(data, function () {});
    },
    setFilterImage(data) {
      data["contrat"] = this.dialogData.data["N° Contrat Comp"];
      data["matricule"] = this.dialogData.data["LocdataS_::N° Mat interne"];
      engine.set_image_filter(data, function () {});
    },
    closeDialog() {
      this.dialog = false;
    },
    openDialog(title, item) {
      let disabled = { entrer: true, sortie: true };
      if (item.CLD_CONTROLE_DEPART == 0 && item.CLD_CONTROLE_RETOUR == 0) {
        disabled = { entrer: true, sortie: false };
      } else if (
        item.CLD_CONTROLE_DEPART == 1 &&
        item.CLD_CONTROLE_RETOUR == 0
      ) {
        disabled = { entrer: false, sortie: true };
      }
      this.dialogData = {
        selectedItem: this.selectedItem,
        title,
        data: item,
        disabled,
      };
      this.dialog = true;
    },
    setMateriels() {
      let that = this;

      // engine.is_token_valid(function (valid) {
      // if (valid.code == 0) {
      engine.get_materials(function (data) {
        if (data.data !== null)
          that.materielsList = data.data.data.map((val) => ({
            CLD_MATRICULE: val.fieldData.CLD_MATRICULE,
            CLD_CHECK_LIST_JSON: val.fieldData.CLD_CHECK_LIST_JSON,
            CLD_CHECK_LIST_JSON_GESTION:
              val.fieldData.CLD_CHECK_LIST_JSON_GESTION,
            CLD_DESCRIPTION: val.fieldData.CLD_DESCRIPTION,
            CLD_SOUS_FAMILLE: val.fieldData.CLD_SOUS_FAMILLE,
            CLD_DATE_DEPART_VALIDATION:
              val.fieldData.CLD_DATE_DEPART_VALIDATION,
            CLD_DATE_RETOUR_VALIDATION:
              val.fieldData.CLD_DATE_RETOUR_VALIDATION,
            CLD_DATE_DEPART: val.fieldData.CLD_DATE_DEPART,
            CLD_DATE_RETOUR: val.fieldData.CLD_DATE_RETOUR,
            CLD_NUM_CONTRAT: val.fieldData.CLD_NUM_CONTRAT,
            CLD_TECHNICIEN: val.fieldData.CLD_TECHNICIEN,
            CLD_COMMENTAIRE_DEPART: val.fieldData.CLD_COMMENTAIRE_DEPART,
            CLD_COMMENTAIRE_RETOUR: val.fieldData.CLD_COMMENTAIRE_RETOUR,
            CLD_LIEN: val.fieldData.CLD_LIEN,
            CLD_NIVEAU_GAS_DEPART: val.fieldData.CLD_NIVEAU_GAS_DEPART,
            CLD_NIVEAU_GAS_RETOUR: val.fieldData.CLD_NIVEAU_GAS_RETOUR,
            CLD_HEURE_DEPART: val.fieldData.CLD_HEURE_DEPART,
            CLD_HEURE_RETOUR: val.fieldData.CLD_HEURE_RETOUR,
            CLD_SIGNATURE_DEPART: val.fieldData.CLD_SIGNATURE_DEPART,
            CLD_SIGNATURE_RETOUR: val.fieldData.CLD_SIGNATURE_RETOUR,
            CLD_NOM_CLIENT: val.fieldData.CLD_NOM_CLIENT,
            CLD_CONTROLEUR_DEPART: val.fieldData.CLD_CONTROLEUR_DEPART,
            CLD_CONTROLEUR_RETOUR: val.fieldData.CLD_CONTROLEUR_RETOUR,
            CLD_KILOMETRE_DEPART: val.fieldData.CLD_KILOMETRE_DEPART,
            CLD_KILOMETRE_RETOUR: val.fieldData.CLD_KILOMETRE_RETOUR,
            CLD_HORAMETRE_RETOUR: val.fieldData.CLD_HORAMETRE_RETOUR,
            CLD_HORAMETRE_DEPART: val.fieldData.CLD_HORAMETRE_DEPART,
            CLD_QTE_DEPART: val.fieldData.CLD_QTE_DEPART,
            CLD_QTE_RETOUR: val.fieldData.CLD_QTE_RETOUR,
            CLD_ID_SOCIETE: val.fieldData.CLD_ID_SOCIETE,
            CLD_CONTROLE_DEPART: val.fieldData.CLD_CONTROLE_DEPART,
            CLD_CONTROLE_RETOUR: val.fieldData.CLD_CONTROLE_RETOUR,
            CLD_ID_AGENCE: val.fieldData.CLD_ID_AGENCE,
            CLD_OBSERVATION_GENERAL: val.fieldData.CLD_OBSERVATION_GENERAL,
            CLD_ETATDL_VALIDE_COMMENTAIRE:
              val.fieldData.CLD_ETATDL_VALIDE_COMMENTAIRE,
            CLD_ETATDL_VALIDE: val.fieldData.CLD_ETATDL_VALIDE,
            CLD_PHOTO_EDITION_DEPART: val.fieldData.CLD_PHOTO_EDITION_DEPART,
            CLD_PHOTO_EDITION_RETOUR: val.fieldData.CLD_PHOTO_EDITION_RETOUR,
          }));
        if (that.$route.query && that.$route.query.contrat) {
          let findedContrat = undefined;
          findedContrat = that.materielsList.find(
            (val) => val.CLD_NUM_CONTRAT == that.$route.query.contrat
          );
          if (findedContrat != undefined) {
            that.openDialog("", findedContrat);
            that.$router.replace({ query: null });
          }
        }
        that.pageIsLoading = false;
      });
      // } else {
      //   that.$router.push("/login");
      // }
      // });
    },
    filterTextTab(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1
      );
    },
  },
  computed: {
        get_user_information() {
      return this.$store.state.user;
    },
    getTopBarHeight() {
      if (this.isIos) return 65;
      if (this.isMobile) return 45;
      return "";
    },
    isIos() {
      return (
        navigator.userAgent.includes("iPhone") ||
        navigator.userAgent.includes("iPhone Simulator") ||
        navigator.platform.includes("iPhone") ||
        navigator.platform.includes("iPhone Simulator") ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    isMobile() {
      return false;
      // return this.$vuetify.breakpoint.mobile;
    },
    get_tab_items() {
      let sorted_items = [];
      if (this.agency_list.length == 0) return sorted_items;
      let selectedAgency = this.agency_list.find(
        (val) => val.ag_nom == this.selectedFilteredAgency
      );
      for (let i = 0; i < this.materielsList.length; i++) {
        if (
          this.selectedItem == 0 &&
          this.materielsList[i].CLD_CONTROLE_DEPART == "0" &&
          this.materielsList[i].CLD_CONTROLE_RETOUR == "0"
        ) {
          if (
            this.verif_elem(selectedAgency, this.materielsList[i].CLD_ID_AGENCE)
          )
            sorted_items.push(this.materielsList[i]);
        }
        if (
          this.selectedItem == 1 &&
          this.materielsList[i].CLD_CONTROLE_DEPART == "1" &&
          this.materielsList[i].CLD_CONTROLE_RETOUR == "0"
        )
          if (
            this.verif_elem(selectedAgency, this.materielsList[i].CLD_ID_AGENCE)
          )
            sorted_items.push(this.materielsList[i]);
        if (
          this.selectedItem == 2 &&
          this.materielsList[i].CLD_CONTROLE_DEPART == "1" &&
          this.materielsList[i].CLD_CONTROLE_RETOUR == "1"
        )
          if (
            this.verif_elem(selectedAgency, this.materielsList[i].CLD_ID_AGENCE)
          )
            sorted_items.push(this.materielsList[i]);
      }
      return sorted_items;
    },
    itemsMenu() {
      return [
        {
          text: "Sortie",
          icon: "mdi-arrow-right-bold",
          class: "sortie",
        },
        {
          text: "En cours",
          icon: "mdi-arrow-left-bold",
          class: "entrer",
        },
        {
          text: this.$t("etat-des-lieux-velo.menu.historical"),
          icon: "mdi-lock",
          class: "termine",
        },
      ];
    },
    dataMenu() {
      return [
        {
          title: "Sortie",
          option: {
            btn: ["la sortie"],
            key: "depart",
            title: "Sortie",
            disabled: { entrer: true, sortie: false },
          },

          class: "sortie",
        },
        {
          title: "Retour",
          option: {
            btn: ["le retour"],
            key: "return",
            title: "Retour",
            disabled: { entrer: false, sortie: true },
          },
          class: "entrer",
        },
        {
          title: this.$t("etat-des-lieux-velo.menu.finished"),
          option: {
            btn: [this.$t("etat-des-lieux-velo.menu.open")],
            key: "returned",
            title: this.$t("etat-des-lieux-velo.menu.finished"),
            disabled: { entrer: true, sortie: true },
          },
          class: "termine",
        },
      ];
    },
    get_tab_header() {
      let tab_header = [];
      let list = [];
      if (this.selectedItem === 0)
        list = [
          "CLD_NUM_CONTRAT",
          "CLD_MATRICULE",
          "CLD_NOM_CLIENT",
          // "CLD_SOUS_FAMILLE",
          "CLD_DESCRIPTION",
          "CLD_ID_AGENCE",
        ];

      if (this.selectedItem === 1 || this.selectedItem === 2)
        list = [
          "CLD_NUM_CONTRAT",
          "CLD_MATRICULE",
          "CLD_NOM_CLIENT",
          // "CLD_SOUS_FAMILLE",
          "CLD_DESCRIPTION",
          "CLD_DATE_DEPART",
          "CLD_DATE_RETOUR",
          // "CLD_TECHNICIEN",
          "CLD_ID_AGENCE",
        ];
      for (let i = 0; i < list.length; i++) {
        tab_header.push({
          text: this.$t("etat-des-lieux-velo.home." + list[i]),
          value: list[i],
        });
      }
      return tab_header;
    },
  },
};
</script>

<style>
@import "../style.css";
</style>