<template>
  <div class="etat-des-lieux-velo">
    <v-col cols="12" v-if="isAdmin">
      <v-card outlined>
        <v-simple-table dense style="height: 20vh; overflow: auto">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Valeure</th>
                <th class="text-left">Afficher</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in personalizedData"
                :key="item['Clé primaire']"
              >
                <td>{{ item.name }}</td>
                <td>
                  <v-switch v-model="personalizedData[idx]['show']"></v-switch>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
      <v-col cols="12" class="px-1">
        <v-card-actions align="right">
          <v-btn color="red" style="color: white" @click="close()"
            >Annuler</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateUser()">Mettre à jour</v-btn>
        </v-card-actions>
      </v-col>
  </div>
</template>

<script>
import engine from "../core/index";

export default {
  data() {
    return {
      dataForm: {},
      personalizedData: undefined,
    };
  },
  props: {
    isAdmin: {},
    agencyList: {},
    user_information: {},
  },
  mounted() {
    let that = this;
    this.dataForm = JSON.parse(JSON.stringify(this.user_information));
    engine.get_users_settings(this.isAdmin, function (data) {
      if (data.code == 0) {
        that.personalizedData = data.data.data.map((val) => val.fieldData);
        for (let i = 0; i < that.personalizedData.length; i++) {
          that.personalizedData[i].show = that.personalizedData[i].show == 1;
        }
      }
    });
  },
  methods: {
    updateUser() {
      for (let i = 0; i < this.personalizedData.length; i++) {
        engine.set_users_settings(
          {
            "Clé primaire": this.personalizedData[i]["Clé primaire"],
            show: this.personalizedData[i].show == true ? 1 : 0,
          },
          function () {}
        );
      }
      this.close()
    },
    close() {
      this.dataForm = {};
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>